.NeedInfo {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 90%;
}

.footer {
    margin-top: auto;
    padding: 1rem;
    display: flex;
    align-items: center;
    gap: 1rem;
    justify-content: space-between;
    border-top: 1px solid #e7e7e7;
    height: auto;
}

.PrimaryBtn {
    cursor: pointer;
    border-radius: 0.25rem;
    outline: none;
    display: flex;
    font-family: Gilroy-semibold, sans-serif;
    align-items: center;
    background: var(--primary-button-bg);
    color: var(--primary-button-text);
    padding: 0.75rem;
    border: 1px solid var(--primary-border-colour);
}

.SecondaryBtn {
    cursor: pointer;
    border-radius: 0.5rem;
    outline: none;
    display: flex;
    align-items: center;
    background: var(--secondary-button-bg);
    color: var(--secondary-button-text);
    padding: 0.75rem;
    border: 1px solid var(--primary-border-colour);
}

.FooterText {
    color: #9d9d9d;
    font-size: 0.875rem;
}
.Dflex {
    display: flex;
    gap: 1rem;
    align-items: center;
}
.UserList {
    font-size: 0.875rem;
    cursor: pointer;
    color: #4d4d4d;
}
.AddCompanyUser {
    color: #6021b3;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin: 0.25rem 0;
    font-size: 0.875rem;
    font-family: Gilroy-semibold, sans-serif;
}
