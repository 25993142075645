/* The switch - the box around the slider */
.switch {
    font-size: 7px;
    position: relative;
    display: inline-block;
    width: 30px;
    height: 16px;

    &.success {
        input:checked + .slider {
            background-color: #12b76a;
            &:before {
                background-color: white;
            }
        }
    }
}

/* Hide default HTML checkbox */
.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

/* The slider */
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0px;
    background: #bcbcbc;
    transition: 200ms cubic-bezier(0.175, 0.885, 0.32, 1.5);
    border-radius: 30px;
    border: 1px solid #ccc;
}

.slider:before {
    position: absolute;
    content: '';
    height: 1.9em;
    width: 1.9em;
    border-radius: 16px;
    left: 1px;
    top: 0px;
    bottom: 0;
    background-color: black;
    box-shadow: 0 2px 5px #999999;
    transition: 200ms cubic-bezier(0.175, 0.885, 0.32, 1.5);
}

input:checked + .slider {
    background-color: #5fdd54;
    border: 1px solid transparent;
}

input:checked + .slider:before {
    transform: translateX(1.8em);
}

input:disabled + .slider {
    background-color: #c6c6c6;
    border: 1px solid transparent;
}
input:disabled + .slider::before {
    background-color: rgb(108, 108, 108);
}
