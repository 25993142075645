@import '../../../../scss/card';
$--grid-template-columns: minmax(280px, 300px) minmax(140px, auto) minmax(114px, auto)
    minmax(114px, auto) minmax(116px, auto) minmax(114px, auto) minmax(152px, auto)
    minmax(100px, auto);

$--lender-name-width: 280px;
$--column-width-1: 160px;
$--column-width-2: 200px;

.DebtDetails {
    @include card-with-title;

    .Top {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .Title {
            font-size: 18px;
            font-weight: 600;
            line-height: 24px;
        }

        .Right {
            display: flex;
            align-items: center;
            column-gap: 30px;

            .UpdatedDate {
                color: #9d9d9d;
                font-size: 14px;
                font-weight: 500;
                line-height: 16.48px;
            }
            .Download {
                color: var(--primary-purple);
                font-size: 14px;
                font-weight: 500;
                line-height: 20px;

                display: flex;
                align-items: center;
                column-gap: 5px;
                cursor: pointer;
            }
        }

        .NoDataText {
            color: #9d9d9d;
            font-size: 14px;
            font-weight: 500;
            line-height: 16.48px;
        }
    }

    .NoData {
        border-bottom: none;
        border-radius: 8px;
    }

    .Body {
        .TableContainer {
            //border: 1px solid red;
            overflow-x: auto;
            border: 1px solid #e7e7e7;
            border-radius: 8px;
            .Table {
                border-collapse: separate;
                border-spacing: 0;
                background-color: white;
                .TableHead {
                    text-align: left;
                    .TableHeadRow {
                        background-color: #f9fafc;
                        color: #545b62;
                        font-size: 14px;
                        font-weight: 600;
                        line-height: 18px;

                        .HeadRowItem {
                            background-color: #f9fafc;
                            min-width: $--column-width-1;
                            max-width: fit-content;
                            padding: 13px 16px;
                            white-space: nowrap;
                            border-bottom: 1px solid #e7e7e7;
                        }

                        .FirstHeadRowItem {
                            min-width: $--lender-name-width;
                            border-right: 1px solid #e7e7e7;
                            position: sticky;
                            left: 0;
                        }
                    }
                }
                .TableBody {
                    color: black;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 18px;

                    .TableBodyRow {
                        .RowItem {
                            background-color: white;
                            border-bottom: 1px solid #e7e7e7;
                            padding: 13px 16px;
                        }

                        .FirstColumnItem {
                            border-right: 1px solid #e7e7e7;
                            position: sticky;
                            left: 0;
                        }

                        .TotalRowItem {
                            background-color: #f7f7f7;
                            font-size: 14px;
                            font-weight: 600;
                        }

                        &:last-child {
                            .RowItem {
                                border-bottom: none;
                            }
                        }
                    }
                }
            }
        }
        .NoteText {
            margin-top: 24px;
            font-size: 14px;
            font-weight: 500;
            line-height: 18px;
            color: #9d9d9d;
            .Bold {
                font-weight: 600;
            }
        }
    }
}
.RightAlign {
    text-align: right;
}
