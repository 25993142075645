.container {
    display: flex;
    height: 100vh;
}

.leftSide {
    display: none;
    flex: 1;
    background: linear-gradient(135deg, #ffa7a7, #a18cd1);
    position: relative;
}

.leftSide::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: repeating-linear-gradient(
        -45deg,
        transparent,
        transparent 10px,
        rgba(255, 255, 255, 0.1) 10px,
        rgba(255, 255, 255, 0.1) 20px
    );
}

.logoPlaceholder {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 320px;
    height: 155px;
    background-color: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(10px);
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.rightSide {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem;
}

.card {
    width: 100%;
    max-width: 400px;
    padding: 2rem;
    background-color: #ffffff;
    border-radius: 8px;
    //box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.header {
    margin-bottom: 2rem;
}

.form {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.inputGroup {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.createAccount {
    display: flex;
    justify-content: center;
    gap: 0.5rem;
    margin-top: 1rem;
}

.shimmer {
    background: #f6f7f8;
    background-image: linear-gradient(to right, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%);
    background-size: 800px 104px;
    display: inline-block;
    position: relative;
    border-radius: 4px;
}

.shimmer::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-60%);
    background-image: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0) 0,
        rgba(255, 255, 255, 0.2) 20%,
        rgba(255, 255, 255, 0.5) 60%,
        rgba(255, 255, 255, 0) 100%
    );
    animation: shimmer 2s infinite;
    content: '';
}

// @keyframes shimmer {
//     100% {
//         transform: translateX(60%);
//     }
// }

.titleShimmer {
    height: 32px;
    width: 200px;
}

.tagShimmer {
    height: 24px;
    width: 100px;
    margin-top: 0.5rem;
}

.labelShimmer {
    height: 16px;
    width: 60px;
}

.inputShimmer {
    height: 40px;
    width: 100%;
}

.buttonShimmer {
    height: 40px;
    width: 100%;
}

.textShimmer {
    height: 16px;
    width: 120px;
}

.linkShimmer {
    height: 16px;
    width: 100px;
}

.logoShimmer {
    height: 60px;
    width: 200px;
}

@media (min-width: 768px) {
    .leftSide {
        display: block;
    }
}
