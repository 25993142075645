@import '../../../../../../scss/card';

.SectorIndustryOutlook {
    @include new-overview-card;

    .Body {
        .Container {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            align-items: stretch;
            column-gap: 24px;
            row-gap: 24px;

            .SingleReport {
                @include cta-card;
                min-height: 260px;
                .Header {
                    display: flex;
                    align-items: flex-start;
                    justify-content: space-between;

                    .Image {
                        width: 25%;
                        aspect-ratio: 1/1;
                    }

                    .Date {
                        border-radius: 16px;
                        color: #828282;
                        padding: 5px 8px;
                        background: #f7f7f7;
                        font-size: 12px;
                        font-weight: 500;
                        line-height: 14.12px;
                        letter-spacing: 0.00019968050764873624px;
                        text-align: center;
                    }
                }

                .Title {
                    margin-top: 16px;
                    font-size: 14px;
                    font-weight: 700;
                    line-height: 16.93px;
                    text-align: left;
                    color: #000000;
                }

                .Publisher {
                    margin-top: 4px;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 16.48px;
                    text-align: left;
                    color: #999999;
                }

                .Text {
                    margin-top: 16px;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 16px;
                    text-align: left;
                    color: #676767;
                }
            }
        }
    }
}

.PDFReport {
    .Top {
        padding: 0.85rem;

        .Title {
            font-size: 14px;
        }
    }

    .Body {
        padding: 0.85rem;

        .Container {
            grid-template-columns: 1fr 1fr;

            .SingleReport {
                padding: 0.85rem;
                min-height: fit-content;

                .Header {
                    .Image {
                        width: 20%;
                    }

                    .Date {
                        font-size: 11px;
                    }
                }

                .Title {
                    font-size: 11px;
                }

                .Publisher {
                    font-size: 11px;
                }

                .Text {
                    margin-top: 5px;
                    font-size: 11px;
                }
            }
        }
    }
}
