@import '../../../../../scss/card';
@import '../../../../../scss/animations';

@mixin Btn {
    height: 44px;
    width: 100%;
    font-family: 'Gilroy-SemiBold';
    font-size: 12px;
    border-radius: 6px;
}

.GstFetch {
    @include animate-fadein(300ms, 0, 0);
    @include data-upload-card;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 1.625rem;

    .Card {
        .CardHeader {
            color: #000000;
            font-family: 'Gilroy-Bold', sans-serif;
            font-size: 1.125rem;
            margin-bottom: 1rem;
        }

        .CardSubHeader {
            font-family: 'Gilroy-Medium', sans-serif;
            font-size: 0.875rem;
            margin-bottom: 2rem;
            color: #9d9d9d;
        }
    }
}

.Place {
    width: 70%;
    display: flex;
    flex-wrap: wrap;
    color: #000000;
    font-family: 'Gilroy-SemiBold', sans-serif;
    font-size: 0.875rem;
    white-space: nowrap;
    @media (max-width: 768px) {
        font-size: 0.65rem;
    }

    span {
        font-family: 'Gilroy-Medium', sans-serif;
        display: flex;
        align-items: center;
    }
}

.FetchBtn {
    width: 24%;
    height: 2.5rem;
    display: flex;
    align-items: center;
    background: var(--primary-purple-highlight);
    color: var(--primary-purple);
    justify-content: center;
    padding: 0.75rem 1.5rem;
    font-size: 0.875rem;
    border-radius: 0.3rem;
    cursor: pointer;
    // media query for mobile
    @media (max-width: 768px) {
        width: 30%;

        font-size: 0.65rem;
        padding: 0.5rem 0.75rem;
    }

    & > img {
        margin-right: 0.5rem;
    }
}

.FetchSuccessful {
    @extend .FetchBtn;
    background-color: #e8fff3;
    color: #00bd00;
}

.Line {
    height: 0px;
    border: 1px solid #ffffff;
    background-color: #dbdbdb;
}

.Hide {
    display: none;
}

.Separator {
    margin-top: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 10px;

    .Line {
        height: 3px;
        width: 100%;
        background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='white' stroke='black' stroke-width='3' stroke-dasharray='3%2c 19' stroke-dashoffset='5' stroke-linecap='square'/%3e%3c/svg%3e");
    }
}

.InactivePill {
    background-color: #fef9ec;
    color: #e49e00;
    width: 70px;
    height: 24px;
    padding: 3px 10px;
    border-radius: 44px;
    margin-left: 1rem;
    font-size: 14px;
    font-weight: 300;
}

.DisabledBtn {
    @extend .FetchBtn;
    font-size: 14px;
    background-color: #f7f7f7;
    color: #9d9d9d;
    border: 1px solid #cbcbcb;
    cursor: not-allowed;
}
