@mixin scrollbar-style() {
    &::-webkit-scrollbar {
        width: 10px;
    }

    &::-webkit-scrollbar-track {
        background: transparent;
        border-radius: 10px;
        border-left: 0px solid transparent;
        border-right: 0px solid transparent;
        margin: 5px 0 5px 0;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #d9d9d9;
        border-radius: 4px;
        border: 3px solid #ffffff;
    }
}

.hasData {
    background: #ffffff !important;
    border: 0.5px solid #c7c7c7 !important;
}

.hasData1 {
    background: #ffffff !important;
}

.noFocus {
    background-color: #ffffff;
}

.Dropdown {
    //width: fit-content;
    display: flex;
    align-items: center;
    width: 100%;
    border-radius: 4px;
    position: relative;
    outline: none;

    &.Disabled {
        cursor: no-drop;
        .Label {
            color: #8d8d8d;
        }
    }

    .Label {
        position: absolute;
        color: #8d8d8d;
        top: -14px;
        left: 0;
        font-size: 10px;
        font-weight: 300;
    }

    .Main {
        padding: 0.85rem 0.75rem;
        outline: none;
        border-radius: 0.375rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        min-width: fit-content;
        width: 100%;
        white-space: nowrap;
        position: relative;
        background-color: #f7f7f7;
        border: 0.5px solid transparent;
        .Input {
            border: none;
            outline: none;
            padding: 0;
            color: #c7c7c7;
            font-size: 12px;
            width: calc(100% - 25px);
            background-color: inherit;
            text-overflow: ellipsis;

            &::placeholder {
                color: #8d8d8d;
                font-size: 12px;
            }
        }

        .SelectedValue {
            color: black;
        }

        .Frozen {
            pointer-events: none;
            cursor: pointer;
            caret-color: transparent;
        }

        .White {
            background-color: white;

            &::placeholder {
                color: black;
            }
        }

        .DropdownIcon {
            position: absolute;
            right: 15px;
            top: 50%;
            transform: translateY(-50%);
            transition: transform 200ms ease-in-out;
        }

        .DropdownIconInverted {
            transform: translateY(-50%) rotate(180deg);
        }

        .List {
            color: black;
            position: absolute;
            top: calc(100% + 10px);
            left: 0;
            font-size: 12px;
            background-color: white;
            max-height: 300px;
            min-width: 300px;
            width: 100%;
            overflow-y: auto;
            overflow-x: hidden;
            box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
            border-radius: 0.375rem;
            z-index: 5;
            @include scrollbar-style();

            .ListItem {
                display: flex;
                padding: 10px;
                align-items: center;
                gap: 0.5rem;

                &:hover {
                    background-color: var(--hover-bg);
                }
            }

            .ItemHovered {
                background-color: var(--hover-bg);
            }

            .MultiSelectItem {
                padding: 14px 10px;
            }
        }
    }

    .Tiny {
        padding: 8px 10px;
    }

    .White {
        background-color: white;
    }

    .ErrorMsg {
        position: absolute;
        left: 0;
        color: #ce121d;
        top: calc(100% + 2px);
        font-size: 10px;
    }

    .HideErrorMsg {
        visibility: hidden;
    }
}
.IconContainer {
    position: relative;
    width: 100%;
}

.IconStuff {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
}
//.Icon{
//    margin-left: 20px;
//}

.Error {
    border: 0.5px solid #df666a;
}

.Disabled {
    filter: grayscale(1);
    pointer-events: none;
    border: 0.5px solid #dedede;

    .Main {
        background-color: #ebebeb;

        .Input {
            background-color: #ebebeb;
        }
    }
}
