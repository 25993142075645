@import '../../../scss/animations';

.DrawerBackdrop {
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    z-index: 999;
    background-color: #00000040;

    display: flex;
    align-items: center;
    justify-content: flex-end;
    animation-name: blurIn;
    animation-timing-function: ease-in-out;
    animation-duration: 1s;
}

.CloseDrawerBackdrop {
    animation: blurOut 100ms ease-in-out;
}

.DrawerBody {
    //transform: translateX(0);
    backface-visibility: hidden;
    background: white;
    width: 1260px;
    height: 100%;
    position: relative;
    animation-name: moveIn;
    z-index: 998;
    animation: moveIn 500ms;
    animation-timing-function: cubic-bezier();
}

.closeIcon {
    position: absolute;
    top: 28px;
    right: 41px;
    cursor: pointer;
    z-index: 11;
}

.DrawerHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 72px;
    padding: 1.5rem;
    border-bottom: 1px solid #e7e7e7;

    font-family: 'Gilroy-Medium', sans-serif;
    font-weight: 500;
    font-size: 1rem;
    line-height: 27px;
    letter-spacing: 0.000559105px;

    & > img {
        cursor: pointer;

        &:hover {
            transform: scale(1.1);
        }
    }
}

.DrawerFooter {
    position: absolute;
    bottom: 0;
    background-color: #ffffff;
    width: 100%;
    min-height: 4.375rem;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0 4%;
    border-top: 0.0625rem solid #dcdcdc;

    .CtaContainer {
        display: flex;
        flex-direction: row-reverse;
    }
}

.CloseDrawer {
    animation: moveOut 500ms;
    animation-timing-function: cubic-bezier();
}

@keyframes moveIn {
    0% {
        transform: translateX(120%);
    }

    25% {
        transform: translateX(120%);
    }

    100% {
        transform: translateX(0);
    }
}

@keyframes moveOut {
    0% {
        transform: translateX(0);
    }

    25% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(120%);
    }
}

@keyframes blurIn {
    0% {
        background-color: #00000000;
    }

    100% {
        background-color: #00000040;
    }
}

@keyframes blurOut {
    0% {
        background-color: #00000040;
    }

    100% {
        background-color: #00000000;
    }
}
