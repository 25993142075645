// Variables
$border-color: #e0e0e0;
$hover-bg-color: #f9f9f9;
$radio-border-color: #d0d5dd;
$text-color: #333;
$link-color: #6a4cff;

// Container Styling
.container {
    margin: 1rem;
    border: 1px solid $border-color;
    border-radius: 0.5rem;
    background-color: #fff;
    font-family: Arial, sans-serif;
    color: $text-color;
    height: 72%;
    overflow-y: auto;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
    position: relative;
}

.header {
    display: flex;
    justify-content: space-between;
    position: sticky;
    font-size: 0.875rem;
    padding: 1rem;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
    background-color: #f9f9f9;
    border-bottom: 1px solid #e7e7e7;
    color: #545b62;
    font-family: 'Gilroy-semibold';
    .copyButton {
        display: flex;
        align-items: center;
        color: #6021b3;
        column-gap: 0.5rem;
        cursor: pointer;
    }
}

// List Styling
.docList {
    list-style: none;
    margin: 0;
    padding: 0;

    .listItem {
        display: flex;
        align-items: center;
        position: relative;
        justify-content: space-between;
        padding: 0.75rem 1rem;
        border-bottom: 1px solid $border-color;
        transition: background 0.3s ease;

        &:last-child {
            border-bottom: none;
        }

        &:hover {
            background-color: $hover-bg-color;
        }
    }

    .completed {
        font-size: 0.9rem;
        text-decoration: line-through;
    }

    .radioWrapper {
        margin-right: 1rem;

        input[type='radio'] {
            width: 1rem;
            height: 1rem;
            cursor: pointer;
            border: 1px solid $radio-border-color;
        }
    }

    .textWrapper {
        flex: 1;
        display: flex;
        align-items: center;
        gap: 1ch;
        .docName {
            font-size: 0.9rem;
            font-family: Gilroy-semibold, sans-serif;
            color: $text-color;
        }

        .docDetails {
            font-size: 0.9rem;

            font-family: Gilroy-Medium, sans-serif;
            color: #666;

            a {
                color: $link-color;
                text-decoration: none;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }

    .iconWrapper {
        margin-left: 1rem;
        display: flex;
        align-items: center;

        svg,
        img {
            width: 14px;
            height: 14px;
            cursor: pointer;
        }
    }
}

.radioButton {
    /* Remove default radio styles */
    -webkit-appearance: none;
    appearance: none;

    /* Base dimensions */
    width: 24px;
    height: 24px;

    /* Outer border (green) and shape */
    border: 3px solid #00bd00;
    border-radius: 50%;

    /* White background when unchecked */
    background-color: #ffffff;

    /* Misc styles */
    cursor: pointer;
    outline: none;
    position: relative; /* For pseudo-elements positioning */
    transition: all 0.3s ease-in-out;

    &:hover {
        border-color: #00bd00; /* Darker green on hover */
    }

    &:focus {
        //box-shadow: 0 0 0 4px rgba(40, 167, 69, 0.4); /* Green focus ring */
    }

    /* When the radio is checked: green background + slightly lighter border */
    &:checked {
        background-color: #00bd00;
        border-color: #00bd00 !important;
    }

    /* White "ring" with green fill in the center */
    &:checked::before {
        content: '';
        position: absolute;
        top: 0.5px;
        left: 0.5px;
        width: 11px;
        height: 11px;
        border: 1px solid #ffffff;
        background-color: #28a745;
        border-radius: 50%;
        z-index: 1;
    }

    /* White tick mark in the exact center */
    &:checked::after {
        content: '';
        position: absolute;
        top: 46%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(45deg);
        width: 2px;
        height: 5px;
        border: solid #ffffff;
        border-width: 0 1.3px 1.3px 0;
        z-index: 2;
    }
}

.radioButtonDisabled {
    @extend .radioButton;
    cursor: not-allowed !important;
    background: $hover-bg-color;
    border: 1px solid $border-color !important;
}

.dropdownMenu {
    position: absolute;
    top: 100%;
    right: 0;
    background: var(--tooltip-background-color);
    border: 1px solid #e7e7e7;
    border-radius: 4px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    z-index: 999;
    min-width: 100px;
    /* Adjust as needed */
}

.dropdownItem {
    padding: 8px 12px;
    cursor: pointer;
    font-size: 14px;
    font-family: Gilroy-Medium, sans-serif;

    &:hover {
        background: var(--primary-purple-highlight);
    }
}
.dropdownItemDelete {
    @extend .dropdownItem;
    color: #df666a;

    &:hover {
        background: #fff1f1;
    }
}

.MagicLink {
    display: flex;
    align-items: center;
    column-gap: 0.656rem;
    justify-content: center;
    img {
        position: relative;
        top: -0.02rem;
        cursor: pointer;
    }
}

.DynamicLink {
    cursor: pointer;
    font-family: Gilroy-Medium, sans-serif;
    font-size: 0.875rem;
    color: var(--primary-purple) !important;
    text-decoration: underline !important;
}
