@import '../../../../scss/animations';

.StatCard {
    width: 100%;
    border-radius: 15px;
    padding: 16px 20px;
    text-align: center;
    background-color: white;
    height: 80px;

    .Content {
        .Name {
            font-family: 'Gilroy-Bold', sans-serif;
            font-size: 12px;
            line-height: 13px;
            font-weight: 700;
            color: #08080d;

            display: flex;
            align-items: flex-start;
            justify-content: space-between;

            .Title {
                display: flex;
                align-items: center;
                white-space: nowrap;
            }
        }

        .Value {
            margin-top: 8px;
            font-family: 'Gilroy-Bold', sans-serif;
            font-weight: 700;
            font-size: 24px;
            line-height: 28px;
            text-align: left;
            display: flex;
            align-items: flex-end;
            column-gap: 8px;
            line-height: 30px;

            .Dates {
                font-family: 'Gilroy-Medium', sans-serif;
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                color: #939393;
                line-height: 24px;
                // line-height: 100%;
            }
        }
    }

    .Animate {
        @include animate-fadein(350ms, 0, 10px);
    }
}

.ShimmerTextStat {
    margin-bottom: 0px;
}

.ShimmerThumbStat {
    margin: 10px 0;
}

.PDFReport {
    border-radius: 8px;
    padding: 0.85rem;
    height: fit-content;

    .Content {
        .Name {
            font-size: 11px;
        }

        .Value {
            margin-top: 0.25rem;
            font-size: 18px;

            .Dates {
                font-size: 11px;
            }
        }
    }
}
