@import '../../scss/animations';
@import '../../scss/scrollbars';

$modal-padding: 1rem;
$base-gap: 1rem;
$extra-gap: 3rem;
$less-extra-gap: 1.5rem;
$audited-gap: 1.25rem;
$button-margin-top: 2rem;

.VerticalSeparator {
    margin: 0 1rem;
}

.DrawerHeading {
    display: flex;
    align-items: center;
    min-height: 72px;
    padding-left: 4%;
    font-size: 1rem;
    color: #000000;
    border-bottom: 0.0625rem solid #d3d3d3;
    .DrawerHeadingTitle {
        font-family: Gilroy-semibold, sans-serif;
    }
    @include no-bg-scroll();

    .CompanyName {
        font-weight: 500;
        color: #a9a9a9;
    }

    justify-content: space-between;

    .HeaderCTA {
        margin-right: 5.5rem;
    }
}

.DealDrawerLoader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90vh;
}

.customFetch {
    width: 0.875rem;
    height: 0.875rem;
    margin-right: 6px;
}

.ManageDealDrawer {
    background-color: #f7f7f7;
    height: 90vh;
    width: 100%;
    padding: 1.5rem 2.125rem;
    overflow-y: auto;
    @include no-bg-scroll;

    .Step {
        width: 100%;
    }

    .StepContainer {
        background-color: #ffffff;
        border-radius: 0.375rem;
        padding: 2.125rem;
        box-shadow: 0rem 0.0625rem 0.625rem 0rem #7090b029;
        margin-bottom: 70px;

        .Section {
            margin-bottom: 3rem;

            &:last-child {
                margin-bottom: 0;
            }

            .InputList {
                .InputItem {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-top: 40px;
                    padding-bottom: 24px;
                    border-bottom: 1px solid #f0f0f0;

                    &:last-child {
                        border-bottom: none;
                    }

                    .InputLabel {
                        display: flex;
                        align-items: center;
                        width: 50%;
                        justify-content: space-between;
                        font-size: 12px;
                        font-weight: 600;
                        color: #9d9d9d;
                    }

                    .SubLabel {
                        font-size: 12px;
                        font-weight: 400;
                        color: #8d8d8d;
                        width: 50%;
                        margin-top: 4px;
                    }

                    .RangeInput {
                        width: 50%;
                        display: flex;
                        align-items: center;
                        gap: 3.15rem;
                    }
                }
            }
        }
    }
}
.InputFieldContainer {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.DrawerFooter {
    position: absolute;
    bottom: 0;
    background-color: #ffffff;
    width: 100%;
    height: 10%;
    min-height: 4.375rem;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0 4%;
    border-top: 0.0625rem solid #dcdcdc;

    .CtaContainer {
        display: flex;
        flex-direction: row-reverse;

        .Button {
            padding: 0.75rem 1.5rem;
            border-radius: 0.25rem;
            font-size: 0.875rem;
            width: 9.875rem;
            font-weight: 600;
            margin-left: 1rem;
            cursor: pointer;

            &:last-child {
                margin-left: 0;
            }

            &.Primary {
                background-color: var(--primary-button-bg);
                color: #ffffff;
                border: none;

                &:disabled {
                    background-color: #d8d8d8;
                    color: #ffffff;
                }
            }

            &.Secondary {
                background-color: #ffffff;
                color: var(--secondary-button-text);
                border: 0.0625rem solid var(--border-color);
                &:disabled {
                    background-color: #ffffff;
                    border: 0.0625rem solid #d8d8d8;
                    color: #d8d8d8;
                }
            }
        }
    }
}

.NotAvailable {
    font-size: 12px;
    font-weight: 500;
    color: #8d8d8d;
    text-align: right;
    width: 50%;
}

.HorizontalSeparator {
    margin-top: 1.5rem;
    width: 100%;
    border-top: 1px solid #f0f0f0;
}
.DealsFormWrapper {
    display: flex;
    gap: 2.125rem;
}
.Dflex {
    display: flex;
    align-items: center;
    gap: 3rem;
}
.DflexColumn {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}
.slideUp {
    @include slide-up-animation;
}

.slideDown {
    @include slide-down-animation;
}
.fadeIn {
    @include animate-fadein(0.2s);
}
.smallBuild {
    min-width: 0;
}

.DflexFilters {
    position: relative;
    border-radius: 0.5rem;
    background-color: #ffffff;
    padding: 1.5rem;
    box-shadow: 0rem 0.0625rem 0.625rem 0rem #7090b029;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    border-left: 3px solid transparent;
    transition: all 0.3s ease;
}

.DflexFiltersDone {
    @extend .DflexFilters;
    border-left: 3px solid var(--primary-purple);
}

.DflexMutiDropDowns {
    @extend .Dflex;
    width: 100%;

    &:not(:first-child) {
        margin: 1.5rem 0;
        gap: 0.875rem;
    }

    &:last-child {
        margin-bottom: 0;
    }

    span {
        color: rgba(157, 157, 157, 1);
        font-size: 0.875rem;
    }
}

// ManageDealDrawer Modal css
.ModalContainer {
    padding: $modal-padding;
    height: 100%;
    display: flex;
    flex-direction: column;

    .ModalHeader {
        font-size: 1.5rem;
        color: #000;
        font-weight: 600;
    }

    .SubHeading {
        font-size: 0.875rem;
        color: rgba(157, 157, 157, 1);
        font-weight: 500;
        padding: 0.75rem 0;
    }

    .InputList {
        display: flex;
        flex-direction: column;
        gap: $base-gap;
    }

    &.ModalContainerExtraGap .InputList {
        margin-top: 1rem;
        gap: $extra-gap;
    }

    &.ModalContainerExtraWithLessGap {
        .InputList {
            gap: $less-extra-gap;
        }
        .CtaButtons {
            margin-top: $button-margin-top;
        }
    }

    &.ModalContainerWithAuditedData {
        .InputList {
            gap: $audited-gap;
        }

        .InputField {
            display: flex;
            flex-direction: column;
            gap: 0.5rem;
        }

        &.ModalContainerExtraGap {
            .InputList {
                gap: $extra-gap;
            }
            .CtaButtons {
                margin-top: 2.5rem;
            }
        }

        &.ModalContainerExtraWithLessGap {
            .InputList {
                gap: $audited-gap;
            }
            .CtaButtons {
                margin-top: 1rem;
            }
        }
    }

    .CtaButtons {
        display: flex;
        gap: 1.5rem;
        margin-top: auto;
        padding-top: 1rem;
    }
}

.financialYearLabel {
    font-size: 0.625rem;
    font-weight: 500;
    color: rgba(157, 157, 157, 1);

    span {
        color: #000000;
    }
}

.SectionHeading {
    font-size: 1rem;
    font-weight: 600;
    color: #000000;
    padding-bottom: 0.25rem;
}

.StepHeading {
    font-size: 0.875rem;
    color: #000000;
    margin-bottom: 1.5rem;

    .StepHeadingSecondaryText {
        color: #9d9d9d;
    }
}
