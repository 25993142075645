.GlobalSearchWrapper {
    display: flex;
    align-items: center;
    max-width: 100%;
    position: relative;
}

.ShowSearch {
    z-index: 1;
    position: absolute;
    right: 0;
    display: flex;
    font-size: 0.875rem;
    padding: 0.75rem 1rem;
    border: 1px solid var(--primary-border-colour);
    border-radius: 0.5rem;
    background-color: #ffffff;
    width: 50rem;
    transition: width 0.5s;
    outline: none;
    font-family: Gilroy-Medium, sans-serif;

    ::placeholder {
        color: #9d9d9d;
        font-size: 0.875rem;
        font-family: Gilroy-Medium, sans-serif;
    }
}

.HideSearch {
    @extend .ShowSearch;
    width: 0;
    padding: 0;
    border: 0;
    transition: none;
}

.CrossIcon {
    z-index: 1;
    cursor: pointer;
    position: absolute;
    right: 1rem;
    bottom: 25%;
}

.ShowSearchIcon {
    padding: 0.75rem;
    cursor: pointer;
    background: #ffffff;
    z-index: 2;
    animation: fadeIn 0.25s;
    border-radius: 0.5rem;
    transition: background 0.2s linear;
    &:hover {
        background: #f2f2f2;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.HideSearchIcon {
    @extend .ShowSearchIcon;
    opacity: 0;
    animation: fadeOut 0.1s;
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.SearchResults {
    position: absolute;
    overflow-y: auto;
    max-height: 30rem;
    top: 2.75rem;
    right: 0;
    width: 50rem;
    background: #ffffff;
    border-radius: 5px;
    z-index: 1;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    transition: all 0.2s linear;
}
.SearchResultHeading {
    background: #f7f7f7;
    font-size: 0.875rem;
    font-family: Gilroy-SemiBold, sans-serif;
    width: 100%;
    margin: 0;
    display: flex;
    padding: 0.75rem;
    align-items: center;
    justify-content: center;
    color: #9d9d9d;
}

.SearchResultItem {
    cursor: pointer;
    padding: 0.75rem 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 0.875rem;
    &:hover {
        background: #f2f2f2;
    }
}

.OrgName {
    margin: 0;
    font-family: Gilroy-Medium, sans-serif;
    color: #000000;
}

.WebsiteUrl {
    margin: 0;
    font-family: Gilroy-Medium, sans-serif;
    color: #bcbcbc;
}
.NoDataFound {
    padding: 2rem;
    text-align: left;
    font-family: Gilroy-Medium, sans-serif;
    color: #9d9d9d;
    font-size: 0.875rem;
}
