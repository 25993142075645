.Probability {
    display: flex;
    align-items: center;
    column-gap: 9px;

    .Value {
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        color: #7e4ce0;
        width: 28px;
        text-align: right;

        &.NotAvailable {
            color: #9b9b9b;
        }
    }

    .BarContainer {
        width: 60px;
        border: 1px solid #e5e5e5;
        height: 14px;
        background-color: #e5e9f2;
        position: relative;
        border-radius: 1.56px;

        .BarFill {
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 0;
            background: linear-gradient(
                90deg,
                #e6d9ff 0%,
                #aa7eff 50%,
                rgba(96, 33, 179, 0.75) 100%
            );
            border-radius: 1.56px;
            transition: width 0.5s ease-in-out;
        }
    }
}
