@import '../../../../../scss/animations';

.OperatingInflowReconciliation {
    .Title {
        font-size: 24px;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: 0.00023961660917848349px;
        text-align: left;

        display: flex;
        align-items: center;
        justify-content: space-between;

        .Left {
            display: flex;
            align-items: center;
            column-gap: 10px;
            color: black;
            white-space: nowrap;
            .Date {
                color: #939393;
            }
        }
    }

    .TableContainer {
        .OperatingInflowTable {
            @include animate-fadein(300ms, 0, 0px);
            box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
            margin-top: 16px;
            border-collapse: separate;
            border-spacing: 0;
            background-color: white;
            width: 100%;

            .TableHead {
                font-size: 0.875rem;
                line-height: 14px;
                font-family: 'Gilroy-SemiBold';
                text-align: left;
                color: #535b62;
                background: #f9fafc;
                border-right: 1px solid #ededed;

                .TableHeadRow {
                    .TableHeadRowItem {
                        padding: 0px 30px;
                        text-align: right;
                        border-right: 1px solid #ededed;
                        height: 2.75rem;
                        &:first-child {
                            background: #f9fafc;
                            // min-width: 300px;
                            // max-width: 300px;
                            text-align: left;
                            width: 400px;
                            position: sticky;
                            left: 0;
                            border-right: 1px solid #ededed;
                        }
                    }
                }
            }

            .TableBody {
                font-size: 0.875rem;

                .TableBodyRow {
                    .TableBodyRowItem {
                        padding: 0px 30px;
                        text-align: right;
                        color: black;
                        border-bottom: 1px solid #ededed;
                        border-right: 1px solid #ededed;
                        height: 2.75rem;
                        &:first-child {
                            font-weight: 600;
                            position: sticky;
                            left: 0;
                            background-color: white;
                            border-right: 1px solid #ededed;
                            text-align: left;
                        }

                        .FirstRowItem {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;

                            .Button {
                                color: #6021b3;
                                cursor: pointer;
                                font-weight: 400;

                                display: flex;
                                align-items: center;

                                & > img {
                                    margin-bottom: 2px;
                                }
                            }
                        }
                    }

                    .SubrowItem {
                        padding: 13px 30px;
                        background: #f7f7f7;
                        border-bottom: 1px solid #ededed;
                        text-align: right;

                        &:first-child {
                            background: #f7f7f7;
                            padding: 13px 30px 13px 50px;
                            font-weight: 500;
                            position: sticky;
                            left: 0;
                            text-align: left;
                            border-right: 1px solid #ededed;
                        }
                    }

                    &:last-child {
                        .TableBodyRowItem {
                            background: #f7f7f7;
                            border-top: 1px solid #09090971;
                            font-weight: 600;
                        }
                    }
                }
            }
        }
    }
}

.ShimmerBody {
    margin-bottom: 0;
}
.Nodata {
    height: 150px;
    font-size: 16px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
