.UploadDataWrap {
    top: 72px;
    width: 100%;
    height: calc(100vh - 72px);
    z-index: 1;
}

.UploadData {
    height: 100%;
    width: 100%;
    overflow: hidden;
}
.NotCompany {
    display: inline;
    grid-template-columns: 0.2fr 1.5fr;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
}

.Left {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 2rem;
    justify-content: flex-start;
    background-color: #ffffff;
    border-right: 1px solid #ebebeb;
    text-align: center;

    & > img {
        margin-bottom: 40px;
    }
}

.MagicUploadButton {
    display: flex;
    align-items: center;
    column-gap: 5px;
    font-size: 14px;
    cursor: pointer;
    background: var(--primary-bg-colour);
    color: #ffffff;
    padding: 0.75rem 1rem;
    border-radius: 4px;
    font-weight: 600;
    position: relative;
    overflow: hidden;

    & > img {
        margin-bottom: 2px;
    }

    &:hover {
        animation-play-state: paused;
    }
}

.MagicUploadButton::before,
.MagicUploadButton::after {
    content: '';
    position: absolute;
    width: 14px;
    height: 200%;
    background: linear-gradient(to left, #ffffff 4.79%, rgba(153, 153, 153, 0) 76.27%);
    opacity: 0.2;
    transform: rotate(17deg);
    animation: reflect 2s infinite;
}

.MagicUploadButton::after {
    animation-delay: 0.1s;
}

@keyframes reflect {
    0% {
        left: -150%;
    }
    100% {
        left: 110%;
    }
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

.Right {
    background-color: white;
    // display: flex;
    // flex-direction: column;

    .Top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1rem;
        box-shadow: 0px 0px 10px 0px #0000001a;
        margin-bottom: 1rem;
        .InviteUser {
            display: flex;
            align-items: center;
            gap: 0.5rem;
            cursor: pointer;
            color: var(--secondary-button-text);
            border-radius: 4px;
            border: 1px solid var(--secondary-button-border);
            padding: 0.8rem 1rem;
            font-size: 14px;
            font-weight: 600;
        }

        .ProfileButton {
            z-index: 100;
        }
    }

    .TopInvestor {
        padding: 20px 40px;
        color: #000;
        font-family: 'Gilroy-Medium';
        font-size: 20px;
        line-height: 23.54px;
        letter-spacing: 0.000319488812237978px;
        text-align: left;
    }

    .UploadContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100vh;

        .UploadJourneyContainer {
            height: 100%;
            width: 90%;
            overflow: hidden;
        }
    }
    .UploadContainerInvestor {
        background-color: #f7f7f7;
    }

    .UploadContainerInvestor {
        height: calc(100vh - 65px);
        padding: 0 40px;
    }
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

.redDot {
    position: absolute;
    height: 25px;
    width: 25px;
    right: -8%;
    top: -25%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.75rem;
    color: white;
    font-family: 'Gilroy-SemiBold', sans-serif;
    padding: 0.25rem 0.35rem 0.12rem 0.35rem;
    border-radius: 50%;
    background-color: red;
}

.EmailNotVerified {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 12px 4%;
    background-color: #ffe0d3;
    color: #08080d;
    font-size: 16px;
    font-family: 'Gilroy-SemiBold', sans-serif;

    .WarnIcon {
        margin-right: 1rem;
    }

    .ResendVerificationCta {
        background-color: transparent;
        border: none;
        padding: 0;
        font-size: 16px;
        font-family: 'Gilroy-SemiBold', sans-serif;
        color: #6021b3;
        cursor: pointer;
        margin-left: 4px;
    }
}

.GeneratingReportPill {
    position: fixed;
    bottom: 0px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 200;
}
