.FormStepperWrapper {
    background: #f7f7f7;
    position: relative;
    width: 15rem;
    .StepperContainer {
        position: fixed;
        background-color: #ffffff;
        border-radius: 0.375rem;
        padding: 0.5rem;
        box-shadow: 0rem 0.0625rem 0.625rem 0rem #7090b029;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
    }
    .StepperItem {
        display: flex;
        cursor: pointer;
        justify-content: space-between;
        align-items: center;
        padding: 1rem;
        font-size: 0.875rem;
        border-radius: 12px;
        color: #8a8b9f;
        &:hover {
            background: var(--hover-bg);
            color: var(--primary-text-colour);
        }
        .StepperText {
            font-family: Gilroy-semibold, sans-serif;
        }
    }
    .StepperItemDisabled {
        @extend .StepperItem;
        cursor: not-allowed;
        color: #d1d1d1;
        pointer-events: none;
        &:hover {
            background: #ffffff;
            color: #d1d1d1;
        }
    }
    .StepperItemActive {
        @extend .StepperItem;
        background: var(--secondary-bg-colour);
        color: var(--primary-text-colour);
    }
}
