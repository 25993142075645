.UnlockScanModal {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .Top {
        padding: 24px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid rgba(220, 220, 220, 1);

        font-size: 28px;
        font-weight: 600;
        line-height: 28px;
    }

    .Body {
        padding: 34px;

        .Points {
            margin-top: 30px;
            display: flex;
            flex-direction: column;
            row-gap: 20px;

            .Point {
                display: flex;
                align-items: flex-start;
                column-gap: 10px;

                font-size: 16px;
                font-weight: 500;
                line-height: 22px;

                & > img {
                    margin-bottom: 2px;
                }
            }
        }
    }

    .Footer {
        padding: 34px;
    }
}
