.searchBar {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    border-radius: 50px;
    background: #fdf0ec;
    color: #000000;
    padding: 0.25rem;
    z-index: 2;
    &:hover {
        box-shadow: 0px 0px 8px 0.5px #00000026;
    }
}

.input {
    border: none;
    outline: none;
    flex: 1;
    color: #000000;
    font-family: Gilroy-Medium, sans-serif;
    background: transparent;
    padding: 0.25rem 0.5rem 0.25rem 0.5rem;
    border-radius: 50px;
    font-size: 0.875rem;
    &::placeholder {
        color: #df666a;
    }
}

.searchIcon {
    width: 0.75rem;
    height: 0.75rem;
    background-image: url('https://fl-fe-assets.s3.ap-south-1.amazonaws.com/svg/MUSearchIconRed.svg');
    background-size: cover;
    cursor: pointer;
    margin-right: 0.5rem;
}

.dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: #fff;
    font-size: 0.75rem;
    border-radius: 5px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
    margin-top: 5px;
    z-index: 40;
    text-align: left;
}

.option {
    padding: 8px 12px;
    cursor: pointer;

    &:hover {
        background-color: var(--hover-bg);
    }
}
.optionDisabled {
    padding: 8px 12px;
    cursor: not-allowed;
    color: #ccc;
}

.addNewLead {
    padding: 8px 12px;
    display: flex;
    align-items: center;
    cursor: pointer;
    color: var(--primary-text-colour);
    border-top: 1px solid #ddd;

    &:hover {
        background-color: var(--hover-bg);
    }

    .addIcon {
        font-size: 20px;
        margin-right: 5px;
    }
}
