@import '../../../../../scss/card';
@import '../../../../../scss/scrollbars';

//declare a css variable column width

$columnWidth: 150px;
$stickyColumnWidth: 250px;
.Gstr3b {
    @include card-with-title;

    .Top {
        margin-top: 34px;
        display: flex;
        align-items: center;
        column-gap: 10px;
        justify-content: space-between;

        .Title {
            font-weight: 600;
            font-size: 18px;
        }

        .Left {
            display: flex;
            align-items: center;
            column-gap: 10px;

            & img {
                margin-top: 2px;
            }
        }

        .Nodata {
            font-size: 14px;
            font-weight: 500;
            line-height: 16.48px;
            color: #9d9d9d;
        }
    }

    .TopNoData {
        border-bottom: none;
        border-radius: 4px;
    }

    .Body {
        .TableContainer {
            border-radius: 8px;
            height: fit-content;
            overflow-x: auto;
            //@include small-grey-scroll;
            border: 1px solid #e7e7e7;
            overflow-y: visible;
            //width: fit-content;
            //min-width: fit-content;
            width: fit-content;
            max-width: 100%;

            .Headers {
                display: flex;
                align-items: center;
                background: #f9fafc;
                width: fit-content;

                .Header {
                    min-width: $columnWidth;
                    padding: 13px 16px;
                    background: #f9fafc;
                    border-bottom: 1px solid #eaecf0;
                    text-align: right;

                    font-size: 14px;
                    font-weight: 600;
                    line-height: 18px;
                    color: #535b62;

                    &:last-child {
                        border-left: 1px solid #eaecf0;
                    }
                }

                .Classification {
                    min-width: $stickyColumnWidth;
                    max-width: $stickyColumnWidth;
                    position: sticky;
                    left: 0;
                    text-align: left;
                    border-right: 1px solid #eaecf0;
                    z-index: 10;
                }

                .Total {
                    position: sticky;
                    right: 0;
                    z-index: 10;
                }
            }

            .Rows {
                .Row {
                    display: flex;
                    align-items: stretch;
                    border-bottom: 1px solid #eaecf0;
                    width: fit-content;

                    &:last-child {
                        border-bottom: none;

                        .RowItem {
                            background-color: #f7f7f7;
                        }
                    }

                    .RowItem {
                        min-width: $columnWidth;
                        background-color: white;
                        padding: 13px 16px;
                        text-align: right;

                        font-size: 14px;
                        font-weight: 500;
                        line-height: 18px;

                        .DataCountBadge {
                            width: fit-content;
                            padding: 5px 8px;
                            float: right;
                            border-radius: 16px;
                            display: flex;
                            align-items: center;
                            column-gap: 5px;

                            .InfoIcon {
                                //margin-bottom: 2px;
                                margin-top: 2px;
                            }
                        }

                        .Green {
                            color: #49a71b;
                            background: #e1ffe1;
                        }

                        .Yellow {
                            color: #e49e00;
                            background: #fef9ec;
                        }
                    }

                    .Classification {
                        min-width: $stickyColumnWidth;
                        max-width: $stickyColumnWidth;
                        position: sticky;
                        left: 0;
                        border-right: 1px solid #eaecf0;
                        text-align: left;
                        background: white;
                        z-index: 10;

                        display: flex;
                        align-items: center;
                    }

                    .Total {
                        border-left: 1px solid #eaecf0;

                        position: sticky;
                        right: 0;
                        z-index: 10;
                    }
                }
            }
        }
    }
}

.Tooltip {
    width: 100%;
    text-align: left;
    padding: 10px;

    .TopText {
        font-size: 14px;
        font-weight: 600;
        line-height: 22px;
        color: black;

        .Left {
            //color: red;
        }
    }

    .Gsts {
        .GstRow {
            margin-top: 16px;

            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 14px;
            line-height: 22px;
            color: black;
            font-weight: 400;
            font-family: 'Gilroy-Medium', sans-serif;

            .Right {
                .Badge {
                    width: fit-content;
                    padding: 5px 8px;
                    float: right;
                    border-radius: 16px;
                    display: flex;
                    align-items: center;
                    column-gap: 5px;
                    font-size: 12px;
                }
                .NotConnected {
                    color: #e49e00;
                    background: #fef9ec;
                }
                .Connected {
                    color: #49a71b;
                    background: #e1ffe1;
                }
                .Uploaded {
                    color: #49a71b;
                    background: #e1ffe1;
                }
            }

            .State {
                color: #999999;
            }
        }
    }
}
