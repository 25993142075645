@import '../../../../../scss/card';
@import '../../../../../scss/animations';

.DirectorCibil {
    @include data-upload-card;
    @include animate-fadein(300ms, 0, 0);
    margin-top: 30px;
    color: #000;
    font-size: 14px;
    font-weight: 500;
    line-height: 150%;

    .Title {
        color: #000;
        font-size: 16px;
        font-weight: 600;
        line-height: 16px;
    }

    .Text {
        margin-bottom: 16px;

        margin-top: 16px;
    }

    .Check {
        margin: 16px 0;
        display: flex;
        align-items: center;
        color: #161513;
        font-size: 13.75px;
        font-weight: 500;
        line-height: 16px;

        & > input {
            margin-right: 5px;
            margin-bottom: 2px;
        }
    }
}
