.TableContainer {
    max-height: 100%;
    overflow-x: auto;
    overflow-y: auto;
}
.LoadingContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
}

.Table {
    width: 100%;
    height: 100%;
    border-spacing: 0;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    border-collapse: separate;
}
.TableHeaders {
    background-color: #f9fafc;
    font-family: 'Gilroy-Bold', serif;
    z-index: 10;
    position: sticky;
    top: 0px;
    height: 2.75rem;
    line-height: 10px;
    font-size: 0.875rem;

    .TableHeader {
        text-align: left;
        position: sticky;
        top: 0;
        color: #535b62;
        background: #f9fafc;
        font-family: 'Gilroy-SemiBold';
        .HeaderCell {
            border-bottom: 1px solid #e0e0e0;
            min-width: 10rem;
            padding: 1rem;
            background: #f9fafc;
            border-bottom: 1px solid #e0e0e0;
            text-align: right;
        }
        .FrozenCell {
            top: 0;
            border-bottom: 1px solid #e0e0e0;
            min-width: 10rem;
            position: sticky;
            padding: 1rem;
            background: #f9fafc;
            left: 0;
            z-index: 5;
            border-right: 1px solid #ededed;
            box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05);
            border-bottom: 1px solid #e0e0e0;
        }
    }
}
.TableBody {
    vertical-align: baseline;
    .LabelRow {
        .LabelCell {
            padding: 0.5rem 0.5rem;
            border-bottom: 1px solid #e0e0e0;
            text-align: center;
            font-family: 'Gilroy-SemiBold', sans-serif;
            position: sticky;
            left: 0;
            top: 0;
            // background-color: red;
            height: 2.75rem;
        }
    }
    .SubLabelRow {
        .SubLabelCell {
            padding: 0.5rem 0.5rem;
            border-bottom: 1px solid #e0e0e0;
            // border-right: 1px solid #e0e0e0;
            position: sticky;
            left: 0;
            height: 2.75rem;
        }
    }
    .TableRow {
        text-align: right;
        height: 2.75rem;
        .FrozenCell {
            min-width: 10rem;
            position: sticky;
            left: 0;
            padding: 0.5rem;
            z-index: 5;
            border-right: 1px solid #ededed;
            box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05);
            border-bottom: 1px solid #e0e0e0;
            text-align: left;
        }
        .TableCell {
            padding: 0.5rem 1rem;
            border-bottom: 1px solid #e0e0e0;
        }
        .CellText {
            display: inline-flex;
            align-items: center;
        }
    }
    .Label {
        border-radius: 20px;
        height: 22px;
        width: 22px;
        // padding: 3px;
        margin-right: 8px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.PDF {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-family: 'Gilroy-Medium';
    font-size: 12px;
    line-height: 14.12px;
    letter-spacing: 0.00019968050764873624px;
    text-align: center;
    column-gap: 5px;
    font-size: 14px;
    cursor: pointer;
    background-color: var(--secondary-bg-colour);
    color: var(--primary-text-colour);
    padding: 5px 8px;
    border-radius: 64px;
    white-space: nowrap;
    gap: 8px;

    img {
        margin-bottom: 1px;
    }
}

.NA {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-family: 'Gilroy-Medium';
    font-size: 12px;
    line-height: 14.12px;
    letter-spacing: 0.00019968050764873624px;
    text-align: center;
    column-gap: 5px;
    font-size: 14px;
    background-color: #f7f7f7;
    color: #ced3db;
    padding: 5px 8px;
    border-radius: 64px;
    white-space: nowrap;
    gap: 8px;

    img {
        margin-bottom: 1px;
    }
}

.RefDocRow {
    display: none;
}

.PDFReport {
    .Table {
        .TableHeaders {
            height: fit-content;

            .TableHeader {
                font-size: 10px;

                .FrozenCell {
                    padding: 0.5rem 1rem;
                }

                .HeaderCell {
                    padding: 0.5rem 1rem;
                }
            }
        }

        .TableBody {
            .LabelRow {
                font-size: 10px;

                .LabelCell {
                    height: fit-content;
                    padding: 4px;
                }
            }

            .SubLabelRow {
                font-size: 10px;

                .SubLabelCell {
                    height: fit-content;
                }
            }

            .TableRow {
                text-align: right;
                font-size: 10px;
                height: fit-content;

                .FrozenCell {
                }

                .TableCell {
                    padding: 4px;
                    border-bottom: 1px solid #e0e0e0;
                }
            }

            .Label {
                font-size: 8px;
                height: 20px;
                width: 20px;
            }
        }
    }
}
