.Container {
    margin-bottom: 2.125rem;
    background-color: white;
    border-radius: 0.5rem;

    .HeaderContainer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 0.063rem solid #e8e8e7;
        padding: 1.023rem 0;
    }
    .Title {
        font-size: 1.125rem;
        font-family: 'Gilroy-SemiBold';
        line-height: 1.688rem;
        opacity: 0.7;
        padding-left: 1.5rem;
        padding-top: 1rem;
    }
    .UpdateDate {
        font-size: 0.875rem;
        font-family: 'gilroy-medium';
        color: #9d9d9d;
        padding-right: 1.5rem;
    }

    .TableWrapper {
        border: 0.063rem solid #e8e8e7;
        margin-top: 1.5rem;
        border-radius: 0.5rem;
        overflow: hidden;
        margin: 1.023rem 1.5rem;
    }

    .Table {
        width: 100%;
        border-collapse: collapse;
        background-color: white;
        table-layout: fixed; /* Ensures the table layout is fixed */

        .TableHead {
            font-size: 0.875rem;
            font-family: 'Gilroy-SemiBold';
            line-height: 1.125rem;
            text-align: left;
            color: #535b62;
            background: #f9fafc;
            border-bottom: 0.063rem solid #e7e7e7;

            .TableHeadRow {
                .TableHeadRowItem {
                    // padding: 0px 2.875rem;
                    white-space: nowrap;
                    height: 2.75rem;
                    padding: 0 1.875rem;

                    &:first-child {
                        background: #f9fafc;
                        width: 10%;
                        position: sticky;
                        left: 0;
                    }
                    &:nth-child(2) {
                        width: 15%;
                        padding: 0 1rem;
                    }
                    &:nth-child(3) {
                        width: 25%;
                        padding: 0 1rem;
                    }
                    &:nth-child(4) {
                        width: 25%;
                        padding: 0 1rem;
                    }
                    &:nth-child(5) {
                        width: 10%;
                        padding: 0rem 0rem 0rem 1rem;
                    }
                    &:nth-child(6) {
                        width: 15%;
                        padding: 0 1rem;
                    }
                    &:nth-child(7) {
                        width: 4%;
                    }
                }
            }
        }
        .TableHeadNoData {
            display: grid;
            place-items: center;
            padding: 3.125rem 0;
            font-weight: 500;
            grid-template-columns: repeat(5, 1fr);
        }

        .TableBody {
            font-size: 0.875rem;
            font-weight: 500;
            line-height: 1.125rem;
            letter-spacing: 0em;
            text-align: left;
            vertical-align: top;

            .TableBodyRow {
                .TableBodyRowItem {
                    color: black;
                    border-bottom: 0.063rem solid #ededed;
                    height: 2.75rem;
                    overflow-wrap: anywhere;
                    white-space: break-spaces;

                    &:first-child {
                        padding: 1rem 1.5rem;
                        overflow-wrap: normal;
                        position: sticky;
                        left: 0;
                        background-color: white;
                    }
                    &:nth-child(7) {
                        padding: 1rem;
                    }
                    .NoCases {
                        display: flex;
                        align-items: center;
                        column-gap: 0.625rem;
                        & > img {
                            margin-bottom: 0.125rem;
                        }
                    }
                }

                .Truncate {
                    // overflow: hidden;
                    // text-overflow: ellipsis;
                    max-width: 32ch;
                    display: block;
                    padding: 1rem;
                }

                .Nodata {
                    padding: 3.125rem 0;
                    text-align: center;
                    font-weight: 500 !important;
                }

                .Status {
                    padding: 0.188rem 0.5rem 0.125rem 0.5rem;
                    width: fit-content;
                    border-radius: 3.125rem;
                    font-size: 0.875rem;
                    height: 1.5rem;
                }

                .StatusOpen {
                    color: #df666a;
                    background: #fdf0ec;
                }

                .StatusClosed {
                    background: #f5f5f5;
                    color: #b4b4b4;
                }

                .StatusNotAvailable {
                    background-color: #e5e5e5;
                    color: rgb(120, 120, 120);
                }
                &:last-child {
                    .TableBodyRowItem {
                        border-bottom: none;
                    }
                }
            }
        }
    }

    .GeneratingData {
        margin-top: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        background-color: white;
        border-radius: 0.5rem;
    }
    .Note {
        font-size: 0.875rem;
        padding-top: 1rem;
        color: #9d9d9d;
        padding-bottom: 1rem;
        margin: 1.023rem 1.5rem;
    }
    .downloadIconContainer {
        cursor: pointer;
    }
}

.PDFTitle {
    background-color: white;
    padding: 0.85rem;
    font-weight: 600;
    font-size: 14px;
    border-radius: 8px;
    margin-bottom: 24px;
}

.PDFReportContainer {
    .HeaderContainer {
        padding: 0.85rem;

        .Title {
            font-size: 14px;
            padding: 0;
        }

        .UpdateDate {
            font-size: 12px;
            padding: 0;
        }
    }

    .TableWrapper {
        margin: 0.85rem;

        .Table {
            .TableHead {
                .TableHeadRow {
                    .TableHeadRowItem {
                        font-size: 11px;
                    }
                }
            }

            .TableBody {
                .TableBodyRow {
                    .TableBodyRowItem {
                        padding: 0.65rem;

                        .Status {
                            font-size: 10px;
                        }

                        .Truncate {
                            padding: 0;
                        }
                    }
                }
            }
        }
    }
    .Note {
        font-size: 11px;
    }
}
