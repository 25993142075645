.GstPopup {
    padding: 10px;
    width: 400px;

    .Title {
        font-size: 14px;
        font-weight: 600;
        line-height: 22px;
        letter-spacing: 0.00019968050764873624px;
        text-align: left;
        color: #000000;
    }

    .GSTRow {
        margin-top: 16px;

        display: flex;
        align-items: center;
        justify-content: space-between;

        font-size: 14px;
        font-weight: 500;
        line-height: 22px;

        .Left {
            .State {
                color: #999999;
            }

            .GstNum {
            }
        }

        .Right {
            .Badge {
                width: fit-content;
                padding: 2px 8px;
                border-radius: 16px;
                font-family: 'Gilroy-Medium', sans-serif;
                font-size: 12px;
            }

            .Connected {
                color: #49a71b;
                background: #e1ffe1;
            }

            .NotConnected {
                color: #e49e00;
                background: #fef9ec;
            }

            .Uploaded {
                background: #e7e7e7;
                color: black;
            }
        }
    }
}
