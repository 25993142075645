@import '../../../scss/animations';
@import '../../../scss/breakpoints';

.InvestorSignup {
    @include animate-fadein(300ms, 0, 0);
    height: 100vh;
    display: flex;
    flex-wrap: wrap;
}

.Left {
    width: 40%;
    background-image: url('https://fl-fe-assets.s3.ap-south-1.amazonaws.com/svg/investorSignupBackground.svg');
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #f9f9f9;
    text-align: center;
    h1 {
        font-size: 2.5rem;
        background: linear-gradient(
            180deg,
            #6021b3 100%,
            #bb429d 100%,
            #7628d8 100%,
            #6021b3 100%,
            #6021b3 100%
        );
        -webkit-text-fill-color: transparent;
        -webkit-background-clip: text;
        background-clip: text;
        margin-bottom: 0;
    }

    .Logo {
        margin-bottom: 40px;
        height: 75px;
    }
}

.Right {
    width: 60%;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    text-align: center;
    flex-direction: column;
    justify-content: center;
}

@include sm {
    .InvestorSignup {
        display: flex;
        flex-direction: column;
    }
    .Left {
        width: 100%;
        height: 30%;
    }
    .Right {
        width: 100%;
        margin-top: -1rem;
        height: 70%;
        border-top-left-radius: 16px;
        border-top-right-radius: 16px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
}
