@import '../../../../../../scss/card';

.ChargeDetails {
    @include new-overview-card;

    .Table {
        border-radius: 8px;
        overflow: hidden;
        background: #fff;
        border: 1px solid #e8e8e7;
        font-size: 0.875rem;

        .ChargesHeaders {
            padding: 16px 16px;
            color: #535b62;
            font-family: 'Gilroy-SemiBold';
            background-color: #f9fafc;
            display: grid;
            align-items: center;
            grid-template-columns: 2fr 1fr 2fr repeat(4, 1fr);
            border-bottom: 1px solid #d7dfe9;
            height: 2.75rem;
            white-space: nowrap;
            gap: 1rem;
        }
        .AmountHeader {
            text-align: right;
            padding-right: 0.75rem;
        }

        .TotalsRow {
            background: #f5f5f5;
        }
        .ChargesRows {
            color: #000;
            font-weight: 400;
            padding: 16px 16px;
            display: grid;
            align-items: center;
            gap: 1rem;
            grid-template-columns: 2fr 1fr 2fr repeat(4, 1fr);
            border-bottom: 1px solid #d7dfe9;

            .RowItem {
                width: 100%;
                overflow: hidden;
            }

            .NoWrap {
                white-space: nowrap;
            }
            .Amount {
                text-align: right;
                padding-right: 0.75rem;
            }
            .Total {
                font-weight: 700;
            }
            &:last-child {
                border-bottom: none;
            }
        }

        .FixHeight {
            height: 2.75rem;
        }

        .Wrap {
            white-space: normal;
            height: fit-content;
        }
    }
}

.PDFReport {
    .Top {
        padding: 0.85rem;

        .Title {
            font-size: 14px;
        }
    }

    .Body {
        padding: 0.85rem;

        .Table {
            font-size: 11px;

            .ChargesHeaders {
                padding: 0.6rem;
            }

            .ChargesRows {
                padding: 0.6rem;

                .RowItem {
                    //padding: 0.8rem;
                    text-overflow: clip;

                    &.Status {
                        padding: 0.25rem 0.75rem;
                        border-radius: 0.5rem;
                        width: fit-content;
                        transform: scale(0.8);
                    }
                }

                .Amount {
                    padding-right: 0.8rem;
                }

                .Total {
                    //padding: 0.8rem;
                }
            }

            .FixHeight {
                height: fit-content;
            }
        }
    }
}
