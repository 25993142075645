@import '../../../../../../../scss/animations';

.Container {
    position: relative;
    height: 100%;
}

.TableContainer {
    height: 100%;
    width: 100%;

    .Table {
        table-layout: fixed;
        width: 100%;
        border-collapse: separate;
        border-spacing: 0;
        // overflow-y: scroll;

        tr {
            height: 50px;
        }

        tr:last-child {
            height: auto;
        }

        .TableHead {
            background-color: #f9fafc;
            z-index: 10;
            position: sticky;
            top: 0;

            text-align: left;

            color: #535b62;
            font-size: 14px;
            font-weight: 500;
            line-height: 18px;
            width: 100%;
            height: 44px;

            .HeadItem {
                font-size: 14px;
                padding: 15px 20px 15px 10px;
                text-align: left;
                white-space: nowrap;
                border-bottom: 1px solid #d7d7d7;
                width: 10%;
            }

            .HeadMain {
                @extend .HeadItem;
                padding-right: 40px;
                width: 20%;
            }

            .HeadFirst {
                @extend .HeadItem;
                padding-left: 30px;
            }

            .HeadComment {
                font-size: 14px;
                padding: 15px 20px 15px 10px;
                text-align: left;
                white-space: nowrap;
                border-bottom: 1px solid #d7d7d7;
                width: 18%;
            }

            .HideLabel {
                font-size: 0;
            }

            //.HeadItem:first-child {
            //    color: red;
            //}

            .HeadAction {
                width: 2%;
            }
        }

        .TableBody {
            @include animate-fadein(200ms, 0, 5px);
            font-family: 'Gilroy-Medium';
            font-size: 14px;
            line-height: 12px;
            z-index: 1;
            position: relative;
            vertical-align: middle;

            .BorderBottom {
                border-bottom: 1px solid #d7d7d7;
            }

            .NoBorderTop {
                border-top: none;
            }

            .Sticky {
                position: sticky;
                left: 0;
                border-right: 1px solid rgb(237, 237, 237);
                box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
            }

            .FirstColumn {
                display: flex;
                align-items: center;
                width: 100%;
            }

            .Label {
                display: inline-block;
                border-radius: 20px;
                height: 22px;
                width: 22px;
                // padding: 3px;
                margin-right: 8px;
                text-align: center;

                display: flex;
                align-items: center;
                justify-content: center;
            }

            .Section_Heading {
                padding: 11px 0 11px 30px;
                text-align: center;
                font-family: 'Gilroy-Semibold';
            }

            .Heading {
                padding: 11px 0 11px 30px;
                background: white;
            }
        }
    }

    &::-webkit-scrollbar {
        display: none;
    }

    &::-webkit-scrollbar-track {
        display: none;
    }

    &::-webkit-scrollbar-thumb {
        display: none;
    }
}

.Row {
    background: white;
    // height: 72px;

    .WebsiteUrl {
        padding: 0 0.5rem;
        width: 80%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .RowItem {
        padding: 17px 10px 17px 10px;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px;
        border-bottom: 1px solid #f0f0f0;
        height: 72px;

        &_Ellipsis {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        &:first-child {
            padding-left: 30px;
            font-size: 14px;
            font-style: normal;
            line-height: 20px;
        }

        &:nth-child(2) {
            font-weight: 500;
            text-transform: uppercase;
        }

        .RowLoading {
            width: 40%;
            margin-bottom: 0;
        }
    }

    .ThreeDotRowItem {
        max-width: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .CommentRowItem {
        padding: 0 !important;
    }
}

.HoverState {
    &:hover {
        cursor: pointer;
        background-color: var(--hover-bg);
        color: var(--primary-text-colour);
    }
}

.HoverStateCommentsActive {
    background-color: var(--primary-purple-highlight);
    // color: var(--primary-text-colour);
}

.Gst {
    height: fit-content;
    width: fit-content;
    padding: 2px 8px;
    border-radius: 44px;
    background: #f5f5f5;
}

.Alerts {
    padding: 2px 8px;
    color: #df666a;
    background: #fff4f5;
    width: fit-content;
    border-radius: 44px;
    // cursor: pointer;
    text-decoration: underline;
}

.AlertCount {
    border: none;
    box-shadow: none;
    background: none;
    display: flex;
    align-items: center;
    color: #df666a;
    cursor: pointer;

    img {
        margin-right: 0.5rem;
    }

    &.Disabled {
        pointer-events: none;
        color: #9d9d9d;
    }
}

.DisabledDate {
    color: #9d9d9d;
}

.LastUpdated {
    color: #667085;
}

.Loading {
    font-family: 'Gilroy-SemiBold', sans-serif;
    font-size: 16px;
    line-height: 19.09px;
    padding: 220px 0 20px 0;
    background-color: white;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.MonitoringWrap {
    display: flex;
    align-items: center;

    .MonitoringDot {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        margin-right: 10px;

        &.Green {
            background-color: #3eb702;
        }

        &.Red {
            background-color: #df666a;
        }
    }
}
