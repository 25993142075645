.Wrapper {
    position: relative;
}
.Dropdown {
    z-index: 1000;
    border-radius: 8px;
    box-shadow: 0px 4px 10px 0px #00000026;
    background-color: #fff;
    height: fit-content;
    transition: all 0.2s ease-in-out;
    position: absolute;
    width: 100%;
    .Option {
        padding: 0.5rem;
        cursor: pointer;
        &:hover {
            background-color: var(--hover-bg);
        }
    }
    .OptionWithSubtext {
        @extend .Option;
        display: flex;
        font-size: 0.75rem;
        gap: 1rem;
        align-items: center;
        span {
            font-size: 0.75rem;
            color: #9c9c9c;
            width: 20ch;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    .Disabled {
        cursor: not-allowed;
        pointer-events: none;
        color: rgb(194, 194, 194);
    }
}
.Input {
    border: 1px solid #d2d2d2;
    padding: 0.5rem 2rem 0.5rem 0.5rem;
    border-radius: 4px;
    font-family: Gilroy-SemiBold, sans-serif;
    &:focus {
        outline: none;
        border: 1px solid #9c9c9c;
    }
    &:active {
        outline: none;
        border: 1px solid #9c9c9c;
    }
    &:valid {
        outline: none;
        border: 1px solid #9c9c9c;
    }
}
.InputNoB {
    border: none;
    padding: 0.75rem 2rem 0.5rem 0.5rem;
    width: 90%;
    border-radius: 4px;
    font-family: Gilroy-SemiBold, sans-serif;
    &:focus {
        outline: none;
        border: none;
    }
    &:active {
        outline: none;
        border: none;
    }
    &:valid {
        outline: none;
        border: none;
    }
}
.DropdownIcon {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    transition: transform 200ms ease-in-out;
}
.DropdownIconInverted {
    transform: translateY(-50%) rotate(180deg);
}
