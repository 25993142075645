.ProfileButton {
    .MainButton {
        background-color: #f9f9f9;
        color: black;
        padding: 10px 16px;
        font-size: 14px;
        font-weight: 600;
        line-height: 24px;
        border-radius: 5px;
        cursor: pointer;
        position: relative;

        display: flex;
        align-items: center;
        column-gap: 6px;

        &:hover {
            background-color: var(--hover-bg);
        }

        .DropdownImg {
            transition: transform 200ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
        }

        .logoImage {
            background-color: transparent;
            height: 1.2rem;
            width: auto;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 0.25rem;
            margin-right: 0.125rem;
        }

        .DropdownImgActive {
            transform: rotate(180deg);
        }

        .Options {
            box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 5px;
            position: absolute;
            right: 0;
            top: 100%;
            background-color: white;
            width: 12rem;
            border-radius: 6px;
            margin-top: 0.5rem;
            overflow: hidden;

            .Option {
                padding: 10px 10px;
                white-space: nowrap;
                display: flex;
                align-items: center;
                gap: 0.5rem;
                justify-content: space-between;
                column-gap: 10px;
                border-bottom: 1px solid #eaecf0;
                font-weight: 500;
                &:hover {
                    background: var(--hover-bg);
                    color: var(--primary-text-colour);
                }

                &:last-child {
                    border-bottom: none;
                }

                .OptionImage {
                    height: 15px;
                }
                span {
                    text-align: left;
                    width: 100%;
                    position: static;
                }
            }
        }
        .companyLogo {
            display: flex;
            flex-direction: row;
            gap: 0.5rem;
            font-size: 0.75rem;
            max-width: 11rem;

            .companyLogoPlaceholder {
                width: 1.5rem;
                height: 1.5rem;
                object-fit: cover;
                border-radius: 8px;
                background-color: rgba(0, 0, 0, 1);
                color: #ffffff;
                font-weight: 700;
                text-align: center;
                flex-shrink: 0;
            }

            .companyLogoText {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                max-width: 6rem;
                font-weight: 600;
            }
        }
    }

    .MainButtonActive {
        background-color: #f3f1f1;
    }
}
