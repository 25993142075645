// @import '../../../scss/animations';

.Button {
    // @include animate-fadein(400ms, 0, 0);
    padding: 10px 24px;
    margin-top: 25px;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: white;
    border-radius: 6px;
    cursor: pointer;
    text-align: center;
    background-color: var(--primary-button-bg);
    border: 1px solid;

    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    // flex-wrap: nowrap;
    position: relative;
    transition: all 150ms ease-in-out;

    &:active {
        transform: translateY(2px);
    }

    .Icon {
        margin-right: 5px;
        height: 1rem;
        width: 1rem;
    }

    // .Label {}
    &:focus {
        outline: none;
    }

    .Loader {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

.White {
    color: black;
    border: 1px solid black;
    background-color: white;
}

.Blue {
    background-color: #4c8dff;
    border: 1px solid #4c8dff;
    color: white;
}

.BlueInverted {
    background-color: white;
    border: 1px solid #4c8dff;
    color: #4c8dff;
}

.Purple {
    background-color: var(--primary-purple);
    border: 1px solid var(--primary-purple);
    color: white;

    &:hover {
        background-color: var(--primary-purple-hovered);
    }
}

.orange {
    background-color: var(--primary-orange);
    border: 1px solid var(--primary-orange);
    color: white;
}
.orangeInverted {
    background-color: transparent;
    border: 1px solid var(--primary-orange);

    color: var(--primary-orange);
}
.PurpleInverted {
    background-color: transparent;
    border: 1px solid var(--secondary-button-border);
    color: var(--secondary-button-text);
}
.primary {
    background-color: var(--primary-button-bg);
    color: var(--primary-button-text);
}
.secondary {
    background-color: var(--secondary-button-bg);
    border: 1px solid var(--border-color);
    color: var(--secondary-button-text);
}
.tertiary {
    color: var(--tertiary-button-text);
}
.Disabled {
    background: #d8d8d8;
    pointer-events: none;
    border: 1px solid #d8d8d8;
    cursor: not-allowed;
}

.ClickDisabled {
    pointer-events: none;
}

.Fit {
    width: fit-content;
}

.Tiny {
    padding: 4px 16px;
    font-weight: 500;
    font-size: 10px;
    line-height: 14px;
}

.Small {
    padding: 7px 18px;
    font-weight: 500;
    font-size: 14px;
    line-height: 15px;
    font-family: 'Gilroy-SemiBold', sans-serif;
}

.Wide {
    width: 100%;
}

.Hide {
    visibility: hidden;
}
