.Embed {
    width: fit-content;
    position: relative;
    margin-right: 20px;

    img,
    iframe {
        width: 100%;
        height: 100%;
    }

    button {
        position: absolute;
        bottom: -6px;
        right: 0;
    }
}
