.threeDotMenu {
    position: relative;
    display: inline-block;
}

.icon {
    font-size: 28px;
    cursor: pointer;
    display: flex;
    font-weight: bold;
    align-items: center;
    justify-content: center;
    color: var(--primary-text-colour);
    width: 40px;
    height: 40px;
    padding: 0 5px 0 5px;
    border-radius: 8px;
    text-align: center;

    &:hover {
        background-color: var(--hover-bg);
    }
}

.ManageEligibilityMenu {
    color: black;
    width: 6.563rem;
    overflow-x: hidden;
}

.menu {
    position: absolute;
    top: 110%;
    right: 0;
    background: white;
    z-index: 1000;
    overflow-y: auto;
    white-space: nowrap;
    max-width: 20rem;
    max-height: 20rem;
    border-radius: 0.5rem;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
}

.menu ul {
    list-style: none;
    width: 14rem;
    margin: 0;
    padding: 0;
}

.Delete {
    background-color: #ffeeee;
    color: #df666a;
    &:hover {
        background-color: #df666a !important;
        color: white;
    }
}
.menu li {
    padding: 10px 30px;
    cursor: pointer;
    border-bottom: 1px solid #eaecf0;
    font-size: 0.875rem;
    line-height: 19px;
    font-family: 'Gilroy-Medium', sans-serif;
    &:hover {
        background-color: #f7f7f7;
    }
}
.ManageEligibilityMenu li {
    width: 6.563rem;
    padding: 0.75rem;
    text-align: left;
}
