.CommentsBox {
    position: relative;
    border-radius: 4px;
    height: 100%;
    width: 100%;
    overflow: hidden;
    min-height: 3.125rem;
    display: flex;
    align-items: center;

    .Container {
        position: absolute;
        left: 0;
        top: 0;
        height: calc(100% - 16px);
        width: calc(100% - 16px);
        margin: 8px;
        border-radius: 8px;
        color: #979797;
        transition: all 0.1s ease-in-out;

        &:hover {
            background-color: #f2e8ff;
            color: #6021b3;
            box-shadow: 0px 0px 8px 0.5px #00000026;
        }

        .CommentsText {
            font-size: 12px;
            padding: 4px 0.5rem;
            word-break: break-word;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            white-space: break-spaces;

            &.NotEmpty {
                color: #000000;
            }
        }

        .AddComment {
            font-size: 12px;
            padding: 0.5rem;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
        }
    }

    .ContainerHoverActive {
        background-color: #f2e8ff;
        color: #6021b3;
        box-shadow: 0px 0px 8px 0.5px #00000026;
    }
}

.Date {
    font-family: Gilroy-Light, sans-serif;
    font-size: 10px;
}

.ButtonContainerHidden {
    position: absolute;
    transform: translateY(300%);
}

.ButtonContainerVisible {
    position: absolute;
    top: 0;
    left: 0;
    padding: 0 0.75rem;
    transition: transform 0.1s ease-in-out;
    transform: translateY(0);
    display: flex;
    align-items: center;
    margin: 0;
    gap: 1rem;
    background-color: rgba(249, 245, 255, 1);
    height: 100%;
    width: 100%;
    z-index: 2;
}

.CommentButton {
    color: var(--primary-text-colour);
    background: #ffffffcc;
    border: 1px solid var(--primary-border-colour);
    padding: 0.5rem;
    border-radius: 6px;
    display: flex;
    gap: 0.25rem;
    justify-content: center;
    white-space: nowrap;
    align-items: center;
    flex-wrap: nowrap;
    width: 100%;
    cursor: pointer;
}

.CommentsEditBox {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 4px;
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    z-index: 10;
    //gap: 0.5rem; // For spacing between textarea and buttons
}

.TextArea {
    width: 100%;
    border-radius: 4px;
    height: auto;
    padding: 0.5rem;
    font-size: 12px;
    font-family: Gilroy-Medium, sans-serif;
    border: 1px solid var(--primary-border-colour);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    outline: none;
    overflow-y: hidden;
    resize: none;
    z-index: 9;
}

.SubmitButton,
.CancelButton {
    padding: 0 1rem;
    border-radius: 0 0 4px 4px;
    border: none;
    font-size: 14px;
    cursor: pointer;
}

.SubmitButton {
    background-color: var(--primary-bg-colour);
    color: white;
    margin-right: 0.25rem;
    z-index: 1000;
}

.CancelButton {
    background-color: white;
    color: var(--primary-text-colour);
    border-left: 1px solid var(--primary-border-colour);
    border-right: 1px solid var(--primary-border-colour);
    border-bottom: 1px solid var(--primary-border-colour);
    z-index: 1000;
    margin-right: 0.5rem; // Space between Cancel and Submit buttons
}

.ButtonContainer {
    display: flex;
    justify-content: flex-end;
    gap: 0.5rem;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(255, 255, 255, 0.5);
    z-index: 9;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
}
