@import '../../../../../../scss/animations';
@import '../../../../../../scss/card';
@import '../../../../../../scss/colors';

.Report {
    @include animate-fadein(300ms, 100px, 0);

    .Breadcrumb {
        display: flex;
        align-items: center;
        column-gap: 8px;

        .Blue {
            color: var(--primary-text-colour);
            font-size: 16px;
            font-weight: 700;
            cursor: pointer;
            display: flex;
            align-items: center;
            gap: 0.5rem;

            img {
                margin-bottom: 2px;
            }
        }

        & > img {
            margin-bottom: 3px;
        }
    }

    .TypeSelector {
        margin-top: 36px;
    }

    .ChartDataTypeSection {
        .Section {
            .TopCard {
                margin-top: 34px;
                @include new-overview-card;

                .Title {
                    font-family: 'Gilroy-SemiBold';
                    font-size: 18px;
                    line-height: 24px;
                    letter-spacing: 0.00023961660917848349px;
                    text-align: left;
                    color: #000000;
                }
            }

            .ChartsPanel {
                display: grid;
                grid-template-columns: 1fr 1fr;
                column-gap: 40px;

                .Card {
                    // @include overview-card;
                    border-radius: 8px;
                    border: 1px solid #e8e8e7;

                    .Metrics {
                        display: flex;
                        flex-direction: column;

                        .MetricTitle {
                            min-height: 24px;
                            color: #000;
                            font-size: 14px;
                            font-weight: 600;
                            line-height: 14px;
                        }

                        & > div {
                            color: #000;
                            font-size: 14px;
                            font-weight: 500;
                            line-height: 20px;
                            text-align: left;

                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            padding: 10px 16px;
                            border-bottom: 1px solid #e8e8e7;

                            &:last-child {
                                border-bottom: none;
                            }
                        }

                        .MetricValue {
                            // border: 1px solid red;
                            border-radius: 16px;
                            min-width: 50px;
                            padding: 5px 8px;
                            display: flex;
                            align-items: center;
                            justify-content: center;

                            font-size: 12px;
                            font-weight: 500;
                            line-height: 14.12px;
                            letter-spacing: 0.00019968050764873624px;
                            text-align: center;
                        }

                        .GrayPill {
                            background: #f7f7f7;
                            color: #000;
                        }
                    }
                }
            }
        }

        .ChartSections {
            .ChartSection {
                margin-top: 40px;

                .SectionTitle {
                    color: #08080d;
                    font-size: 22px;
                    font-weight: 700;
                }

                .GraphsContainer {
                    margin-top: 16px;
                    display: grid;
                    padding: 0;
                    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
                    row-gap: 40px;
                    column-gap: 40px;
                }
            }
        }
    }

    .TableDataTypeSection {
        margin-top: 36px;
    }

    .Close {
        @include animate-fadein(500ms, 400, 0);
    }
}

.Right {
    display: flex;
    gap: 34px;
}
