@import '../../../../../../scss/animations.scss';
@import '../../../../../../scss/card';

.Header {
    color: black;

    .Sub {
        color: #a9a9a9;
        margin-left: 0.2rem;
    }
}

.FinancialMetrics {
    padding: 24px;
    height: calc(100vh - 78px);
    overflow-y: auto;
    background-color: #f7f7f7;
    @include animate-fadein(400ms, 200px, 0px);
}

.OverviewScreenContainer {
    background-color: white;
    border-radius: 8px;
    .Header {
        // display: flex;
        // align-items: center;
        // justify-content: space-between;
        // vertical-align: center;
        padding: 20px 24px;
        height: 3.875rem;
        font-family: 'Gilroy-SemiBold';
        font-size: 18px;
        font-weight: 400;
        text-align: left;

        .Cta {
            color: var(--primary-text-colour);
            font-size: 14px;
            font-weight: 600;
            line-height: 18px;
            text-align: center;

            display: flex;
            align-items: center;
            column-gap: 8px;
            cursor: pointer;
        }

        .NodataText {
            font-size: 14px;
            font-weight: 600;
            line-height: 18px;
            text-align: center;
            color: #9d9d9d;
        }
    }

    .Body {
        border-top: 1px solid #e8e8e7;

        padding: 24px;
    }
}

.Table {
    border: 1px solid #e8e8e7;
    border-radius: 8px;
    overflow: visible;
    background-color: white;
    font-size: 14px;
    &.overviewScreen {
        @include cta-card;
        padding: 0;
        &:hover {
            box-shadow: none;
        }
    }
    .TableHeadRow {
        display: grid;
        grid-template-columns: 2fr 1fr 1fr 1fr;
        border-bottom: 1px solid #e8e8e7;
        background: #f9fafc;
        color: #535b62;
        border-radius: 8px 8px 0 0;

        .TableHeadRowItem {
            padding: 13px 16px;
            text-align: right;
            font-weight: 600;
            border-bottom: 1px solid #e8e8e7;

            &:first-child {
                text-align: left;
                border-right: 1px solid #e8e8e7;
            }

            &:last-child {
                border-right: none;
            }
        }
    }

    .SeparatorRow {
        background-color: #f7f7f7;
        text-align: center;
        padding: 13px 16px;
        border-bottom: 1px solid #e8e8e7;
        color: #939393;
        font-weight: 600;
    }

    .TableBodyRow {
        display: grid;
        grid-template-columns: 2fr 1fr 1fr 1fr;

        .TableBodyRowItem {
            padding: 13px 16px;
            text-align: right;
            border-bottom: 1px solid #e8e8e7;
            color: #676767;

            &:first-child {
                text-align: left;
                font-weight: 600;
                border-right: 1px solid #e8e8e7;
                color: black;

                display: flex;
                align-items: center;
                justify-content: space-between;
            }

            &:last-child {
                border-right: none;
            }
        }
        .sectionEnd {
            border-bottom: 0.063rem solid black;
        }
    }

    .Nodata {
        padding: 100px;
        text-align: center;
    }
}

.Bold {
    color: rgb(85, 85, 85);
    font-weight: 600;
}
.dashboard {
    display: flex;
    justify-content: space-between;
}
.LoaderMessage {
    display: flex;
    align-items: center;
    column-gap: 0.313rem;
    font-size: 0.875rem;
    color: #9d9d9d;
    font-weight: 500;
}

.PDFCard {
    @include new-overview-card;

    .Top {
        padding: 0.8rem;

        .Title {
            font-size: 16px;
        }
    }

    .Body {
        padding: 0.85rem;

        .Table {
            .TableHeadRow {
                .TableHeadRowItem {
                    font-size: 11px;
                    padding: 0.7rem 0.8rem;
                }
            }

            .SeparatorRow {
                font-size: 11px;
                padding: 0.7rem 0.8rem;
            }

            .TableBodyRow {
                font-size: 11px;

                .TableBodyRowItem {
                    padding: 0.7rem 0.8rem;
                }
            }
        }
    }
}
