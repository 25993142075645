@import '../../../../../../../../../scss/animations';
@import '../../../../../../../../../scss/scrollbars';

.StatusDropdown {
    min-width: 90px;

    .DropdownToggle {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 6px 10px 4px;
        background: transparent;
        border-radius: 44px;
        border: none;
        cursor: pointer;
        max-width: 100%;
        overflow-x: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        .SelectedText {
            width: 100%;
            overflow-x: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        .DropdownCaret {
            margin-left: 6px;
            margin-bottom: 2px;
            transition: 0.25s;

            &.CaretOpen {
                transform: rotate(-180deg);
            }
        }

        .DropdownCustomCaretIcon {
            margin-left: 6px;
            margin-bottom: 2px;
            transition: 0.25s;
            margin-top: 4px;

            &.CaretOpenIcon {
                transform: rotate(-180deg);
                margin-top: 0;
                margin-left: 6px;
                margin-bottom: 8px;
            }
        }
    }

    .DropdownMenu {
        width: 8%;
        min-width: 136px;
        max-height: 250px;
        overflow: auto;
        display: flex;
        flex-direction: column;
        position: absolute;
        background-color: #ffffff;
        border-radius: 4px;
        margin-top: 8px;
        padding: 0;
        z-index: 1;
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
        @include animate-fadein(250ms);
        @include scrollbar-show-on-hover;

        .DropdownCell {
            display: flex;
            background-color: #ffffff;
            border: none;
            padding: 8px 12px;
            border-bottom: 1px solid #eaecf0;
            cursor: pointer;

            &:hover {
                background-color: var(--hover-bg);
            }

            &:first-child {
                border-top-left-radius: 4px;
                border-top-right-radius: 4px;
            }

            &:last-child {
                border-bottom-left-radius: 4px;
                border-bottom-right-radius: 4px;
                border-bottom: none;
            }

            .OptionPill {
                display: inline;
                padding: 4px 10px 2px;
                border-radius: 44px;
                font-size: 14px;
                font-weight: 500;
                cursor: pointer;
                overflow-x: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }
    }
}
