@import '../../scss/animations';

.AicaOverviewWrapper {
    padding: 1.25rem;
    border-radius: 0.5rem;
    background-color: #fff;
}
.AnimationContainer {
    :first-child {
        @include animate-fadein(400ms, 0px, 10px);
    }
}

.LoadingContianer {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}
.LoadingDots {
    margin-left: -0.5rem;
    width: auto;
    overflow: hidden;
    color: var(--primary-text-colour);
    animation: dot1 1s steps(3, end) infinite;
}

@keyframes dot1 {
    0% {
        width: 0ch;
    }
    33% {
        width: 1ch;
    }
    66% {
        width: 2ch;
    }
    100% {
        width: 3ch;
    }
}
