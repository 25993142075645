.Toolbar {
    background: rgb(255, 255, 255);
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 15px 20px 15px 10px;
    row-gap: 15px;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 2;
    border-bottom: 1px solid #e5e5e5;
    height: 72px;

    .ToolbarLeft {
        display: flex;
        align-items: flex-start;
    }

    .ToolbarRight {
        // margin-right: 30px;
        display: flex;
        align-items: center;
        column-gap: 20px;

        .CommentsIconContainer {
            height: 40px;
            width: 40px;
            padding: 5px;

            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            border-radius: 8px;

            &:hover {
                background-color: #f2f2f2;
            }

            &_HoverActive {
                background-color: #f2f2f2;
            }
        }
    }
}

.ToolbarGrp {
    margin: 0 10px;

    & > * {
        margin-right: 10px;
        cursor: pointer;
    }
}

.Select {
    height: 30px;
    border: none;
    width: 60px;
}

.TableMsg {
    color: #787878;
    justify-self: flex-end;
    float: right;
}

.CloseIcon {
    margin-right: 4px;
    cursor: pointer;

    &:hover {
        transform: scale(1.1);
    }
}
