.popupContainer {
    position: fixed;
    bottom: 20px;
    right: 20px;
    max-height: 21rem;
    width: 11.3rem;
    background: rgb(255, 255, 255);
    padding: 16px;
    border-radius: 8px;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.3);
    z-index: 998;
    color: white;
    text-align: left;

    animation: slideIn 0.7s cubic-bezier(0.34, 1.56, 0.64, 1);

    &.closing {
        animation: slideOut 0.7s cubic-bezier(0.34, 1.56, 0.64, 1) forwards;
    }
}

@keyframes slideIn {
    0% {
        transform: translateY(100px);
        opacity: 0;
    }
    60% {
        transform: translateY(-30px);
        opacity: 0.8;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes slideOut {
    0% {
        transform: translateY(0);
        opacity: 1;
    }
    40% {
        transform: translateY(-30px);
        opacity: 0.8;
    }
    100% {
        transform: translateY(100px);
        opacity: 0;
    }
}

.popupContent {
    position: relative;
    background: #fff;
    padding: 4px;
    border-radius: 12px;
    color: #333;
    max-height: calc(21rem - 32px);
    overflow-y: auto;
    scrollbar-width: thin;
    text-align: center;

    &::-webkit-scrollbar {
        width: 6px;
    }

    &::-webkit-scrollbar-track {
        background: #f1f1f1;
        border-radius: 3px;
    }

    &::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 3px;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
}

.closeButton {
    position: absolute;
    top: -12px;
    right: -12px;
    background-color: black;
    border-radius: 100%;
    border: none;
    font-size: 30px;
    cursor: pointer;
    color: #ffffff;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    padding-top: 4px;
    margin: 0;
}

.textBlock {
    font-size: 12px;
    margin-bottom: 10px;
    text-align: center;
    font-size: 12px;
    color: #9d9d9d;
    font-family: 'Gilroy-Medium', sans-serif;
}

.image {
    width: 55%;
    height: auto;
    margin: 10px auto;
    border-radius: 6px;
    display: block;
}

.ctaButton {
    display: block;
    margin: 10px auto;
    padding: 6px;
    color: #0056b3;
    text-align: center;
    transition: background 0.3s;
    width: fit-content;
    min-width: 60%;

    &:hover {
        color: #0056b3;
    }
}

.headingBlock {
    font-size: 14px;
    font-weight: 600;
    margin: 0 0 12px;
    color: #222;
    text-align: center;
}

.divider {
    height: 1px;
    background: rgba(0, 0, 0, 0.1);
    margin: 12px 0;
    width: 100%;
}
