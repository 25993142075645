@import '../../../../../../scss/card';

.KeyInvestors {
    @include new-overview-card;

    .Body {
        .Container {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            align-items: stretch;
            column-gap: 24px;
            row-gap: 24px;

            .Investor {
                @include cta-card;

                .Image {
                    height: 70px;
                    aspect-ratio: 1/1;
                    border: 1px solid #f2f2f26e;
                    border-radius: 4px;

                    & > img {
                        height: 100%;
                        width: 100%;
                    }
                }

                .Name {
                    margin-top: 16px;
                    font-size: 14px;
                    font-weight: 700;
                    line-height: 16.8px;
                    text-align: left;
                    color: #000000;
                }
            }
        }
    }
}
