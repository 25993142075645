.mainHeading {
    font-family: Gilroy-SemiBold;
    font-size: 16px;
}
.HaveDebtToggle {
    .ToggleText {
        font-size: 12px;
        font-family: Gilroy-Medium, serif;
    }
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.LoanInfoBanner {
    margin-top: 1rem;
    border-radius: 5px;
    display: flex;
    align-items: center;
    gap: 1rem;
    padding: 0.5rem 1rem;
    background-color: var(--secondary-bg-colour);
    .BannerText {
        font-size: 12px;
        font-family: Gilroy-Medium, serif;
    }
}

.UploadContainer {
    transition: max-height 0.5s ease-in-out;
    overflow: hidden;
    &.show {
        height: auto;
    }
    h5 {
        font-family: Gilroy-Semibold, serif;
        font-size: 0.75rem;
        color: #000000;
        margin-bottom: 1rem;
    }
    .sampleLink {
        font-family: Gilroy-Semibold, serif;
        font-size: 0.75rem;
        color: var(--primary-text-colour);
        text-decoration: none;
        cursor: pointer;
    }
}
