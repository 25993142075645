.file {
    display: grid;
    grid-template-columns: 2.3fr 0.3fr 0.5fr 0.1fr 0.1fr;
    height: 3.438rem;
    border-bottom: 1px solid #eaecf0;
    align-items: center;
    cursor: pointer;
    position: relative;
    overflow: visible;
    &:hover {
        background: var(--hover-bg);
    }
}
.fileLenderView {
    display: grid;
    grid-template-columns: 2.3fr 0.3fr 0.5fr 0.1fr 0.1fr;
    padding: 0.75rem 0;
    border-bottom: 1px solid #eaecf0;
    align-items: center;
    cursor: pointer;
    position: relative;
    overflow: visible;
    &:hover {
        background: var(--hover-bg);
    }
}

.FileDate {
    display: flex;
    color: #afafaf;
    font-family: Gilroy-Regular, sans-serif;
    font-size: 0.8rem;
}
.downloadIconContainer {
    background: transparent;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0.5rem;
    margin: 0 0.5rem;
    border-radius: 8px;
    img {
        height: 1rem;
        width: 1rem;
    }
}

.downloadIconContainer:hover {
    background: #faf9f97f;
}
.FileIconContainer {
    img {
        width: 1rem;
        height: 1rem;
    }
}
.FileName {
    color: #000000;
    font-family: Gilroy-SemiBold, sans-serif;
    font-size: 0.87rem;
    max-width: 100ch;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.ThreeDots {
    position: relative; // Makes sure that OtherOptions are positioned relative to this element
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
        width: 1rem;
        height: 1rem;
    }
}
.OtherOptions {
    position: fixed;
    z-index: 999;
    background: #fff;
    box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.2);
    border-radius: 0.25rem;
    min-width: 5.125rem;
    display: flex;
    width: 3rem;
    flex-direction: column;
    div {
        font-size: 0.875rem;
        padding: 0.5rem 1rem;
        cursor: pointer;
        &:hover {
            background: var(--hover-bg);
            border-radius: 8px;
        }
    }
}

.threeDotVertical {
    cursor: pointer;
    width: 1.875rem;
    height: 1.875rem;
}

.separator {
    margin: 0;
    padding: 0;
    height: 3.4rem;
    width: 0.0625rem;
    background-color: #d0d5dd;
}
.labelStatus {
    border-radius: 44px;
    color: var(--primary-bg-colour);
    background-color: var(--secondary-bg-colour);
    padding: 0.25rem 0.5rem;
    font-size: 14px;
    width: 67px;

    /* Center text */
    display: flex;
    align-items: center; /* Vertical center */
    justify-content: center; /* Horizontal center */
    text-align: center;
    height: 24px; /* Adjust height if needed */
}
