@import '../../../scss/scrollbars';

$fontSize18Px: 1.125rem;

.ManageUsers {
    border-radius: 0.938rem;
    background: white;
}
.HeadingText {
    color: #101828;
    font-family: Gilroy-SemiBold, sans-serif;
    padding-right: 1.5rem;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 600;
    margin: 0;
}

.CategorySelector {
    padding-left: 1.5rem;
}

.HeaderContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 24px;
    height: 72px;

    .LeftSection {
        display: flex;
        align-items: center;
    }

    .RightSection {
        display: flex;
        justify-content: space-between;
        align-items: center;
        column-gap: 12px;
    }

    .SearchContainer {
        // margin-top: 24px;
        // margin-right: 16px;
        // width: 300px;
    }
}
.TableContainer {
    // margin-top: 1.5rem;
    overflow-y: auto;
    scrollbar-gutter: stable;
    height: calc(100vh - 316px);
    @include scrollbar-show-on-hover;
}
.PaginationContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #ffffff;
    border-radius: 0 0 1rem 1rem;
    border-top: 1px solid #eaecf0;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0);
    padding: 0.75rem 1.5rem;
}
.ButtonContainer {
    display: flex;
    align-items: center;
    column-gap: 1.25rem;
}
.PaginationText {
    color: #344054;
    font-family: Gilroy-semibold, sans-serif;
    font-size: 0.875rem;
    font-style: normal;
    line-height: 1.25rem;
}

.Btn {
    padding: 8px 12px;
    color: #344054;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    cursor: pointer;

    border-radius: 8px;
    border: 1px solid var(--gray-300, #d0d5dd);
    background: var(--white, #fff);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

    &:active {
        transform: translateY(2px);
    }
}

.Disabled {
    box-shadow: none;
    border: 1px solid var(--gray-300, #dce1e8);
    color: #c6c6c6;
    cursor: not-allowed;
    pointer-events: none;
}

/* Company ManageUser css */
.ManageUserForCompanySide {
    .HeaderContainerForCompanySide {
        display: flex;
        position: sticky;
        top: 0;
        z-index: 10;
        height: 4.5rem;
        flex-direction: row;
        align-items: center;
        padding: 1.375rem 2.125rem;
        background-color: #ffffff;
        border-bottom: 1px solid #e7e7e7;

        .HeadingTextForCompany {
            color: black;
            border-right: 1px solid #d9d9d9;
            padding-right: 0.5rem;
            font-size: $fontSize18Px;
            font-weight: 600;
        }

        .CompanyName {
            color: rgba(157, 157, 157, 1);
            font-weight: 500;
            font-size: $fontSize18Px;
            padding-left: 0.5rem;
        }
    }

    .TableContainerCompanySide {
        height: auto;
        border-radius: 0.8rem;
        margin: 1.5rem;
        background-color: #ffffff;
        border: 1px solid #e7e7e7;
        margin-bottom: 124px;
    }
}

.LoadingContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
}
