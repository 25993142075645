.SingleFile {
    border-radius: 4px;
    border: 0.5px solid #d9d9d9;
    background: #f9f9f9;
    color: var(--primary-text-colour);
    font-size: 12px;
    font-weight: 600;
    line-height: 12px;
    padding: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .FileName {
        width: 15rem;
        overflow: hidden;
        text-overflow: ellipsis;
        &:hover {
            text-decoration: underline;
            cursor: pointer;
        }
    }

    .DeleteIcon {
        cursor: pointer;
        &:hover {
            transform: scale(1.2);
        }
    }
}
.FileButton {
    width: 45%;
    padding: 0.75rem;
    background-color: #fff;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--primary-text-colour);
    border: 1px solid var(--primary-text-colour);
    cursor: pointer;
    &:disabled {
        border: 1px solid #bcbcbc;
        color: #bcbcbc;
        background-color: #ffff;
    }
}

.FileButtonInv {
    @extend .FileButton;
    border: 1px solid var(--primary-border-colour);
    color: var(--primary-text-colour);
}
