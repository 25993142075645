.AIBadge {
    padding: 6px 8px;
    display: flex;
    align-items: center;

    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    text-align: center;
    color: #c293ff;

    .Img {
        margin-right: 5px;
        margin-bottom: 2px;
        width: 16px;
    }
}
