.tableoption {
    display: flex;
    margin: 5px 2px;
    gap: 5px;
}

.tableoption {
    white-space: nowrap;
}

.tableinput {
    display: grid;
    grid-template-columns: auto auto auto auto auto auto;
    gap: 3px;
}

.tableunit {
    width: 15px;
    height: 15px;
    border: 1px solid lightgray;
}

.EditorTable {
    width: 100%;
    border-collapse: collapse;
    // page-break-inside: avoid;
    tbody {
        tr {
            page-break-inside: avoid;
            page-break-after: auto;
            td {
                padding: 12px 14px;
                border: 1px solid #dbdbdb;

                &:first-child {
                    color: #979797;
                    width: 300px;
                }
            }

            &:first-child {
                color: black;
                font-weight: 600;
                background: #eaeaea;

                td {
                    &:first-child {
                        color: black;
                    }
                }
            }
        }
    }
    p {
        page-break-inside: avoid;
    }
}

.TableWithoutHeaders {
    width: 100%;
    border-collapse: collapse;

    tr {
        page-break-inside: avoid;
        page-break-after: auto;
    }

    th,
    td {
        border: 1px solid #dbdbdb;
    }

    td {
        padding: 6px 18px;

        &:first-child {
            color: #979797;
            width: 300px;
        }
    }
    p {
        page-break-inside: avoid;
    }
}

table {
}

tr {
    page-break-inside: avoid;
    page-break-after: auto;
}

p {
    page-break-inside: avoid;
}
