.selectBox {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    border-radius: 50px;
    background: #fdf0ec;
    color: #000000;
    padding: 0.5rem 0.75rem;
    outline: none;
    border: none;
    z-index: 2;
    cursor: pointer;
    font-family: Gilroy-Medium, sans-serif;
    font-size: 0.875rem;
    &::placeholder {
        font-family: Gilroy-Medium, sans-serif;
        color: #df666a;
    }
    &:hover {
        box-shadow: 0px 0px 8px 0.5px #00000026;
    }
}
.Input {
    background-color: #ffffff !important;
    border: 1px solid var(--primary-border-colour) !important;
    &::placeholder {
        color: #000000;
    }
    &:hover {
        box-shadow: none !important;
        color: #000000 !important;
        background: #ffffff !important;
    }
}
.FilteredOptionAvailableInput {
    @extend .selectBox;
    &::placeholder {
        color: #000000 !important;
    }
}
.selectedBox {
    @extend .selectBox;
    background: #f5f5f5;
    color: #000000;
    &:hover {
        box-shadow: 0px 0px 8px 0px #00000040;
        background: var(--secondary-bg-colour);
        color: var(--primary-text-colour);
    }
}
.finalized {
    @extend .selectBox;
    background: #f5f5f5;
    color: #000000;
    &:hover {
        box-shadow: none !important;
        background: #f5f5f5;
        color: #000000;
    }
}

.selectedText {
    flex: 1;
    padding: 0.25rem 0.5rem;
}

.arrowIcon {
    z-index: 2;
    position: absolute;
    top: 35%;
    right: 1rem;
    width: 0.75rem;
    height: 0.75rem;
    background-image: url('https://fl-fe-assets.s3.ap-south-1.amazonaws.com/svg/MUSearchIconRed.svg'); // Replace with the correct path to your arrow icon
    background-size: cover;
}
.crossIcon {
    z-index: 2;
    position: absolute;
    top: 35%;
    right: 1rem;
    filter: grayscale(1);
    width: 0.5rem;
    height: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 10rem;
    overflow-y: auto;
    background-color: #fff;
    font-size: 0.875rem;
    border-radius: 5px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
    margin-top: 5px;
    z-index: 4;
    text-align: left;
}

.option {
    padding: 8px 12px;
    cursor: pointer;

    &:hover {
        background-color: var(--hover-bg);
    }
}

.addNewLead {
    padding: 8px 12px;
    display: flex;
    align-items: center;
    cursor: pointer;
    color: var(--primary-text-colour);
    border-top: 1px solid #ddd;

    &:hover {
        background-color: var(--hover-bg);
    }

    .addIcon {
        font-size: 20px;
        margin-right: 5px;
    }
}
