.searchBox {
    position: relative;

    .boxWrapper {
        position: relative;
        display: flex;
        align-items: center;
        width: 24.438rem;
        height: 2.563rem;
        border-radius: 0.5rem;
        background-color: #f9f9f9;
        border: 1px solid #f9f9f9;
        transition: width 200ms ease-in-out;

        .searchInput {
            width: 100%;
            box-sizing: border-box;
            padding: 0.5rem 0.875rem;
            color: black;
            font-family: 'Gilroy-Medium';
            font-size: 0.875rem;
            line-height: 1.5rem;
            background-color: #f9f9f9;
            border: 1px solid #f9f9f9;
            border-radius: 0.5rem;

            &:active,
            &:focus {
                border: 1px solid #ebebeb;
                outline: none;
            }

            &:disabled {
                background-color: #ebebeb;
                color: #979797;
                border: 1px solid #ebebeb;
            }

            &::placeholder {
                color: #c3c3c3;
                font-family: 'Gilroy-Medium';
                font-size: 0.875rem;
                line-height: 1.5rem;
            }
        }

        .searchIcon {
            position: absolute;
            right: 0.875rem;
            width: 0.938rem;
            height: 0.938rem;
            pointer-events: none;
        }

        .cancelIcon {
            position: absolute;
            right: 0.875rem;
            cursor: pointer;
            height: 0.693rem;
            width: 0.693rem;
        }

        .HasData {
            background: #ffffff;
            border: 1px solid var(--primary-border-colour) !important;
        }
    }
}

.dropdownContainer {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background: white;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    margin-top: 4px;
    max-height: 200px;
    overflow-y: auto;
    z-index: 1000;
    box-shadow: 0px 0px 10px 0px #0000001a;
}

.dropdownItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 12px;
    cursor: pointer;
    height: 2.75rem;
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 0.875rem;
    font-family: 'Gilroy-Medium';

    &:hover {
        background-color: #f5f5f5;
    }
}
@media (max-width: 1700px) {
    .searchBoxCommentsActive {
        .boxWrapper {
            width: 20.438rem;
        }
    }
}

@media (max-width: 1400px) {
    .searchBoxCommentsActive {
        .boxWrapper {
            width: 10.438rem;
        }
    }
}
