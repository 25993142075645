.FileWithDescription {
    background-color: white;
    border-radius: 4px;

    .Row {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 35px;
        margin-top: 20px;
    }

    .FileInfo {
        cursor: pointer;
        border-radius: 6px;
        border: 1px solid #ebebeb;

        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: 5px;

        color: var(--primary-text-colour);
        font-size: 10px;
        font-weight: 600;
    }
    .DisableFileInfo {
        filter: grayscale(1);
        pointer-events: none;
    }

    .FileInfoDownload {
        cursor: pointer;
        border-radius: 6px;
        border: 1px solid #ebebeb;
        padding: 10px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;

        color: var(--primary-text-colour);
        font-size: 10px;
        font-weight: 600;
        // width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        &:hover {
            text-decoration: underline;
        }
    }

    .Bottom {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-top: 20px;

        .AddButton {
            cursor: pointer;
            width: fit-content;
            padding: 9px 10px;
            color: var(--primary-text-colour);
            font-size: 12px;
            font-weight: 600;
            line-height: 16px;
            box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);

            display: flex;
            align-items: center;
            column-gap: 5px;

            & > img {
                margin-bottom: 2px;
            }
        }
        .DisableAdd {
            filter: grayscale(1);
            opacity: 0.5;
            pointer-events: none;
        }
    }
}
.FileLabel {
    font-size: 0.75rem;
    font-family: Gilroy-semibold, sans-serif;
    color: #000000;
    padding: 1rem 1rem 1rem 0;
}
.Disabled {
    opacity: 0.4;
    pointer-events: none;
}
