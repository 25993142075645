@import '../../../../../../scss/card';
@import '../../../../../../scss/scrollbars';

.KeyCompetitors {
    @include new-overview-card;

    .Body {
        .Container {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            align-items: stretch;
            column-gap: 24px;
            row-gap: 24px;

            .Card {
                @include cta-card;
                height: 250px;

                .Image {
                    height: 70px;
                    aspect-ratio: 1/1;
                    border-radius: 4px;
                    overflow: hidden;
                }

                .Title {
                    margin-top: 16px;
                    font-size: 14px;
                    font-weight: 700;
                    line-height: 16.8px;
                    text-align: left;
                    color: #000000;
                }

                .Text {
                    margin-top: 16px;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 16px;
                    text-align: left;
                    color: #676767;
                    // border: 1px solid red;
                    max-height: 100px;
                    overflow: auto;
                    @include no-bg-scroll;
                }
            }
        }

        .PDFReport {
            grid-template-columns: 1fr 1fr 1fr;
        }
    }
}

.PDFReport {
    .Top {
        padding: 0.85rem;

        .Title {
            font-size: 14px;
        }
    }

    .Body {
        padding: 0.85rem;

        .Container {
            grid-template-columns: 1fr 1fr;
            align-items: stretch;
            .Card {
                padding: 0.85rem;
                height: auto;

                .Title {
                    font-size: 11px;
                }

                .Text {
                    font-size: 11px;
                    max-height: fit-content;
                }
            }
        }
    }
}
