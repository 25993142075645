.AlertBtn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 0.5rem;
    font-family: Gilroy-SemiBold, serif;
    font-size: 0.875rem;
    border: none;
    color: #df666a;
    padding: 1rem;
    cursor: pointer;
    background: #ffffff;
    width: 100%;
    &:hover {
        background: #ffdbdc;
    }
}

.Disabled {
    filter: grayscale(1);
    // pointer-events: none;
}

.AlertDrawerHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1rem;
    border-bottom: 1px solid #e5e5e5;
}

.AlertBtnContainer {
    position: relative;
    display: flex;
    width: 100%;
}

.AlertCount {
    right: -0.75rem;
    bottom: 1.2rem;
    position: absolute;
    font-family: Gilroy-SemiBold, serif;
    font-size: 0.75rem;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 1.5rem;
    width: 1.5rem;
    color: #ffffff;
    background: #df666a;
    padding: 0.25rem 0.5rem;
    border-radius: 50%;
}
