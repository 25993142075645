.Formula {
    color: black;
    margin: 5px 0;
    white-space: nowrap;

    display: flex;
    align-items: center;
    justify-content: center;

    .Character {
        height: 24px;
        width: 24px;
        border-radius: 20px;
        border: 1px solid black;
        margin: 0 5px;

        display: flex;
        align-items: center;
        justify-content: center;
    }
}
