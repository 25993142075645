.ConfirmationPopup {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1.5rem;
    max-width: 392px;

    .WarningIcon {
        width: 3.75rem;
        height: 3.75rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .ConfirmationText {
        font-weight: 500;
        text-align: center;

        .Title {
            margin-top: 1.5rem;
            font-size: 1.25rem;
        }

        .SubTitle {
            margin-top: 0.625rem;
            font-size: 0.875rem;
            max-width: 20rem;
        }
    }

    .CtaWrap {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 1.5rem;
        gap: 1.5rem;

        .CtaButton {
            box-shadow: none;
            padding: 10px 12.5px;
            border-radius: 0.25rem;
            cursor: pointer;
        }

        .ConfirmCta {
            @extend .CtaButton;
            border: none;
            background-color: var(--primary-bg-colour);
            color: #ffffff;
        }

        .CancelCta {
            @extend .CtaButton;
            border: 1px solid var(--primary-border-colour);
            background-color: #ffffff;
            color: var(--primary-text-colour);
            padding: 10px 40px;
        }
    }
}
