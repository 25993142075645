@import '../../../scss/animations';

.Heading {
    font-family: Gilroy-Medium, sans-serif;
    margin: 0;
    font-weight: 500;
    font-size: 1.5rem;
    color: #000000;
}
.SubHeading {
    font-family: Gilroy-Medium, sans-serif;
    margin: 0.625rem 0;
    font-weight: 500;
    font-size: 0.75rem;
    color: #000000;
}

.Wrapper {
    @include animate-fadein(500ms, 0, 0);
}
