.MainContainer {
    position: relative;
    width: calc(210mm + 80px);
    height: 95vh;
    .AicaPDFReportContainer {
        height: 95vh;
        width: fit-content;
        overflow-y: auto;
        padding: 40px;
    }

    .Buttons {
        position: absolute;
        width: 100%;
        bottom: 0;
        left: 0;
        height: fit-content;
        padding: 10px 20px;
        background-color: white;
        //border-top: 1px solid black;
        z-index: 100;
        border-radius: 0 0 8px 8px;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

        display: flex;
        align-items: center;
        justify-content: space-between;

        .Text {
            font-size: 14px;
            color: #c0c0c1;
        }
    }
}

.CompanyNameContainer {
    width: 100%;
    height: 100%;
    padding: 110mm 0;

    display: flex;
    align-items: center;
    justify-content: flex-end;

    .Box {
        width: 60%;
        //border: 1px solid red;
        font-size: 30px;
        //letter-spacing: 0.5px;

        .Name {
            color: var(--primary-text-colour);
            font-weight: 600;
            font-family: 'Gilroy-Bold', sans-serif;
        }
        .Subtext {
            margin-top: 5px;
            padding-top: 10px;
            border-top: 4px solid #c0c0c1;
            font-weight: 600;
        }

        .ReportGen {
            font-size: 14px;
            margin-top: 20px;
        }
    }
}

.TableOfContents {
    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: flex-start;

    .Box {
        width: 55%;
        //border: 1px solid red;

        .Title {
            font-size: 40px;
        }

        .Sections {
            margin-top: 10mm;
            .Section {
                display: flex;
                align-items: center;
                justify-content: space-between;
                border-bottom: 2px solid black;
                padding: 20px 0;
            }
        }
    }
}

.Disclaimer {
    .Title {
        font-size: 22px;
        text-align: center;
    }

    .Text {
        margin-top: 40px;
        font-size: 12px;

        display: flex;
        flex-direction: column;
        row-gap: 25px;
    }
}
