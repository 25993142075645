@import '../../../../../../../../../scss/animations';

.Action {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 10px;
    position: relative;
    margin-right: 10px;

    .ActionRow {
        display: flex;
        align-items: center;
        column-gap: 10px;
    }

    .ActionBtn {
        width: 135px;
        white-space: nowrap;

        &:active {
            transform: translateY(2px);
        }
    }

    & > img {
        cursor: pointer;
    }

    .Options {
        //@include animate-fadein(100ms);
        position: absolute;
        min-height: 40px;
        width: max-content;
        right: calc(30%);
        top: 50%;
        //transform: translateY(100%);
        background-color: white;
        z-index: 5;
        border-radius: 4px;
        border: 1px solid #d8d8d8;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        color: black;
        .Option {
            padding: 11px 12px;
            border-bottom: 1px solid #eaecf0;
            font-size: 14px;
            font-weight: 500;
            line-height: 18px;

            &:hover {
                background-color: var(--hover-bg);
                color: var(--primary-text-colour);
                cursor: pointer;
            }
        }
    }

    .OptionsLastChild {
        //top: -20
        //top: 0;
        //bottom: 100px;
        // right: 3px;
        transform: translateY(-100%);
    }
}

.ThreeDotBox {
    display: flex;
    align-items: center;
    border-radius: 4px;
    cursor: pointer;

    &:hover {
        background-color: var(--hover-bg);
    }
}

.ThreeDotActive {
    background-color: #f3f1f1;
}

.MonitoringWrap {
    display: flex;
    align-items: center;

    .MonitoringDot {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        margin-left: 10px;

        &.Green {
            background-color: #3eb702;
        }

        &.Red {
            background-color: #df666a;
        }
    }
}
