@-webkit-keyframes loading-spinner {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-moz-keyframes loading-spinner {
    0% {
        -moz-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -moz-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-o-keyframes loading-spinner {
    0% {
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-ms-keyframes loading-spinner {
    0% {
        -ms-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -ms-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes loading-spinner {
    0% {
        transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes glow {
    to {
        box-shadow: 0px 0px 0px 6px #00bd007a;
    }
}

@mixin animate-fadein($time, $x-move: 0, $y-move: 0, $opc: 0) {
    animation: fadein $time ease-in-out;

    @keyframes fadein {
        0% {
            opacity: $opc;
            transform: translate($x-move, $y-move);
        }
        85% {
            transform: translate(0, 0);
        }
        100% {
            opacity: 1;
            transform: translate(0, 0);
        }
    }
}

/* slide-up moves the element upward and fades it out */
@keyframes slide-up {
    from {
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
    to {
        transform: translate3d(0, -100%, 0);
        opacity: 0;
    }
}

/* slide-down moves the element downward and fades it out */
@keyframes slide-down {
    from {
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
    to {
        transform: translate3d(0, 100%, 0);
        opacity: 0;
    }
}

/* Mixin for slide-up animation */
@mixin slide-up-animation {
    will-change: transform, opacity; /* performance hint */
    animation: slide-up 0.2s ease-in-out forwards;
}

/* Mixin for slide-down animation */
@mixin slide-down-animation {
    will-change: transform, opacity;
    animation: slide-down 0.2s ease-in-out forwards;
}
