@import '../../CreditBureau.module.scss';
@import '../../../../../../scss/animations';

.KeyOfficialsCreditSummary {
    @include overview-card;
    border: none;
    padding: 0;
    .Content {
        @include animate-fadein(400ms, 0, 30px);
        .Header {
            display: flex;
            align-items: center;
            color: #000;
            font-family: 'Gilroy-SemiBold';
            font-size: 1.125rem;
            line-height: 1.5rem;
            letter-spacing: 0.01em;
            text-align: left;

            &::after {
                content: '';
                width: 0.05rem;
                height: 2.5rem;
                background-color: #e7e7e7;
                margin-left: 1.5rem;
            }
        }
        .OverviewContainer {
            padding: 0rem 1.5rem 1.5rem 1.5rem;
        }

        .HorizontalLine {
            margin: 0;
            border: none;
            border-top: 0.0625rem solid #e7e7e7; // Line color
            width: 100%; // Make it full width
        }

        .MainHeader {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0.69rem 1.5rem;

            .LeftHeader {
                display: flex;
                align-items: center;
                gap: 2rem;
            }

            .HeaderButtons {
                display: flex;
                align-items: center;
                gap: 1.75rem;

                div {
                    font-family: 'Gilroy-Regular';
                    font-size: 0.875rem;
                    line-height: 1.25rem;
                    text-align: center;
                    color: var(--primary-text-colour);
                    display: flex;
                    gap: 7px;
                    align-items: center;
                    cursor: pointer;

                    img {
                        margin-top: -2px;
                    }
                }
            }
        }

        .BarRow {
            display: flex;

            Button {
                margin-right: 0.5rem;
            }
        }

        .Overview {
            margin-top: 1.5rem;
            border-radius: 0.5rem;
            overflow: hidden;
            background: #fff;
            border: 1px solid #dddddd;
            .Headers {
                padding: 1rem 1rem;
                color: #535b62;
                font-size: 0.875rem;
                font-family: 'Gilroy-SemiBold';
                background-color: #f9fafc;
                display: grid;
                align-items: center;
                grid-template-columns: 1.5fr repeat(3, 1fr) 3fr 1fr;
                border-bottom: 1px solid #e7e7e7;
                height: 2.75rem;
                text-align: start;

                &:last-child {
                    text-align: end;
                    margin-right: 20px;
                }
            }

            .ChargesHeaders {
                padding: 1rem 1rem;
                color: #535b62;
                font-size: 0.875rem;
                font-family: 'Gilroy-SemiBold';
                background-color: #f9fafc;
                display: grid;
                align-items: center;
                grid-template-columns: 2fr 1fr 2fr repeat(4, 1fr);
                border-bottom: 1px solid #e7e7e7;
                height: 2.75rem;
                white-space: nowrap;
                gap: 1rem;
            }

            .Row {
                height: 72px;
                color: #000;
                font-size: 0.875rem;
                font-weight: 500;
                padding: 0.625rem 0.625rem;
                display: grid;
                align-items: center;
                grid-template-columns: 1.5fr repeat(3, 1fr) 3fr 1fr;
                border-bottom: 1px solid #e7e7e7;
                font-family: 'Gilroy-SemiBold';
                cursor: pointer;

                &:hover {
                    cursor: pointer;
                    background-color: var(--hover-bg);
                    color: var(--primary-text-colour);
                }

                &:last-child {
                    border-bottom: none;
                }

                .Name {
                    padding-left: 7px;
                }

                .Cibil {
                    display: grid;
                    align-items: center;
                    grid-template-columns: 1fr 2fr 2fr;
                    column-gap: 10px;
                    white-space: nowrap;

                    span {
                        font-family: 'Gilroy-SemiBold';
                        font-size: 0.875rem;
                        line-height: 1.044rem;
                        letter-spacing: 0.00019em;
                        text-align: left;
                        color: #bcbcbc;
                    }

                    .NA {
                        font-family: 'Gilroy-SemiBold';
                        font-size: 0.896rem;
                        line-height: 1.069rem;
                        letter-spacing: 0.00019em;
                        text-align: left;
                        color: #bcbcbc;
                        width: 3.25rem;
                    }
                }

                .Dot {
                    padding: 5px 8px;
                    border-radius: 1rem;
                    width: fit-content;
                    text-align: center;
                    font-family: 'Gilroy-Medium';
                    font-size: 12px;
                    line-height: 14.12px;
                    margin-left: 3px;
                }

                .Green {
                    background-color: #e1ffe1;
                    color: #49a71b;
                }

                .Red {
                    background-color: #fdf0ec;
                    color: #df666a;
                }

                .Fetched {
                    margin-left: -4px;

                    .FetchNow {
                        display: flex;
                        gap: 8px;

                        img {
                            margin-top: -2px;
                        }

                        .FetchNowText {
                            font-family: 'Gilroy-Medium';
                            font-size: 14px;
                            line-height: 20px;
                            text-align: center;
                        }
                    }
                }
            }

            .ChargesRows {
                color: #000;
                font-size: 0.875rem;
                font-weight: 400;
                padding: 1rem 1rem;
                display: grid;
                align-items: center;
                gap: 1rem;
                grid-template-columns: 2fr 1fr 2fr repeat(4, 1fr);
                border-bottom: 1px solid #d7dfe9;
                height: 2.75rem;

                .RowItem {
                    width: 100%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }

                &:last-child {
                    border-bottom: none;
                }
            }
        }
    }
}

.AddOfficial {
    border-top: none;
    height: 2.75rem;
    padding: 0.625rem 0.625rem;
    font-family: 'Gilroy-Medium';
    cursor: auto;
    display: flex;
    align-items: center;
    color: #000;
    font-size: 0.875rem;

    .Add {
        cursor: pointer;
        color: var(--primary-text-colour);
        font-family: 'Gilroy-Medium';
        font-size: 14px;
        line-height: 16.48px;
        text-align: left;
    }
}

.AddOfficialEmptyState {
    border-top: none;
    height: auto;
    padding: 1rem;
    font-family: 'Gilroy-Medium';
    cursor: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #000;
    font-size: 0.875rem;
    text-align: center;

    .EmptyStateImage {
        max-width: 8.697rem;
        height: 2.444rem;
    }

    .EmptyStateText {
        font-size: 0.875rem;
        font-family: 'Gilroy-Medium';
        color: #000;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        margin-bottom: 0px;
    }

    .Add {
        cursor: pointer;
        color: var(--primary-text-colour);
        font-family: 'Gilroy-Medium';
        font-size: 0.875rem;
        line-height: 1.03rem;
        text-align: center;
    }
}

.ShimmerThumbnail {
    margin-bottom: 0;
}

.GeneratingData {
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 8px;
    height: 18.75rem;
}

.Grey {
    color: #9d9d9d;
}

.FooterText {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    color: #868686;
    padding: 28px 41px;

    column-gap: 5px;

    & > img {
        margin-bottom: 2px;
    }
}
