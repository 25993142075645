// @import '../../../../../../../scss/animations';

.UnlockScanButton {
    // @include animate-fadein(00ms, 0, 0);
    background: linear-gradient(
        109.4deg,
        #f4c4d5 -23.96%,
        #c250a3 9.77%,
        #8b33cc 61.9%,
        #6f2fe8 90.16%
    );
    color: white;
    padding: 8px 14px;

    display: flex;
    align-items: center;
    margin-left: 0.5rem;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    column-gap: 5px;
    white-space: nowrap;
    cursor: pointer;
    width: fit-content;

    & > img {
        margin-bottom: 2px;
    }
}
