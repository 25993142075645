.ColorPicker {
    form {
        display: flex;
        align-items: center;
        column-gap: 5px;
        width: 100%;
    }

    input {
        width: 65%;
        height: 1.3em;
        border: 1px solid lightgray;
        border-radius: 5px;
        padding-left: 5px;
    }

    button {
        margin-top: 2px;
        padding: 0;
        cursor: pointer;
    }

    input:focus {
        outline: none;
    }
}

.ColorOptions {
    display: grid;
    grid-template-columns: auto auto auto auto auto auto auto;
    align-items: center;
    gap: 5px;
}

.Option,
.hexPreview {
    width: 16px;
    height: 16px;
    background-color: #000000;
}

.Clicked {
    // border: 1px solid transparent;
    // border-bottom: none;
}

.Bottom {
    display: flex;
    align-items: center;
    column-gap: 5px;
}
