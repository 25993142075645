.CustomTooltip {
    background: #ffffff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
    border-radius: 4px;

    .TooltipHeader {
        padding: 6px 10px;
        font-family: 'Gilroy-Regular';
        font-size: 12px;
        line-height: 14px;
        color: #6a6a6a;
        border-bottom: 1px solid #e5e5e5;
    }

    .TooltipBody {
        padding: 10px 12px;
        font-family: 'Gilroy-Medium';
        font-size: 11px;
        line-height: 12px;
        color: #000000;

        .DataRow {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: 5px 0;

            .Color {
                height: 10px;
                width: 10px;
                border-radius: 10px;
                margin-bottom: 2px;
            }

            .Label {
                margin-left: 6px;
            }

            .TotalLabel {
                color: #000;
                font-weight: 600;
                line-height: 12px;
                margin-bottom: 8px;
            }

            .Value {
                font-family: 'Gilroy-Semibold';
                margin-left: 10px;
            }

            & > div {
                display: flex;
                align-items: center;
            }
        }
    }
}
