.InviteCompanyModal {
    width: 451px;
    .Top {
        padding: 24px;
        .Title {
            font-size: 20px;
            font-weight: 700;
            line-height: 24px;
        }

        .Subtext {
            margin-top: 16px;
            font-size: 12px;
            font-weight: 500;
            line-height: 22px;
        }

        .Row {
            margin-top: 30px;
            display: flex;
            align-items: center;
        }

        .IdentifierFetched {
            margin-top: 4px;
            color: var(--primary-text-colour);
            font-size: 10px;
            font-weight: 600;
            line-height: 11.93px;

            display: flex;
            align-items: center;
            column-gap: 2px;
            & > img {
                margin-bottom: 2px;
            }
        }

        .CheckboxRow {
            margin-top: 17px;
            display: flex;
            align-items: flex-start;
            column-gap: 10px;

            font-size: 12px;
            font-weight: 500;
            line-height: 14.12px;
            letter-spacing: 0.01em;

            .Checkbox {
                accent-color: var(--primary-text-colour);
            }
        }
    }

    .Success {
        // padding-top: 180px;
        padding: 24px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .Title {
            font-size: 20px;
            font-weight: 700;
            line-height: 24px;
        }

        .Subtext {
            margin-top: 16px;
            font-size: 12px;
            font-weight: 500;
            line-height: 16px;
            letter-spacing: 0.01em;
            text-align: center;
        }

        .Buttons {
            display: flex;
            align-items: center;
            column-gap: 20px;
        }
    }

    .Bottom {
        padding: 30px 24px 24px 24px;
        background: #6021b31a;
    }
}

.Line {
    margin-top: 30px;
    height: 1px;
    background-color: #e5e5e5;
    border: none;
}
