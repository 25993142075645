@import '../../../../../../../scss/animations.scss';

.MapDataModal {
    @include animate-fadein(300ms, 0, 0, 0);
}
.ModalBody {
    padding: 0 2rem;
    width: 40rem;
    height: 26.375rem;
    .MainScreen {
        display: flex;
        flex-direction: column;
        height: 100%;
    }
    .ButtonContainer {
        padding: 1.5rem 0;
        margin-top: auto;
        display: flex;
        justify-content: flex-end;
        gap: 1rem;
        .BlueButton {
            background-color: #2f8fff;
            border: none;
            color: white;
            padding: 0.5rem 1rem;
            font-family: Gilroy-SemiBold, serif;
            border-radius: 0.25rem;
            outline: none;
            cursor: pointer;
            &:disabled {
                background-color: #bcbcbc;
                color: white;
                border: none;
            }
        }
        .purpleBtn {
            @extend .BlueButton;
            background-color: var(--primary-bg-colour);
        }
        .WhiteButton {
            background-color: white;
            border: 1px solid #2f8fff;
            color: #2f8fff;
            padding: 0.5rem 1rem;
            font-family: Gilroy-SemiBold, serif;
            border-radius: 0.25rem;
            outline: none;
            cursor: pointer;
        }
        .purpleWhiteBtn {
            @extend .WhiteButton;
            color: var(--primary-text-colour);
            border: 1px solid var(--primary-border-colour);
        }
    }
}

.Enter {
    animation: slideRight 300ms ease-in-out;
}
.Exit {
    animation: slideLeft 300ms ease-in-out;
}
.MappedData {
    display: flex;
    align-items: center;
    justify-content: space-between;
    animation: slideRight 300ms ease-in-out;
    .MappedDataBulletPoint {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: Gilroy-Bold, serif;
        height: 1.6rem;
        width: 1.6rem;
        border-radius: 50%;
        background-color: #000;
        color: white;
    }
    .MappedDataText {
        display: flex;
        align-items: center;
    }
}

@keyframes slideLeft {
    0% {
        opacity: 1;
    }

    30% {
        opacity: 0;
    }

    40% {
        opacity: 0;
        transform: translateX(-20px);
    }

    60% {
        opacity: 0;
        transform: translateX(20px);
    }

    70% {
        opacity: 1;
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes slideRight {
    0% {
        opacity: 0;
        transform: translateX(0);
    }

    40% {
        opacity: 0;
        transform: translateX(20px);
    }

    60% {
        opacity: 0;
        transform: translateX(-20px);
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }
}
