@import '../../../../scss/scrollbars';

.DebtProductOverview {
    //width: 600px;
    max-height: calc(100vh);
    overflow-y: auto;
    background-color: white;
    border-radius: 8px;

    @include no-bg-scroll;

    .Header {
        font-family: 'Gilroy-SemiBold', sans-serif;
        font-size: 18px;
        font-weight: 500;
        line-height: 24px;
        padding: 19.5px 24px;
        border-bottom: 1px solid #e8e8e7;

        position: sticky;
        top: 0;
        background-color: white;
        z-index: 5;

        display: flex;
        align-items: center;
        justify-content: space-between;

        .LenderName {
            color: #9d9d9d;
        }

        .CloseIcon {
            cursor: pointer;

            &:hover {
                transform: scale(1.1);
            }
        }
    }

    .Body {
        padding: 24px 24px 12px;

        .Table {
            margin-top: 24px;
            border: 1px solid #e8e8e7;
            border-radius: 8px;
            overflow: hidden;

            &:first-child {
                margin-top: 0;
            }

            .TableHeader {
                background-color: #f9fafc;
                border-bottom: 1px solid #eaecf0;
                padding: 13px 16px;

                display: flex;
                align-items: center;
                justify-content: space-between;

                font-size: 14px;
                font-weight: 600;
                line-height: 16.8px;
                color: #535b62;
            }

            .Row {
                display: grid;
                grid-template-columns: 1fr 1fr;
                border-bottom: 1px solid #eaecf0;

                &:last-child {
                    border-bottom: none;
                }

                .Metric {
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 20px;
                    padding: 12px 16px;
                }

                .Value {
                    padding: 12px 16px;
                    display: flex;
                    align-items: center;
                    column-gap: 10px;

                    font-size: 12px;

                    .OriginalValue {
                        color: #979797;
                    }
                }

                .ValueRows {
                    padding: 12px 16px;
                    font-size: 12px;

                    display: flex;
                    flex-direction: column;
                    row-gap: 10px;

                    .ValueRow {
                        display: flex;
                        align-items: flex-start;
                        column-gap: 10px;
                    }
                }
            }
        }
    }

    .Footer {
        padding: 0 24px 24px;

        .EditPreferences {
            background: transparent;
            border: none;
            color: var(--primary-button-bg);
            font-family: 'Gilroy-SemiBold';
            font-size: 14px;
            cursor: pointer;
        }
        .CenterEmptyContainer {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            height: 100%; /* Adjust if needed */
            text-align: center;
        }

        .EmptyImageProp {
            height: 100px;
            margin-bottom: 24px;
        }
    }
}
