@import '../../../../scss/animations';

.DataSource {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */

    text-align: right;
    letter-spacing: 0.000319489px;
    height: 46px;
    color: #363636;

    .Content {
        //@include animate-fadein(300ms, 50px, 0);
        display: flex;
        align-items: center;
    }
}

.SingleSource {
    margin-left: 10px;
    background: #e1ffe1;
    border-radius: 4px;
    padding: 12px 10px;
    color: #00aa00;

    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.01em;

    display: flex;
    align-items: center;

    & > img {
        margin-right: 5px;
        margin-bottom: 2px;
    }
}

.NotConnected {
    background: #e7e7e7;
    color: black;
}

.NotAvailable {
    color: #e49e00;
    background: #fef9ec;
}

.DataSourceShimmer {
    width: 10rem;
    margin: 0;
}
