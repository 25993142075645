.Financials {
    .Selector {
        display: flex;
        align-items: center;
        column-gap: 20px;
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 0em;

        .Option {
            padding: 11px 16px;
            border-radius: 24px;
            border: 1px solid rgba(129, 135, 140, 1);
            color: rgba(129, 135, 140, 1);
            background-color: white;
            cursor: pointer;

            &:hover {
                box-shadow: 0px 4px 10px 2px #0000001a;
                border-color: black;
                color: black;
            }
        }

        .SelectedOption {
            background: var(--primary-bg-colour);
            color: white;

            &:hover {
                box-shadow: none;
                border: 1px solid rgba(129, 135, 140, 1);
                color: white;
            }
        }
    }

    .Content {
        margin-top: 1rem;
    }
}
