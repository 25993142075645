.UploadTabs {
    // margin-top: 50px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 0 40px;

    display: flex;
    // grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
    column-gap: 20px;
    border-bottom: 1px solid #e5e5e5;

    .SingleTab {
        flex-grow: 1;
        padding-bottom: 15px;
        border-bottom: 4px solid white;

        .StepButton {
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 12px 0px;
            background-color: var(--tab-inactive-bg);
            color: var(--tab-inactive-text);
            border-radius: 8px;

            &:hover:not(.Complete) {
                background-color: var(--hover-bg);
            }

            .StepLabel {
                font-size: 14px;
            }

            .StepNumber {
                margin-right: 10px;
                color: rgb(132, 132, 132);
                background-color: #dedede;
                height: 20px;
                width: 20px;
                border-radius: 20px;

                font-size: 10px;

                display: flex;
                align-items: center;
                justify-content: center;
            }

            .SelectedStepNumber {
                color: white;
                background-color: var(--tab-active-text);
            }

            .TickImg {
                height: 18px;
                width: 18px;
                margin-right: 5px;
                margin-bottom: 1px;
            }
        }

        .SelectedStepButton {
            background-color: var(--tab-active-bg);
            color: var(--tab-active-text);
        }

        .Complete {
            background-color: #e8fff3;
            color: #000000;

            &:hover {
                background-color: var(--hover-bg);
            }
        }
    }

    .SelectedTab {
        border-bottom: 4px solid var(--tab-active-text);
        color: var(--tab-active-text);
    }
}
