.GoogleSignIn {
    cursor: pointer;
    border-radius: 0.5rem;
    border: 1px solid #e8e8e7;
    background-color: #ffffff;
    font-size: 0.875rem;
    font-family: Gilroy-Medium, sans-serif;
    font-weight: 500;
    padding: 0.5rem 0.625rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    justify-content: center;
    width: 100%;

    img {
        height: 1.5rem;
        width: 1.5rem;
    }

    &:hover {
        border: 1px solid #e8e8e7;
        background: #f7f7f7;
    }
}
