.Container {
    width: 100%;
    //height: 22.875rem;
    height: fit-content;
    background-color: #ffffff;
    padding: 24px;
    border-radius: 10px;

    .Headers {
        width: 27.375rem;
        height: 3.5rem;
        display: flex;
        flex-direction: column;
        gap: 8px;

        font-size: 18px;
        font-weight: 600;
        line-height: 21.47px;
        letter-spacing: 0.01em;
        text-align: left;

        .Row1 {
            display: flex;
            gap: 7px;
            align-items: center;

            span {
                font-family: 'Gilroy-SemiBold';
                font-size: 18px;
                line-height: 21.47px;
                text-align: left;
                margin-top: -5px;
            }
        }
    }

    .Chart {
        margin-top: 5px;
    }
}

.PDFReport {
    padding: 0;

    .Headers {
        height: fit-content;
        font-size: 11px;

        .Row2 {
            font-size: 11px;
        }
    }
}
