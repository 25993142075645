.OverviewScreenContainer {
    font-size: 1.125rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.25rem 1.5rem;
    background: white;
    border-radius: 0.5rem;
    gap: 0.5rem;
    height: 2.813rem;
    .Header {
        flex: 1;
        text-align: left;
        font-weight: 500;
        opacity: 0.7;
    }

    .LoadingContainer {
        margin-right: 0.625rem;
    }

    .MessageContainer {
        font-size: 0.875rem;
        font-weight: 500;
        line-height: 1.03rem;
        color: #9d9d9d;
    }
}
.OverviewScreenContainer.Margin {
    margin-bottom: 1.25rem;
    margin-top: 1.25rem;
}
.financialSummary {
    padding: 0;
    height: 100%;
}
