@import '../../../../scss/animations';

.Overview {
    display: flex;
    flex-direction: column;
    row-gap: 34px;
    opacity: 1;
    transition: all 500ms ease-in-out;
    animation: fade 500ms ease-in-out;
    .Banner {
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;

        display: flex;
        align-items: center;
        column-gap: 10px;
        background: rgba(254, 249, 236, 1);
        border: 1px solid rgba(246, 194, 80, 1);
        padding: 16px;
        border-radius: 4px;
    }

    .GeneratingData {
        padding: 90px 0 20px 0;
        background-color: white;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 20px;
        // box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.06);
    }
}

.AIGenerated {
    @include animate-fadein(300ms, 0, 20px);
    padding: 16px;
    display: flex;
    align-items: center;
    border: 1px solid #f6c250;
    background: #fef9ec;
    border-radius: 4px;
    color: #161513;
    font-size: 13.75px;
    line-height: 16px;

    .Img {
        margin-right: 10px;
    }
}

.Section1 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 40px;
}

@keyframes fade {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
