.OnePagerButton {
    width: 100%;

    .GenerateButton {
        background: linear-gradient(90deg, #b321ad 0%, var(--primary-text-colour) 100%);
        color: white;

        display: flex;
        align-items: center;
        padding: 13px;
        border-radius: 5px;
        column-gap: 10px;
        cursor: pointer;

        & > img {
            margin-bottom: 2px;
        }
    }

    .GeneratedButton {
        background: #f9f9f9;
        // width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        font-size: 12px;
        font-weight: 600;
        line-height: 12px;
        padding: 13px;
        border-radius: 5px;

        .Left {
            color: var(--primary-text-colour);

            display: flex;
            align-items: center;
            column-gap: 5px;
            cursor: pointer;

            &:hover {
                text-decoration: underline;
            }
        }

        .Right {
            color: #a0a0a0;
            display: flex;
            align-items: center;
            column-gap: 5px;
        }
    }

    .UnlockMsg {
        color: #a0a0a0;
        font-size: 11px;
        margin-top: 5px;
    }
}

.Disabled {
    filter: grayscale(0.5);
    opacity: 0.5;
    pointer-events: none;
}
.Locked {
    background: #bcbcbc !important;
    pointer-events: none;
}
