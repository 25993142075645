/* CheckBox.module.scss */

.CheckBox {
    display: flex;
    align-items: center;
    background-color: transparent; /* Light purple background */
    border-radius: 8px; /* Rounded corners */
    padding: 0.75rem 1rem;

    &.NeedPadding {
        padding: 0.75rem 1rem; /* Increased padding for `needPadding` */
        background-color: #f9f6ff; /* Light purple background */
    }

    input[type='checkbox'] {
        appearance: none;
        width: 1rem;
        height: 1rem;
        border: 1.4px solid #4d4d4d; /* Purple border */
        border-radius: 2px; /* Rounded checkbox corners */
        background-color: #fff; /* White background */
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        margin-right: 8px; /* Space between checkbox and label */
        transition:
            background-color 0.3s,
            border-color 0.3s;

        &:checked {
            background-color: var(--primary-bg-colour);
            border-color: var(--primary-bg-colour);

            &::after {
                // white checkmark using css
                content: '';
                width: 3px;
                height: 7px;
                margin-bottom: 20%;
                //margin-right: 25%;
                border: solid #fff;
                border-width: 0 1.5px 1.5px 0;
                transform: rotate(45deg);
            }
        }
    }

    label {
        font-size: 0.75rem;
        font-weight: 500;
        color: #707070; /* Default label color */
        cursor: pointer;

        &.primary {
            color: #8d8d8d; /* Purple for primary variant */
            font-size: 0.75rem;
            font-family: Gilroy-Medium, sans-serif;
        }

        &.secondary {
            color: var(--secondary-button-text); /* Gray for secondary variant */
        }

        &.tertiary {
            color: black; /* Black for tertiary variant*/
        }
    }
}
