$low-risk-font: #00aa00;
$low-risk-bg: #e2ffe9;
$low-risk-bar: #49a71b;

$medium-risk-font: #ff6422;
$medium-risk-bg: #fbede7;
$medium-risk-bar: #fcbf07;

$high-risk-font: #ff6231;
$high-risk-bg: #ffebeb;
$high-risk-bar: #ed6e72;

.LowRisk {
    color: $low-risk-font;
}

.MediumRisk {
    color: $medium-risk-font;
}

.HighRisk {
    color: $high-risk-font;
}

.LowRiskBadge {
    color: $low-risk-font;
    background-color: $low-risk-bg;
}

.MediumRiskBadge {
    color: $medium-risk-font;
    background-color: $medium-risk-bg;
}

.HighRiskBadge {
    color: $high-risk-font;
    background-color: $high-risk-bg;
}
