@import '../../../../../../scss/card';

.AICAGrade {
    @include new-overview-card;

    .Body {
        .Container {
            display: grid;
            grid-template-columns: 3fr 2fr;
            column-gap: 20px;
            border-radius: 4px;

            .Left {
                @include cta-card;
                border: 1px solid #e7e7e7;
                border-radius: 8px;
                padding: 20px 40px;

                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                .Subtext {
                    margin-top: 20px;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 22px;
                    letter-spacing: 0.00019968050764873624px;
                    text-align: left;
                    color: #bdbdbd;
                }
            }

            .Right {
                @include cta-card;
                border: 1px solid #e7e7e7;
                border-radius: 8px;
                display: flex;
                flex-direction: column;
                row-gap: 15px;
                padding: 16px;
                font-size: 14px;

                &:hover {
                    box-shadow: none;
                }

                .Metric {
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 14px;
                    letter-spacing: 0.00023961660917848349px;

                    display: grid;
                    grid-template-columns: 3fr 1fr;
                    align-items: center;

                    .MetricLeft {
                        display: flex;
                        align-items: center;
                        column-gap: 5px;
                    }
                    .MetricRight {
                        white-space: nowrap;
                        display: flex;
                        justify-content: flex-end;
                    }
                }
            }
        }
    }
}
.PDFReport {
    .Top {
        padding: 0.85rem;
        font-size: 16px;
        .Title {
            font-size: 16px;
        }
    }

    .Body {
        padding: 0.85rem;

        .Container {
            .Left {
                .Subtext {
                    font-size: 11px;
                }
            }

            .Right {
                padding: 0.85rem;
                font-size: 11px;
                row-gap: 10px;

                .Metric {
                    font-size: 11px;

                    .MetricLeft {
                        display: flex;
                        align-items: center;
                        column-gap: 5px;
                    }
                }
            }
        }
    }
}
