@import '../../../../scss/animations';
@import '../../../../scss/scrollbars';

.DrawerHeader {
    font-family: 'Gilroy-SemiBold', sans-serif;
    font-size: 18px;
}

.RefreshReportContainer {
    background-color: #f7f7f7;
    @include no-bg-scroll;

    .RefreshReportContent {
        padding: 1.5rem;

        .ModuleSelectionTable {
            width: 100%;
            border-collapse: collapse;
            border: 1px solid #e7e7e7;

            .ModuleSelectionTableHead {
                background-color: #f9fafc;
                text-align: left;
                color: #535b62;
                font-size: 14px;
                font-weight: 500;
                line-height: 18px;
                width: 100%;
                height: 44px;

                tr {
                    border-bottom: 1px solid #e7e7e7;
                }
            }

            .ModuleSelectionTableBody {
                @include animate-fadein(200ms, 0, 5px);
                background-color: #ffffff;
                font-family: 'Gilroy-Medium', sans-serif;
                font-size: 14px;
                line-height: 12px;
                z-index: 1;
                position: relative;
                vertical-align: middle;

                .ModuleName {
                    display: flex;
                    align-items: center;
                    gap: 0.5rem;
                }

                tr {
                    border-bottom: 1px solid #e7e7e7;

                    .LastFetched {
                        color: #9d9d9d;
                    }

                    td:last-child {
                        text-align: right;
                        padding-right: 1rem;
                    }
                }

                tr:last-child {
                    border-bottom: none;
                }
            }
        }

        .RefrestNote {
            font-family: 'Gilroy-Medium', sans-serif;
            font-size: 14px;
            color: #9d9d9d;
            margin-top: 1rem;
            padding-left: 0.25rem;
        }
    }

    .FooterContainer {
        display: flex;
        align-items: center;
        flex-direction: row-reverse;
        gap: 1.5rem;
    }
}
