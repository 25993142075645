.OtpBoxContainer {
    margin-top: 20px;
    height: 40px;
}
.Buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;

    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 20px;
}

.Title {
    display: flex;
    align-items: center;

    & > img {
        margin-right: 10px;
        margin-bottom: 2px;
        transition: all linear 50ms;
        cursor: pointer;
        &:hover {
            transform: scale(1.2);
        }
    }
}
