@import '../../scss/animations';

.Action {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 10px;
    position: relative;
    margin-right: 10px;

    .ActionRow {
        display: flex;
        align-items: center;
        column-gap: 10px;
    }

    .ActionBtn {
        width: 135px;
        white-space: nowrap;

        &:active {
            transform: translateY(2px);
        }
    }

    & > img {
        cursor: pointer;
    }

    .Options {
        @include animate-fadein(100ms);
        position: absolute;
        min-height: 40px;
        width: max-content;
        right: calc(30%);
        top: 26%;
        right: 80%;
        background-color: white;
        z-index: 5;
        border-radius: 4px;
        border: 1px solid #d8d8d8;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        color: black;
        .Option {
            padding: 11px 12px;
            border-bottom: 1px solid #eaecf0;
            font-size: 14px;
            font-weight: 500;
            line-height: 18px;

            &:hover {
                background-color: var(--hover-bg);
                cursor: pointer;
            }
        }
    }

    .OptionsLastChild {
        top: -150px;
        // right: 3px;
    }
}

.ThreeDotBox {
    display: flex;
    align-items: center;
    border-radius: 4px;
    cursor: pointer;
    &:hover {
        background: var(--hover-bg);
        box-shadow: 0px 0px 8px 0.5px #00000026;
    }

    &.Disabled {
        cursor: not-allowed;
        opacity: 0.2;
    }
}

.ThreeDotActive {
    background: var(--secondary-bg-colour);
}

.Disabled {
    cursor: not-allowed;
    opacity: 0.2;
}
.Hide {
    display: none;
}
