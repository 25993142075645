$drawer-height: 95%;
$transition-speed: 0.3s;
$drawer-bg-color: #fff;
$drawer-border-color: #e0e0e0;
$button-color: #6a4cff;
$close-button-color: #ff4c4c;
$backdrop-color: rgba(0, 0, 0, 0.5);

.drawer {
    position: fixed;
    bottom: -$drawer-height;
    left: 0;
    width: 100%;
    height: $drawer-height;
    background-color: $drawer-bg-color;
    border-top: 1px solid $drawer-border-color;
    //border-radius: 2rem 2rem 0 0;
    transition: bottom $transition-speed ease-in-out;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);

    &.show {
        bottom: 0;
        z-index: 1000 !important;
    }
}

.drawerContent {
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.closeButton {
    background-color: transparent;
    color: $close-button-color;
    font-size: 1.5rem;
    border: none;
    cursor: pointer;
    transition: color $transition-speed;

    &:hover {
        color: darken($close-button-color, 10%);
    }
}

.uploadContent {
    flex-grow: 1;
    padding-left: 1rem;
}

.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $backdrop-color;
    opacity: 0;
    transition: opacity $transition-speed ease-in-out;
    z-index: 999;
    pointer-events: none;

    &.show {
        opacity: 1;
        pointer-events: auto;
    }

    &.hide {
        opacity: 0;
        pointer-events: none;
    }
}

.drawerHeader {
    padding: 1.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.5rem;
}

.closeIcon {
    img {
        width: 20px;
        height: 20px;
        cursor: pointer;
    }
}
.DrawerText {
    display: flex;
    align-items: center;
    gap: 1rem;

    .Title {
        margin: 0;
        font-family: 'Gilroy-Bold', sans-serif;
        font-weight: 600;
    }
}
.VerticalSeparator {
    width: 1px;
    height: 2rem;
    background-color: #e0e0e0;
}
.TabSelector {
    border: 1px solid #d0d5dd;
    display: flex;
    align-items: center;
    box-shadow: 0px 1px 2px 0px #1018280d;
    border-radius: 8px;
    font-size: 0.875rem;
    overflow: hidden;
    .Tab {
        cursor: pointer;
        padding: 0.5rem 1rem;
        border-right: 1px solid #d0d5dd;
        &:last-child {
            border-right: none;
        }
    }
    .Active {
        background: var(--secondary-bg-colour);
        color: var(--primary-text-colour);
    }
}
.drawerBody {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border: 1px dashed #afafaf;
    height: 7.25rem;
    gap: 0.5rem;
    transition: background 0.3s ease-in-out;
    background: #f7f7f7;
}

.folderContainer {
    cursor: pointer;
}
.folderContainerHorizontal {
    flex-direction: row !important;
}

.folderIcon {
    transition: transform 0.3s ease-in-out;
}
.folderLid {
    transition: transform 0.3s ease-in-out;
    transform-origin: bottom;
}

.folderContainer:hover .folderLid {
    transform: rotateX(-50deg);
}

.DrawerHeader {
    //border-bottom: 1px solid $drawer-border-color;
    padding: 0 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    //margin-bottom: 1.5rem;
}
.DrawerContentSelector {
    display: flex;
    align-items: center;
    gap: 1rem;
    background: $drawer-bg-color;
}
.DrawerTabsContainer {
    display: flex;
    align-items: center;
    z-index: 2;
    background: $drawer-bg-color;
    gap: 1rem;
}
.DrawerTab {
    background: $drawer-bg-color;
    cursor: pointer;
    padding: 0.5rem 0;
    font-family: Gilroy-semibold, sans-serif;
    border-bottom: 2px solid transparent;
    color: var(--primary-text-colour);
    filter: grayscale(1);
    img {
        width: 1rem;
        height: 1rem;
    }
}
.ActiveDrawerTab {
    @extend .DrawerTab;
    filter: grayscale(0);
    border-bottom: 2px solid var(--primary-border-colour);
}
.NotActiveTab {
    display: flex;
    //align-items: center;
    gap: 0.5rem;
    padding: 0.75rem;
    border-radius: 0.5rem;
    background: $drawer-bg-color;

    &:hover {
        background: var(--hover-bg);
    }
}
.ActiveTab {
    @extend .NotActiveTab;
    color: var(--primary-text-colour);
    background: var(--secondary-bg-colour);
    &:hover {
        background: var(--hover-bg);
    }
}
.Text {
    font-family: Gilroy-semibold, sans-serif;
    font-size: 1rem;
    transition: all 0.3s ease-in-out;
}
.ActiveText {
    @extend .Text;
    color: var(--primary-text-colour);
    background: var(--secondary-bg-colour);
}

.TabSlideRightContianer {
    transform: translateX(-200%);
    opacity: 0;
    transition:
        transform 0.3s ease-in-out,
        opacity 0.3s ease-in-out;
    z-index: 0;

    &.showTabs {
        transform: translateX(0);
        opacity: 1;
        z-index: 1;
    }
}
