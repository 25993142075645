@import '../scss/scrollbars';
.AuthenticatedApp {
    margin-left: 0px;
    padding: 20px 15px;
    background-color: #f7f7f7;
    min-height: 100vh;
}

.blue {
    color: #2f8fff;
}

.cta-blue {
    color: #2f8fff;
    cursor: pointer;
}

.purple {
    margin-left: 0.5rem;
    color: #6021b3;
}

.InvestorPrivateRoutes {
    flex: 1;
    scrollbar-gutter: stable;
    width: 100vw;
    height: calc(100vh - 72px);
    padding: 34px 20px 34px 34px;
    margin-top: 72px;
    overflow-y: auto;

    @include no-bg-scroll2;

    &_CommentThreadOpen {
        width: fit-content;
    }
}

input[type='checkbox'] {
    accent-color: black;
    margin: 0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type='number'] {
    -moz-appearance: textfield;
}

a {
    color: inherit; /* Inherit color from parent or set a specific color */
    text-decoration: none; /* Remove underline */
}
