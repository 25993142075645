.Generating {
    position: absolute;
    bottom: 50px;
    left: 50%;
    transform: translateX(-50%);
    width: 460px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: white;
    padding: 12px 16px;
    border-radius: 60px;
    box-shadow: 0px 0px 20px 0px #00000033;
    z-index: 100;
    //add animation for slide up;
    animation: slideUp 300ms ease-in-out;

    .Left {
        display: flex;
        align-items: center;
        gap: 30px;
    }

    .MainText {
        font-size: 18px;
        font-weight: 600;
        line-height: 21.47px;
        letter-spacing: 0.00023961660917848349px;
        text-align: left;
        color: #555555;
        margin-left: 10px;

        .Ellipsis {
            display: inline-block;
            animation: ellipsisAnimation 1.5s infinite;
        }
    }

    .Time {
        margin-left: 20px;
        font-size: 14px;
        font-weight: 500;
        line-height: 16.48px;
        letter-spacing: 0.00023961660917848349px;
        text-align: right;
        color: #8e8e8e;
    }
}

.Ellipsis::after {
    content: '';
    display: inline-block;
    animation: ellipsisAnimation 1.5s infinite steps(4, end);
}

//keyframes for .Generating to come up from outside the screen
@keyframes slideUp {
    0% {
        transform: translateX(-50%) translateY(150%);
    }

    100% {
        transform: translateX(-50%) translateY(0);
    }
}

@keyframes ellipsisAnimation {
    0% {
        content: '';
    }

    33% {
        content: '.';
    }

    66% {
        content: '..';
    }

    100% {
        content: '...';
    }
}
