.AicaGradeBar {
    padding: 45px 0 10px 0;

    .BarContainer {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        align-items: center;
        column-gap: 10px;
        position: relative;

        .Bar {
            height: 10px;
            background-color: #ebebeb;
            border-radius: 2px;
            position: relative;

            .BarFill {
                border-radius: 2px;
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                background-color: #6021b3;
            }

            .BarLabel {
                position: absolute;
                right: 0%;
                top: 100%;
                font-size: 12px;
                font-weight: 500;
                line-height: 22px;
                color: #c5c5c5;
            }
        }

        .BarStopper {
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-70%) scaleX(0.3);
            transition: left 200ms ease-in-out;
        }

        .BarStopperNA {
            visibility: hidden;
        }

        .BarValue {
            position: absolute;
            left: 0;
            top: 50%;
            transform: translate(-50%, calc(-50% - 30px));
            // transform: translateX(-50%);
            // transform: translateY(calc(-50% - 30px));
            transition: left 200ms ease-in-out;
            font-size: 18px;
            font-weight: 500;
            text-align: left;
            height: 26px;

            // background: linear-gradient(90deg, #9342ff 13.46%, #c278e4 49.93%);
            // color: white;
            padding: 2px 10px 0 10px;

            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 4px;
        }

        .BarValueNA {
            transform: translate(-15%, calc(-50% - 30px));
            color: #bcbcbc;
            background: white;
        }
    }
}

.GradeBarPDFReport {
    .BarContainer {
        .Bar {
            .BarLabel {
                font-size: 10px;
            }
        }

        .BarValue {
            font-size: 12px;
        }

        .BarValueNA {
            transform: translate(-15%, calc(-50% - 30px));
            color: #bcbcbc;
            background: white;
        }
    }
}
