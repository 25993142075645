.MainButtonContainer {
    display: flex;
    align-items: center;
    gap: 1rem;
}
.MainButton {
    padding: 0.75rem 1rem;
    font-family: Gilroy-Bold, sans-serif;
    font-size: 0.875rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    border-radius: 0.5rem;
}
.Purple {
    background: #f9f5ff;
    color: var(--primary-text-colour);
    border: 1px solid var(--primary-border-colour);
    &:hover {
        background: var(--hover-bg);
        color: white;
        border: 1px solid var(--primary-border-colour);
        transition:
            background 0.3s ease,
            color 0.3s ease;
    }
}
