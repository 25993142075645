.Page {
    height: 100%;
    margin-bottom: 3rem;
}

.PageHeading {
    font-size: 1rem;
    font-weight: 600;
    color: #000000;
    padding-bottom: 0.25rem;
}

.InputList {
    height: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.InputContainers {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 40px;
    padding-bottom: 24px;
    border-bottom: 1px solid #f0f0f0;
}

.InputLabel {
    display: flex;
    align-items: center;
    width: 50%;
    padding: 0 0 0 1rem;
    font-size: 12px;
    font-weight: 600;
    color: #9d9d9d;
}
.SubLabel {
    font-size: 12px;
    font-weight: 400;
    color: #8d8d8d;
    width: 100%;
    margin-top: 4px;
}
