.TabContainer {
    height: 100%;
    display: flex;
    align-items: center;
    color: #8a8b9f;
    font-family: Gilroy-Bold, sans-serif;
    font-size: 1rem;
    white-space: nowrap;
    position: relative;
    cursor: pointer;

    .TabTextContainer {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        padding: 0.5rem 0.75rem;
        border-radius: 0.5rem;
        transition: background-color 200ms ease-in-out;
    }

    &:hover .TabTextContainer {
        background: var(--hover-bg);
    }

    img {
        height: 1.5rem;
        width: 1.5rem;
    }
}

.TabContainerSelected {
    color: var(--primary-text-colour) !important;

    .TabTextContainer {
        background: var(--secondary-bg-colour);
    }
}

.DisabledTab {
    cursor: not-allowed;
    filter: grayscale(1);
}

.TabSlider {
    position: absolute;
    bottom: 0;
    height: 3px;
    background-color: var(--primary-border-colour);
    transition:
        left 200ms ease-in-out,
        width 200ms ease-in-out;
}
