.dropdown {
    width: 100%;
}

.trigger {
    height: 4rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    padding: 12px;
    background-color: #fff;
    border-radius: 8px;
    gap: 12px;
    border-bottom: 1px solid transparent;
    transition:
        border-bottom-left-radius 0s ease 0.3s,
        border-bottom-right-radius 0s ease 0.3s,
        border-bottom 0s ease 0.3s,
        background-color 0.2s ease;

    &.expanded {
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
        border-bottom: 1px solid #e7e7e7;
        transition:
            border-bottom 0s ease 0s,
            background-color 0.2s ease;
    }
}

.arrow {
    transition: transform 0.3s ease;
    transform: rotate(-90deg);

    &.expanded {
        transform: rotate(0deg);
        color: #000;
    }
}

.content {
    overflow: hidden;
    max-height: 0;
    opacity: 1;
    transition:
        max-height 0.3s cubic-bezier(0.4, 0, 0.2, 1),
        padding 0.2s ease-in-out;
    padding: 0px 6px;
    background-color: #fff;

    &.expanded {
        max-height: 2000px;
        padding: 0px 12px;
        background-color: #fff;
    }
}
