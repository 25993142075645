@import '../../../../scss/breakpoints';

.Graph {
    padding: 24px;
    background-color: white;
    border-radius: 8px;
    height: 100%;
    // box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
    // overflow: auto;

    .GraphBody {
        //animation: fadein 200ms ease-in-out;
        height: 100%;
        display: flex;
        flex-flow: column;
        .GraphHeader {
            display: flex;
            align-items: center;
            justify-content: space-between;

            & > img {
                cursor: pointer;
                align-self: flex-start;
            }
        }

        .Label {
            font-size: 14px;
            line-height: 14px;
            letter-spacing: 0.01em;
            color: #606060;

            display: flex;
            align-items: center;

            img {
                margin: 2px 0px 0 8px;
            }
        }

        .InfoRow {
            display: flex;
            align-items: center;

            margin-top: 10px;

            .Value {
                font-family: 'Gilroy-Bold';
                font-size: 16px;
                line-height: 14px;
                color: #000000;
            }

            .Type {
                margin-left: 5px;
                color: #865cff;
                font-size: 14px;
            }

            .Date {
                margin: 2px 0 0 10px;
                color: #939393;
                font-size: 14px;
            }

            .Gain {
                display: flex;
                align-items: center;
                justify-content: center;

                margin-left: 10px;
                padding: 4px;
                background: #c6ffd2;
                border-radius: 2px;
                color: #0b9944;
                font-size: 12px;
            }
        }

        .SingleGraphContainer {
            position: relative;
            margin-top: 10px;
            flex: 1 1 auto;

            display: flex;
            flex-flow: column;
            justify-content: flex-end;

            .DisabledGraph {
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;

                display: flex;
                align-items: center;
                justify-content: center;
                border: 1px solid #f3f3f3;
                background-color: white;
                border-radius: 4px;

                .DisabledGraphMessage {
                    font-family: 'Gilroy-SemiBold';
                    font-size: 12px;
                    line-height: 20px;

                    max-width: 80%;
                    padding: 4px 10px;
                    opacity: 1;
                    background: rgba(253, 253, 253, 0.9);
                    border: 1px solid #e8e8e8;
                    border-radius: 10px;

                    .Link {
                        color: #4c8dff;
                        // cursor: pointer;
                    }
                }
            }

            .DisabledGraphSvg {
                opacity: 0.3;
            }
        }
        .OverflowY {
            overflow-y: auto;
        }
    }

    .Animate {
        animation: fadein 200ms ease-in-out;
    }
}

@include md {
    .Graph {
        margin-top: 0px;

        .GraphBody {
            .Label {
                font-size: 14px;
                line-height: 16px;
            }

            .InfoRow {
                height: 22px;

                .Value {
                    font-size: 18px;
                    line-height: 22px;
                }

                .Date {
                    font-size: 14px;
                }
            }

            .SingleGraphContainer {
                .DisabledGraph {
                    .DisabledGraphMessage {
                        font-size: 14px;
                        line-height: 22px;
                        padding: 12px 18px;
                    }
                }
            }
        }
    }
}

.Alert {
    flex: 1 1 auto;

    // display: grid;
    // align-items: center;
    // grid-template-columns: 1fr 10fr 2fr;

    display: flex;
    align-items: center;
    flex-flow: row;
    padding: 10px 12px;
    border-radius: 5px;
    background: #f7f7f7;

    color: #000;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;

    & > img {
        margin-right: 5px;
        margin-bottom: 2px;
    }

    .Text {
        flex: 1 1 auto;
    }

    .Date {
        width: fit-content;
        color: #828282;
        font-size: 10px;
        font-weight: 400;
        line-height: 16px;
        text-align: right;
    }
    & > img {
        align-self: center;
    }

    // &>* {
    //     border: 1px dotted black;
    // }
}

@keyframes fadein {
    0% {
        opacity: 0;
        transform: translateY(10px);
    }

    100% {
        opacity: 1;
        transform: translateY(0px);
    }
}

.tooltiptext {
    margin: 0;
    font-family: 'Gilroy-SemiBold';
    font-size: 14px;
    font-weight: 100;
    color: #000000;
    width: 290px;
    white-space: nowrap;

    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 5px;

    .bold {
        font-family: 'Gilroy-Bold';
        font-size: 14px;
        font-weight: 700;
        color: #000000;
    }
}

.indiawrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    width: 100%;
    overflow: hidden;
}

.mapDataWrapper {
    position: absolute;
    right: 2%;
    top: 2%;

    .labelWrapper {
        display: flex;
    }

    .roundedDiv {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        margin-right: 5px;
    }

    .datawrapper {
        display: flex;
        align-items: center;
        // justify-content: space-between;

        margin-bottom: 5px;

        .mapBoldDatawrapper {
            font-weight: 600;
            margin: 0 10px 0 0;
            color: #000000;
            font-size: 13px;
        }

        .mapLigitTextColor {
            margin: 0;
            font-weight: 500;
            color: #a9a9a9;
            font-size: 13px;
        }
    }
}

.LegendContainer {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .SingleLegend {
        display: flex;
        align-items: center;
        margin-left: 10px;

        .LegendIcon {
            height: 14px;
            width: 14px;
            border-radius: 14px;
            margin-right: 5px;
        }
    }
}

.FetchError {
    border: 1px solid #e5e5e5;
    display: flex;
    align-items: center;
    justify-content: center;

    .FetchErrorBtn {
        padding: 8px 12px;
        background-color: black;
        font-size: 12px;
        border: 1px solid black;
        color: white;
        border-radius: 4px;
        cursor: pointer;
    }
}

.GeneratingData {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
