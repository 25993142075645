.ChecksTableWrapper {
    span {
        font-family: Gilroy-SemiBold, serif;
        font-size: 0.75rem;
        color: #000000;
    }
}
.TableWrapper {
    max-height: 80vh;
    overflow: hidden;
    // margin-top: 1rem;
    border-radius: 8px;
    background: #fff;
    border: 1px solid #eaecf0;
    // box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1);
}
.Headers {
    padding: 16px 16px;
    color: #535b62;
    font-size: 14px;
    font-family: Gilroy-SemiBold;
    background-color: #f9fafc;
    display: grid;
    align-items: center;
    grid-template-columns: 0.75fr 4.5fr 1.5fr 0.5fr;
    border-bottom: 1px solid #d7dfe9;
    height: 2.75rem;
}
.Row {
    color: #000;
    font-size: 12px;
    font-weight: 500;
    padding: 12px 16px;
    display: grid;
    align-items: center;
    grid-template-columns: 0.75fr 4.5fr 1.5fr 0.6fr;
    border-bottom: 1px solid #d7dfe9;
    height: 2.75rem;

    &:last-child {
        border-bottom: none;
    }
    .SeverityPill {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0.25rem;
        white-space: nowrap;
        width: 60%;
        border-radius: 0.5rem;
        font-size: 12px;
    }
    .CategoryPill {
        @extend .SeverityPill;
        padding: 0.25rem 1rem;
        width: fit-content;
        background: #f5f5f5;
        font-family: Gilroy-Medium, serif;
        color: #404040;
    }
    .Description {
        font-size: 14px;
        font-family: Gilroy-Medium, serif;
    }
    .Date {
        font-size: 14px;
        color: #667085;
        font-family: Gilroy-Medium, serif;
    }
}
