@import '../../../../scss/animations';

.LoadingContainer {
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 30rem;
    max-height: 100%;
    width: 100%;
}

.TableContainer {
    height: 100%;
    width: 100%;

    .Table {
        width: 100%;
        border-collapse: separate;
        border-spacing: 0;
        overflow-y: scroll;

        tr {
            height: 50px;
        }

        tr:last-child {
            height: auto;
        }

        .TableHead {
            background: rgba(249, 250, 252, 1);
            position: sticky;
            top: 0;
            text-align: left;
            color: #535b62;
            font-size: 14px;
            font-weight: 500;
            line-height: 18px;
            width: 100%;
            height: 44px;
            z-index: 6;

            .Header {
                &:first-child {
                    padding-left: 16px;
                }

                .ActionColumn {
                    position: sticky;
                    right: 0;
                    z-index: 2;
                    border-left: 0.063rem solid #ededed;
                    text-align: center;
                }

                .HeadMain {
                    position: sticky;
                    top: 0;
                    left: 0;
                    padding: 15px 40px 15px 30px;
                    white-space: nowrap;
                    border-bottom: 1px solid #e7e7e7;
                    box-sizing: border-box;
                    z-index: 2;
                    background-color: #f9fafc;
                    overflow: hidden;
                    width: 30%;
                }

                .CollapsibleHeader {
                    padding-left: 16px;
                }

                .HeadItem {
                    background-color: #f9fafc;
                    font-size: 14px;
                    padding: 15px 20px 15px 24px;
                    text-align: left;
                    white-space: nowrap;
                    border-bottom: 1px solid #e7e7e7;
                    overflow-x: hidden;
                    z-index: 10;

                    &:nth-child(2) {
                        width: 12%;

                        &.PartnerColumn {
                            width: 30%;
                        }
                    }

                    &:nth-child(3) {
                        width: 25%;
                    }

                    &:nth-child(4) {
                        width: 12%;
                    }

                    &:last-child {
                        width: 6%;
                    }
                }

                .HideLabel {
                    font-size: 0;
                }

                .InternalComments {
                    padding: 10px;
                }
            }
        }

        .TableBody {
            @include animate-fadein(200ms, 0, 5px);
            font-family: 'Gilroy-Medium';
            font-size: 14px;
            line-height: 12px;
            vertical-align: middle;

            .Row {
                height: 72px;

                &.CollapsibleRow {
                    background-color: #f9fafc;
                    cursor: pointer;
                    @include animate-fadein(250ms);

                    &:hover {
                        background-color: var(--hover-bg);

                        .RowItem {
                            color: var(--primary-text-colour);

                            .GreyText {
                                color: var(--primary-text-colour);
                            }
                        }
                    }
                }

                &.OrgBasedRow {
                    &:hover {
                        background-color: #f7f7f7;
                        cursor: pointer;

                        .RowItem {
                            background-color: #f7f7f7 !important;
                        }
                    }

                    &_HoverActive {
                        background-color: #f7f7f7;
                        cursor: pointer;
                        .RowItem {
                            background-color: #f7f7f7 !important;
                        }

                        .CommentsBox {
                            background-color: #f2e8ff;
                            color: #6021b3;
                            box-shadow: 0px 0px 8px 0.5px #00000026;
                        }
                    }
                }

                .RowItem:first-child {
                    background-color: white;
                    // position: sticky;
                    left: 0;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                    padding-right: 0.625rem;
                    min-width: 16.563rem;
                    width: 16.563rem;
                    max-width: 16.563rem;
                    white-space: nowrap;
                    vertical-align: middle;
                    z-index: 1;
                }

                .RowItem:last-child {
                    background-color: white;
                    border-left: 0.063rem solid #ededed;
                    // position: sticky;
                    right: 0;
                    max-width: 5.5rem;
                    min-width: 5.5rem;
                    width: 5.5rem;
                    z-index: 1;
                }

                .RowItem {
                    padding: 16px 0 16px 24px;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 18px;
                    border-bottom: 1px solid #e7e7e7;
                    min-width: 9.375rem;
                    width: 20%;
                    white-space: nowrap;
                    vertical-align: middle;
                    z-index: 1;

                    &.CollapsibleRowItem {
                        padding-left: 16px;
                    }

                    &.Indent {
                        padding-left: 30px;
                    }

                    .RowLoading {
                        width: 40%;
                        margin-bottom: 0;
                    }

                    .WrapText {
                        max-width: 200px;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }

                    .Banner {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: fit-content;
                        padding: 0.25rem 0.5rem;
                        border-radius: 2.75rem;
                    }

                    .Verified {
                        @extend .Banner;
                        background: #f7f7f7;
                        color: #000000;
                    }

                    .Unverified {
                        @extend .Banner;
                        background: #f5f5f5;
                        color: #b4b4b4;
                    }

                    &:first-child {
                        width: 30%;
                        min-width: 16.563rem;
                    }

                    &:nth-child(2) {
                        width: 12%;

                        &.PartnerColumn {
                            width: 30%;
                        }
                    }

                    &:nth-child(3) {
                        width: 25%;
                    }

                    &:nth-child(4) {
                        width: 12%;
                    }

                    &:last-child {
                        width: 6%;
                        min-width: 5.5rem;
                    }
                }

                .CommentsRowItem {
                    padding: 5px;
                    height: 100%;
                    min-width: 290px;
                    max-width: 290px;
                    position: relative;
                }
            }

            &.Loading {
                opacity: 0.5;
                pointer-events: none;
            }
        }
    }

    &::-webkit-scrollbar {
        display: none;
    }

    &::-webkit-scrollbar-track {
        display: none;
    }

    &::-webkit-scrollbar-thumb {
        display: none;
    }
}

.EmptyState {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
    min-height: 27rem;
    width: 100%;
    color: #666;
    font-size: 16px;
}

.UsersCell {
    display: flex;
    align-items: center;
    max-width: 100%;
    padding-right: 2.5rem;
    cursor: pointer;

    .FirstUser {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .RemainingCount {
        color: var(--primary-text-colour);
        flex-shrink: 0;
        white-space: nowrap;
    }
}

.CommentsBox {
    position: absolute;
    left: 0;
    top: 0;
    height: calc(100% - 16px);
    width: calc(100% - 16px);
    margin: 8px;
    font-size: 12px;
    color: #979797;
    padding: 5px;
    border-radius: 8px;

    &:hover {
        background-color: #f2e8ff;
        color: #6021b3;
        box-shadow: 0px 0px 8px 0.5px #00000026;
    }

    .AddComment {
        height: 100%;
        display: flex;
        align-items: center;
    }

    .Comment {
        height: 100%;
        color: black;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        white-space: break-spaces;
        line-height: 110%;

        .Date {
            font-family: Gilroy-Light, sans-serif;
            font-size: 10px;
        }
    }
}
