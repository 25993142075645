.UserProfile {
    background-color: white;
    .Heading {
        font-size: 18px;
        font-family: 'Gilroy-SemiBold', sans-serif;
        font-weight: 600;
        line-height: 16px;
        text-align: left;
        padding: 28px 24px;
        border-bottom: 1px solid #d3d3d3;
        height: 73px;

        .Name {
            color: #a9a9a9;
        }
    }

    .Body {
        padding: 34px;
        height: calc(100vh - 73px);
        overflow: auto;

        .Container {
            border: 1px solid #eaecf0;
            border-radius: 8px;
            background-color: white;

            .Top {
                padding: 16px;
                display: flex;
                align-items: center;
                column-gap: 20px;

                .Image {
                    width: 65px;
                    aspect-ratio: 1/1;
                    border-radius: 4px;
                    overflow: hidden;
                }

                .Name {
                    font-size: 25px;
                    font-weight: 500;
                    line-height: 30px;
                    text-align: left;
                    color: #000;
                }

                .InfoPills {
                    margin-top: 8px;
                    display: flex;
                    align-items: center;
                    column-gap: 8px;

                    .Pill {
                        font-size: 14px;
                        font-weight: 500;
                        line-height: 18px;
                        text-align: center;
                        color: #999999;
                        display: flex;
                        align-items: center;
                        column-gap: 2px;
                        background: #f7f7f7;
                        border-radius: 44px;
                        padding: 5px 10px;

                        .Black {
                            color: black;
                        }
                    }
                }
            }

            .SectionHeading {
                font-size: 14px;
                font-weight: 600;
                line-height: 16.8px;
                letter-spacing: 0.00027955271070823073px;
                //text-align: center;
                color: #939393;
                background: #f7f7f7;
                //padding: 8px 16px;
                border-bottom: 1px solid #eaecf0;
                display: grid;
                grid-template-columns: 1fr 1.8fr;

                & > div {
                    padding: 10px 16px;
                }
            }

            .DataRows {
                font-size: 14px;

                .Row {
                    display: grid;
                    grid-template-columns: 1fr 1.8fr;
                    align-items: center;
                    border-bottom: 1px solid #eaecf0;
                    &:last-child {
                        border-bottom: none;
                    }

                    .Key {
                        height: 100%;
                        font-weight: 700;
                        line-height: 20px;
                        text-align: left;
                        color: black;
                        padding: 10px 16px;
                        border-right: 1px solid #eaecf0;

                        display: flex;
                        align-items: center;
                    }

                    .Value {
                        font-weight: 500;
                        line-height: 20px;
                        text-align: left;
                        padding: 10px 16px;

                        .DirectorShip {
                            border-bottom: 1px solid #eaecf0;
                            padding: 10px 16px;

                            display: flex;
                            justify-content: space-between;

                            &:last-child {
                                border-bottom: none;
                            }

                            .Dates {
                                min-width: 150px;
                                width: 150px;
                                color: #999999;
                                text-align: right;
                            }
                        }

                        .Active {
                            width: fit-content;
                            padding: 2px 8px;
                            border-radius: 16px;
                            color: #49a71b;
                            background: #e1ffe1;

                            font-size: 12px;
                            font-weight: 500;
                        }

                        .Former {
                            width: fit-content;
                            padding: 2px 8px;
                            border-radius: 16px;
                            color: #df666a;
                            background: #fdf0ec;

                            font-size: 12px;
                            font-weight: 500;
                        }
                    }

                    .Cta {
                        cursor: pointer;
                        color: var(--primary-text-colour);
                    }
                    .CtaRed {
                        cursor: pointer;
                        color: var(--risk-indicator-red);
                    }

                    .Flex {
                        display: flex;
                        align-items: center;
                        column-gap: 6px;

                        & > img {
                            margin-bottom: 2px;
                        }
                    }
                }
            }
        }
    }
}

.Nodata {
    color: #bcbcbc;
}

.PDFReport {
    border-radius: 8px;

    .Heading {
        padding: 1rem;
        font-size: 1rem;
        height: fit-content;
    }

    .Body {
        padding: 0.8rem;
        height: fit-content;

        .Container {
            .Top {
                padding: 0.8rem;

                .Name {
                    font-size: 11px;
                }

                .InfoPills {
                    .Pill {
                        font-size: 11px;
                    }
                }
            }

            .SectionHeading {
                font-size: 11px;
                padding: 0;
                & > div {
                    padding: 5px 16px;
                }
            }

            .DataRows {
                font-size: 11px;

                .Row {
                    .Key,
                    .Value {
                        padding: 2px 12px;
                        .DirectorShip {
                            padding: 0.3rem 0.85rem;
                        }
                    }
                }
            }
        }
    }
}
