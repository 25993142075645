.CreditSummaryTablePage {
    width: 100%;
}

.TableContainer {
    width: calc(100vw - 322px);
    overflow-x: scroll;
    border-radius: 8px;
}

.CreditSummaryTable {
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    text-align: center;
    border-collapse: separate; /* Don't collapse */
    border-spacing: 0;
    background-color: white;
    // border: 1px solid red;
    border-radius: 8px;

    .TableHead {
        font-size: 0.875rem;
        font-weight: 600;
        line-height: 20px;
        color: #535b62;
        background: #f9fafc;
        .TableHeadRow {
            .TableHeadRowItem {
                padding: 13px 16px;
                border-bottom: 0.5px solid #eaecf0;
                white-space: nowrap;
                background: #f9fafc;
                text-align: left;

                &:first-child {
                    background: #f9fafc;
                    position: sticky;
                    left: 0;
                    text-align: left;
                    min-width: 250px;
                    max-width: 250px;
                    white-space: wrap;
                    border-radius: 8px 0 0 0;
                }
            }

            .SecondHeader {
                position: sticky;
                left: 250px;
                min-width: 250px;
                max-width: 250px;
            }

            .ThirdHeader {
                position: sticky;
                left: 500px;
                min-width: 250px;
                max-width: 250px;
            }
        }
    }

    .TableBody {
        font-size: 0.875rem;

        .TableBodyRow {
            .TableBodyRowItem {
                padding: 13px 16px;
                color: black;
                border-bottom: 0.5px solid #eaecf0;
                background-color: white;
                text-align: left;
                white-space: nowrap;

                &:first-child {
                    font-weight: 600;
                    position: sticky;
                    left: 0;
                    background-color: white;
                    white-space: wrap;
                    // border-right: 1px solid #ededed;
                }
            }
            .SecondColumn {
                position: sticky;
                left: 250px;
                border-right: 0.5px solid #eaecf0;
            }
            .ThirdColumn {
                position: sticky;
                left: 500px;
                white-space: wrap;
            }
        }
    }
}

.NoData {
    background-color: white;
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 8px;
}
.Loading {
    margin-top: 40px;
}
