.Checkbox {
    display: flex;
    align-items: center;
    font-size: 12px;
    font-weight: 500;
    color: #000000;
    cursor: pointer;

    &.MultiSelect:hover {
        background-color: var(--hover-bg);
    }

    input {
        display: none;

        &:checked + .Checkmark {
            background-color: var(--primary-bg-colour);
        }

        &:checked + .Checkmark::after {
            display: block;
        }
    }

    .Checkmark {
        background-color: #ececec;
        width: 14px;
        height: 14px;
        border-radius: 2px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        margin-right: 10px;
        position: relative;

        &::after {
            content: '';
            position: absolute;
            display: none;
            left: 4.5px;
            top: 2px;
            width: 3px;
            height: 6px;
            border: solid white;
            border-width: 0 1px 1px 0;
            transform: rotate(45deg);
        }
    }
}
