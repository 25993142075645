.TabContainer {
    font-family: 'Gilroy-Bold', sans-serif;
    background-color: white;
    display: flex;
    border-radius: 0.5rem;
    align-items: center;
    margin-top: 34px;
    border-bottom: 1px solid #d8d8d8;
    width: 100%;

    .MenuItems {
        width: 100%;
        font-size: 0.875rem;
        list-style: none;
        margin: 0;
        padding: 9px 8px;

        display: flex;
        flex-direction: column;
        row-gap: 10px;

        .UnlockAICA {
            border-bottom: 1px solid #e8e8e7;
            padding: 16px 16px;

            .Top {
                font-size: 10px;
                font-weight: 600;
                line-height: 11.93px;
                text-align: left;
                color: var(--primary-text-colour);
                margin-bottom: 16px;
            }

            .UnlockBtn {
                padding: 12px 14px;
                border-radius: 4px;
                background: #ffffff;
                cursor: pointer;
                color: var(--primary-text-colour);
                font-family: 'Gilroy-SemiBold', sans-serif;
                font-size: 14px;
                line-height: 10px;
                display: flex;
                justify-content: center;
                align-items: center;
                column-gap: 5px;
                border: 1px solid #e7e7e7;
                &:hover {
                    background: var(--hover-bg);
                    color: var(--primary-text-colour);
                    border: 1px solid var(--primary-border-colour);
                }
            }

            .FetchingBtn {
                padding: 12px 14px;
                border-radius: 4px;
                background: #ffffff;
                cursor: progress;
                color: var(--primary-text-colour);
                font-family: 'Gilroy-SemiBold', sans-serif;
                font-size: 14px;
                line-height: 10px;
                display: flex;
                justify-content: center;
                align-items: center;
                border: 1px solid #e7e7e7;
            }
        }

        .MenuItem {
            width: 100%;
            //overflow: hidden;
            color: #c5c5c5;
            border-radius: 0.5rem;
            font-size: 14px;

            .Tab {
                padding: 16px 16px;
                border-radius: 0.5rem;
                &:hover {
                    background: var(--hover-bg);
                    color: #000000;
                    cursor: pointer;
                    transition:
                        background 0.3s ease,
                        color 0.3s ease;
                }
            }

            // a {
            //     // width: max-content;
            //     font-size: 16px;
            //     font-family: 'Gilroy-Bold', sans-serif;
            //     text-align: left;
            //     display: flex;
            //     align-items: center;
            //     text-decoration: none;
            //     transition: 0.2s ease-out;
            // }
        }
    }
}

.InvesteeTabLinks {
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 5px;

    position: relative;
    border-radius: 0.5rem;

    .TooltipInfo {
        padding: 16px;
        position: absolute;
        left: 120%;
        top: 50%;
        transform: translateY(-50%);
        background-color: white;
        height: fit-content;
        min-width: 350px;
        width: fit-content;
        border: 1px solid #f1f1f1;
        box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
        border-radius: 10px;

        font-family: 'Gilroy-Medium', sans-serif;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.00023961660917848349px;
        color: #4c4c4c;
        z-index: 100;

        .TooltipBody {
            height: 100%;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            overflow: visible;

            .TooltipPointer {
                position: absolute;
                left: -20px;
                top: 50%;
                transform: translateY(-50%);
            }
        }
    }
}

.InvesteeTabLinksActive {
    color: var(--primary-text-colour) !important;
    background: var(--secondary-bg-colour) !important;
}

.InvesteeTabLinksLocked {
    cursor: not-allowed;
}
