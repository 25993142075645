.MultiSelectDropdown {
    width: 50%;
    position: relative;

    .HiddenInput {
        bottom: 0;
        left: 0;
        position: absolute;
        width: 100%;
        pointer-events: none;
        box-sizing: border-box;
        opacity: 0;

        &:focus + .InputBox {
            border: 0.5px solid var(--primary-border-colour);
            background-color: #ffffff;
        }
    }

    &.Disabled {
        .InputBox.OptionsSelected {
            .DropdownLabel {
                color: #8d8d8d;
            }
        }
    }

    .InputBox {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1rem 0.75rem;
        font-family: 'Gilroy-Medium', sans-serif;
        font-size: 0.75rem;
        font-weight: 500;
        border-radius: 0.375rem;
        background-color: #f7f7f7;
        border: 0.5px solid #f9f9f9;
        cursor: pointer;

        .EmptyDiv {
            content: '';
            width: 15px;
            height: 15px;
        }

        .DropdownLabel {
            position: absolute;
            top: calc(50% - 7px);
            left: 0.625rem;
            font-size: 0.75rem;
            color: #8d8d8d;
            font-weight: 500;
            cursor: pointer;
            transition: 0.5s;
        }

        .DropdownIcon {
            position: absolute;
            right: 15px;
            top: 50%;
            transform: translateY(-50%);
            transition: transform 200ms ease-in-out;

            &.DropdownIconInverted {
                transform: translateY(-50%) rotate(180deg);
            }
        }

        &.OptionsSelected {
            padding: 12px 14px;
            background: #ffffff;
            border: 0.5px solid #c7c7c7;

            .DropdownLabel {
                top: 0;
                left: 0;
                color: #484848;
                font-size: 0.625rem;
                font-weight: 300;
                transform: translateY(-15px);
            }
        }

        .SelectedOptionsWrap {
            display: flex;
            flex-wrap: wrap;
            gap: 10px;
            align-items: center;
            border-radius: 4px;

            .MoreOptionText {
                font-size: 12px;
                font-weight: 500;
                color: var(--primary-text-colour);
            }

            .SelectedOption {
                background-color: var(--secondary-bg-colour);
                padding: 6px;
                border-radius: 4px;
                font-size: 12px;
                line-height: 12px;
                font-weight: 500;
                color: #000000;
                display: flex;
                justify-content: space-between;
                align-items: center;

                .SelectedOptionLabel {
                    max-width: 140px;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }

                .RemoveSelectedButton {
                    border: none;
                    background-color: transparent;
                    margin-left: 4px;
                    padding: 0;
                    cursor: pointer;

                    img {
                        width: 8px;
                        height: 8px;
                    }
                }
            }
        }
    }

    .OptionsMenu {
        max-height: 300px;
        min-width: 300px;
        width: 100%;
        position: absolute;
        top: 110%;
        background-color: #ffffff;
        overflow-y: auto;
        overflow-x: hidden;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        border-radius: 4px;
        z-index: 5;
        transition: 0.5s;
    }
}
.ErrorMsg {
    position: absolute;
    left: 0.25rem;
    color: #ce121d;
    top: calc(100% + 2px);
    font-size: 10px;
}

.HideErrorMsg {
    visibility: hidden;
}
.Error {
    border: 0.5px solid #df666a !important;
}
