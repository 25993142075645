@import '../../../../../../scss/card.scss';

.EmployeeAndWebsiteTraffic {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    column-gap: 24px;
}

.Row2 {
    display: flex;
    gap: 5px;
    font-size: 16px;
    line-height: 21.76px;
    text-align: left;
    font-weight: 500;
    color: #979797;

    .EmployeeRange,
    .Percentage {
        font-size: 18;
        line-height: 27.08px;
        text-align: left;
        color: #000000;
        margin-top: -3px;
    }
}

.Card {
    @include new-overview-card;

    .Top {
        padding: 0.85rem;

        .Title {
            font-size: 14px;
        }
    }
}
