.PreviewWrapper {
    position: absolute;
    top: 0;
    right: 0;
    width: 40%;
    height: 95%;
    background: #f7f7f7;
    z-index: 1001;
    box-shadow: 0px 0px 20px 0px #00000033;
    margin: 1rem;
    animation: appear 0.3s ease;
    overflow: auto;
    border-radius: 0.5rem;
}

.PreviewWrapperExit {
    @extend .PreviewWrapper;
    animation: disappear 0.2s ease !important;
}

.Overylay {
    position: absolute;
    top: -20rem;
    right: 0;
    width: 100%;
    height: 150%;
    background: rgba(0, 0, 0, 0);
    z-index: 1000;
}
.OverlayExit {
    display: none;
}
.PreviewHeader {
    position: sticky;
    top: 0;
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    border-bottom: 1px solid #e5e5e5;
    background: #ffffff;
    color: #000000;
    font-size: 1.125rem;
    font-weight: 500;
}
.DocName {
    width: 40ch;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.CloseIcon {
    cursor: pointer;
    color: #000000;
    height: 1rem;
    width: 1rem;
}

@keyframes appear {
    0% {
        transform: scale(0) translateX(100%);
    }
    100% {
        transform: scale(1) translateX(0);
    }
}

@keyframes disappear {
    0% {
        transform: scale(1) translateX(0);
    }
    100% {
        transform: scale(0) translateX(100%);
    }
}
