.Generating {
    position: absolute;
    cursor: pointer;
    bottom: 50px;
    left: 50%;
    transform: translateX(-50%);
    width: 460px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: white;
    padding: 12px 16px;
    border-radius: 60px;
    box-shadow: 0px 0px 20px 0px #00000033;
    z-index: 100;
    animation: slideUp 300ms ease-in-out;
    height: 52px;
    transition: height 0.3s ease-in-out;
    position: relative;

    .Left {
        display: flex;
        align-items: center;
        gap: 10px;
        position: relative;
        z-index: 2;
    }

    .Right {
        display: flex;
        align-items: center;
        position: relative;
        z-index: 2;
        margin-left: auto;

        .IconWrapper {
            transition: opacity 0.3s ease-in-out;
            margin-top: 4px;
            &.transitioning {
                opacity: 0;
            }
            svg {
                margin-top: 2px;
            }
        }
    }

    .MainText {
        font-size: 18px;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: 0.00023961660917848349px;
        text-align: left;
        color: #000000;
        margin-left: 10px;
        transition: opacity 0.3s ease-in-out;

        &.transitioning {
            opacity: 0;
        }

        .Ellipsis {
            display: inline-block;
            &.animating {
                animation: ellipsisAnimation 1.5s infinite;
            }
        }
    }

    .CtaText {
        font-size: 18px;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: 0.00023961660917848349px;
        text-align: left;
        color: #6021b3;
        transition: opacity 0.3s ease-in-out;
        margin-right: 8px;

        &.transitioning {
            opacity: 0;
        }
    }

    .Time {
        margin-left: 20px;
        font-size: 14px;
        font-weight: 500;
        line-height: 16.48px;
        letter-spacing: 0.00023961660917848349px;
        text-align: right;
        color: #8e8e8e;
        position: relative;
        z-index: 2;
    }

    &.loading {
        position: relative;
        overflow: hidden;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 0%;
            height: 100%;
            background-color: #f2e8ff;
            animation: simpleLoading 5s linear forwards;
            border-radius: inherit;
            z-index: 1;
        }
    }

    &.completed {
        background-color: #f2e8ff;
        height: 52px;
    }
}

.Ellipsis::after {
    content: '';
    display: inline-block;
    &.animating {
        animation: ellipsisAnimation 1.5s infinite steps(4, end);
    }
}

//keyframes for .Generating to come up from outside the screen
@keyframes slideUp {
    0% {
        transform: translateX(-50%) translateY(150%);
    }

    100% {
        transform: translateX(-50%) translateY(0);
    }
}

@keyframes ellipsisAnimation {
    0% {
        content: '';
    }

    33% {
        content: '.';
    }

    66% {
        content: '..';
    }

    100% {
        content: '...';
    }
}

@keyframes simpleLoading {
    0% {
        width: 0%;
    }
    100% {
        width: 100%;
    }
}
