@import '../../../../../scss/card';
@import '../../../../../scss/animations';

.Financials {
    @include data-upload-card;
    @include animate-fadein(300ms, 0, 0);
    margin-top: 20px;
    color: #000;
    font-size: 14px;
    font-weight: 500;
    line-height: 150%;

    .Title {
        color: #000;
        font-size: 16px;
        font-family: 'Gilroy-SemiBold', sans-serif;
        // font-weight: 600;
        line-height: 16px;
    }

    .Text {
        margin: 16px 0;
        line-height: 16px;
        font-size: 12px;
        font-family: 'Gilroy-Medium', sans-serif;
    }

    // .Check {
    //     margin: 16px 0;
    //     display: flex;
    //     align-items: center;
    //     color: #161513;
    //     font-size: 13.75px;
    //     font-weight: 500;
    //     line-height: 16px;

    //     & > input {
    //         margin-right: 5px;
    //         margin-bottom: 2px;
    //     }
    // }
}
