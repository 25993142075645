.ContainerWrapper {
    background-color: #ffffff;
    margin-bottom: 2.125rem;
    border-radius: 0.5rem;
    .DataAvailable {
        border-bottom: 0.063rem solid var(--border-color);
    }
    .HeaderContainer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1.5rem 0;
        margin-bottom: 1.5rem;
        .HeadingMessage {
            display: flex;
            font-family: 'Gilroy-SemiBold';
            font-size: 1.125rem;
            align-items: center;
            column-gap: 0.438rem;
            padding-left: 1.5rem;
            .InfoIcon {
                padding-top: 0.063rem;
                vertical-align: middle;
            }
        }
        .DataMessage {
            display: flex;
            color: #9d9d9d;
            font-family: 'Gilroy-medium';
            font-size: 0.875rem;
            align-items: center;
            column-gap: 0.438rem;
            padding-right: 1.5rem;
        }
    }
    .TableWrapper {
        margin: 0 1.5rem;
    }
    .TableContainer {
        border: 0.063rem solid var(--border-color);
        border-radius: 0.5rem;
        overflow-x: auto;
        width: 100%;
        box-sizing: border-box;

        .TableHead {
            background-color: #f9fafc;
            //height: 3.75rem;
            display: flex;
            border-bottom: 0.063rem solid var(--border-color);
            width: 150%;

            .TableHeadRow {
                width: 100%;
                display: flex;

                .StickyHeaders {
                    display: flex;
                    justify-content: space-between;
                    position: sticky;
                    left: 0;
                    border-right: 0.063rem solid var(--border-color);
                    background-color: #f9fafc;
                    padding: 0 1rem;
                    width: 35%;
                    box-sizing: border-box;
                    z-index: 2;
                }

                .StickyHeaders .TableHeadRowItem:nth-child(2) {
                    padding: 1rem 0;
                    text-align: center;
                }
                .TableHeadRowItem {
                    color: #545b62;
                    font-family: 'Gilroy-SemiBold';
                    font-size: 0.875rem;
                    display: inline-block;
                    padding: 1rem;
                    line-height: 1.125rem;
                    box-sizing: border-box;
                    width: 14%;
                    .InfoTip {
                        vertical-align: middle;
                        padding-left: 0.25rem;
                        z-index: 1;
                    }
                    &:nth-child(1) {
                        padding-left: 0;
                    }

                    &:nth-child(2),
                    &:nth-child(6),
                    &:nth-child(9) {
                        width: 15%;
                    }
                }
                &:last-child {
                    border-bottom: none;
                }
            }
        }

        .TableRows {
            width: 150%;
            .TableRow {
                width: 100%;
                display: flex;
                border-bottom: 0.063rem solid var(--border-color);
                align-items: center;
                .StickyRows {
                    display: flex;
                    width: 35%;
                    justify-content: space-between;
                    align-items: center;
                    position: sticky;
                    left: 0;
                    border-right: 0.063rem solid var(--border-color);
                    padding: 1rem;
                    background-color: #ffffff;
                    font-size: 0.875rem;
                    font-family: 'Gilroy-SemiBold';
                    box-sizing: border-box;
                    overflow: hidden;
                    height: 2.75rem;
                    .Name {
                        display: flex;
                        align-items: center;
                        column-gap: 0.625rem;
                        max-width: 70%;
                    }
                    .Name span:nth-child(2) {
                        text-overflow: ellipsis;
                        overflow: hidden;
                        white-space: nowrap;
                        display: inline-block;
                        width: 100%;
                    }

                    .DownloadReport {
                        display: flex;
                        align-items: center;
                        padding: 0.188rem 0.313rem;
                        width: 2.125rem;
                        justify-content: center;
                        height: 1.875rem;
                        margin-right: 0.938rem;
                        &:hover {
                            cursor: pointer;
                            background-color: #f2f2f2;
                            border-radius: 0.5rem;
                        }
                    }
                }

                .TableRowItem {
                    font-family: 'Gilroy-Medium';
                    font-size: 0.875rem;
                    width: 14%;
                    line-height: 1.125rem;
                    box-sizing: border-box;
                    padding: 1rem;
                    height: 2.75rem;

                    &:nth-child(2),
                    &:nth-child(6),
                    &:nth-child(9) {
                        width: 15%;
                    }
                }
                &:last-child {
                    border-bottom: none;
                }
            }
        }
    }

    .FooterContainer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1.688rem 1.5rem 1.5rem 1.5rem;
        .FooterMessage {
            .Note {
                font-family: 'Gilroy-SemiBold';
            }
            font-family: 'Gilroy-medium';
            font-size: 0.875rem;
            color: #9d9d9d;
        }
        .RiskDotMessage {
            display: flex;
            font-family: 'Gilroy-medium';
            column-gap: 1rem;
            font-size: 0.875rem;
            color: #9d9d9d;
            .RiskIndicators {
                display: flex;
                column-gap: 0.25rem;
                align-items: center;
            }
        }
    }
}
.RiskDot {
    display: inline-block;
    width: 0.639rem;
    height: 0.639rem;
    border-radius: 50%;
}

.PDFReport {
    .HeaderContainer {
        padding: 0.85rem;
        margin-bottom: 0;

        .HeadingMessage {
            font-size: 14px;
            padding-left: 0;
        }

        .DataMessage {
            padding: 0;
        }
    }

    .CardsContainer {
        padding: 0.85rem;
        display: grid;
        grid-template-columns: 1fr 1fr;
        row-gap: 12px;
        column-gap: 12px;
    }

    .FooterContainer {
        .FooterMessage {
            font-size: 11px;
        }

        .RiskDotMessage {
            font-size: 11px;
            white-space: nowrap;
        }
    }
}
