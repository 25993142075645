@import '../../../../scss/animations';

.CompanyLoginFormContainer {
    @include animate-fadein(300ms, 30px, 0);

    .Title {
        font-size: 24px;
        font-weight: 600;
        display: flex;
        align-items: center;
        gap: 0.75rem;

        .BorrowerPill {
            background-color: var(--tab-active-bg);
            color: var(--tab-active-text);
            padding: 5px 10px;
            border-radius: 44px;
            font-size: 14px;
            font-weight: 300;
        }
    }

    .Buttons {
        margin-top: 20px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 20px;
    }

    .Forgot {
        color: var(--primary-text-colour);
        cursor: pointer;
        margin: 40px 0 0 0;
        width: fit-content;
        background-color: white;
        border: none;
        font-size: 16px;
        padding: 0;
    }

    .Signup {
        margin-top: 40px;
        border-top: 1px solid #e5e5e5;
        padding-top: 40px;

        .Create {
            cursor: pointer;
            padding: 0;
            background-color: white;
            border: none;
            font-size: 16px;
            color: var(--primary-text-colour);
        }
    }
}
