@import '../../../../../scss/animations';
@import '../../../../../scss/card';

.RevenueVendorExpenseTable {
    margin-top: 40px;
    @include card-with-title;

    .Top {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .Right {
            font-size: 14px;
            font-weight: 500;
            line-height: 16.48px;
            color: #9d9d9d;
        }

        .Title {
            font-size: 18px;
            font-weight: 600;
            line-height: 24px;

            color: #bcbcbc;

            display: flex;
            align-items: center;
            column-gap: 10px;

            & img {
                margin-top: 2px;
            }

            .Main {
                color: black;
            }
        }
    }

    .TopNoData {
        border-bottom: none;
        border-radius: 4px;
    }

    .TableContainer {
        //@include animate-fadein(200ms, 0px, 30px);
        overflow-x: auto;
        border-left: 1px solid #eaecf0;
        border-right: 1px solid #eaecf0;
        border-radius: 8px;
        .Nodata {
            margin-top: 16px;
            text-align: center;
            padding: 30px 0;
            background-color: white;
            border-radius: 4px;
        }
    }
}

.BankTable {
    // @include animate-fadein(400ms, 0, 30px);
    //box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    //margin-top: 16px;
    border-collapse: separate;
    border-spacing: 0;
    background-color: white;
    border-radius: 8px;
    border-top: 1px solid #eaecf0;
    border-bottom: 1px solid #eaecf0;

    .TableHead {
        font-size: 14px;
        line-height: 20px;
        font-family: 'Gilroy-SemiBold', sans-serif;
        text-align: left;
        color: rgba(83, 91, 98, 1);
        background: rgba(249, 250, 252, 1);
        border-right: 1px solid #ededed;

        .TableHeadRow {
            //width: 100%;

            .TableHeadRowItem {
                width: 100%;
                padding: 0px 30px;
                white-space: nowrap;
                text-align: left;
                height: 2.75rem;
                background: #f9fafc;
                color: #535b62;
                font-size: 0.875rem;
                font-weight: 600;

                &:first-child {
                    min-width: 350px;
                    max-width: 350px;
                    position: sticky;
                    left: 0px;
                    border-right: 1px solid #ededed;
                }

                &:last-child {
                    background: #f9fafc;
                    min-width: 150px;
                    position: sticky;
                    right: 0;
                    border-left: 1px solid #ededed;
                    text-align: right;
                }
            }

            .RightAligned {
                text-align: right;
            }
        }
    }

    .TableBody {
        font-size: 0.875rem;

        .TableBodyRow {
            .TableBodyRowItem {
                padding: 0px 30px;
                color: black;
                border-bottom: 1px solid #ededed;
                text-align: left;
                height: 2.75rem;

                &:first-child {
                    position: sticky;
                    left: 0;
                    background-color: white;
                    border-right: 1px solid #ededed;
                    z-index: 10;
                }

                &:last-child {
                    position: sticky;
                    right: 0;
                    background-color: white;
                    border-left: 1px solid #ededed;
                    z-index: 10;
                    text-align: right;
                }
            }

            &:last-child {
                .TableBodyRowItem {
                    background: #f7f7f7;
                }
            }

            .RightAligned {
                text-align: right;
            }
        }

        .SummaryRow {
            .TableBodyRowItem {
                background: #f4f4f4;
                font-weight: 600;

                &:first-child {
                    background: #f4f4f4;
                }
            }
        }

        .SummaryRowFirst {
            .TableBodyRowItem {
                border-top: 1px solid rgb(98, 98, 98);
            }
        }

        .SeparatorRow {
            text-align: center;
            font-weight: 600;

            .SeparatorRowItem {
                padding: 10px 0;
                position: sticky;
                left: 0;
                border-top: 1px solid #eaeaea;
                border-bottom: 1px solid #eaeaea;
            }
        }

        .CreditSeparatorRow {
            .SeparatorRowItem {
                background-color: rgba(236, 252, 216, 1);
                color: rgba(0, 170, 0, 1);
            }
        }

        .DebitSeparatorRow {
            .SeparatorRowItem {
                background: rgba(255, 235, 235, 1);
                color: rgba(255, 98, 49, 1);
            }
        }
    }

    .SummarySeparatorRow {
        .SeparatorRowItem {
            background-color: rgb(223, 223, 223);
            color: transparent;
            padding: 20px 0;
        }
    }
}

.DataSource {
    color: black;
    font-weight: 500;

    .Content {
        display: flex;
        align-items: center;
        column-gap: 5px;

        .DataSourceBadge {
            color: #e49e00;
            background: #fef9ec;
            padding: 5px 8px;

            display: flex;
            align-items: center;
            column-gap: 10px;
            border-radius: 16px;
        }

        .Green {
            color: #49a71b;
            background: #e1ffe1;
        }
    }
}

.GstList {
    padding: 8px 10px 10px 10px;

    .GstItem {
        margin-top: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .Status {
            color: #e49e00;
            background: #fef9ec;
            padding: 5px 8px;
            border-radius: 16px;
        }

        .Connected {
            color: #49a71b;
            background: #e1ffe1;
        }
    }
}
