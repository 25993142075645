.FinancialsFromBanking {
    .Title {
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: 0.00023961660917848349px;
        text-align: left;

        display: flex;
        align-items: center;
        justify-content: space-between;

        .Active {
            color: #6021b3;
            cursor: pointer;
        }
    }

    .TableContainer {
        overflow-y: auto;

        .Table {
            box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
            margin-top: 2px;
            border-collapse: separate;
            border-spacing: 0;
            background-color: white;

            .TableHead {
                font-size: 0.875rem;
                line-height: 20px;
                font-family: 'Gilroy-SemiBold';
                text-align: right;
                color: #535b62;
                background: #f9fafc;
                border-right: 1px solid #ededed;
                height: 2.75rem;
                .TableHeadRow {
                    .TableHeadRowItem {
                        padding: 0px 30px;
                        min-width: 150px;
                        max-width: 150px;
                        // border-right: 1px solid #ededed;

                        &:first-child {
                            background-color: #f9fafc;
                            min-width: 330px;
                            position: sticky;
                            left: 0;
                            border-right: 1px solid #ededed;
                            text-align: left;
                        }
                    }
                }
            }

            .TableBody {
                font-size: 0.875rem;

                .TableBodyRow {
                    .SummaryRow {
                        background: #efefef !important;
                    }

                    .SummaryRowTopBorder {
                        border-top: 2px solid rgba(0, 0, 0, 0.432);
                    }

                    .TableBodyRowItem {
                        padding: 0px 30px;
                        min-width: 150px;
                        max-width: 150px;
                        color: black;
                        // border-right: 1px solid #ededed;
                        border-bottom: 1px solid #e4e4e4;
                        height: 2.75rem;
                        text-align: right;

                        &:first-child {
                            font-weight: 600;
                            position: sticky;
                            left: 0;
                            background-color: white;
                            border-right: 1px solid #ebebeb;
                            text-align: left;
                        }

                        .RowItem {
                            display: inline-flex;
                            align-items: center;
                            column-gap: 10px;
                            text-align: right;
                            .Label {
                                font-size: 13px;
                                height: 25px;
                                width: 25px;
                                border: 1px solid black;

                                display: flex;
                                align-items: center;
                                justify-content: center;
                                border-radius: 50px;
                            }
                        }
                    }
                }

                .TableBodySubRow {
                    .TableBodySubRowItem {
                        padding: 13px 30px 13px 30px;
                        min-width: 150px;
                        max-width: 150px;
                        color: black;
                        // border-right: 1px solid #ededed;
                        border-bottom: 1px solid #ededed;

                        &:first-child {
                            font-weight: 600;
                            position: sticky;
                            left: 0.5rem;
                            background-color: white;
                            border-right: 1px solid #ededed;
                        }
                    }
                }
            }
        }
    }
}

.FormulaContainer {
    display: flex;
    align-items: center;
    color: black;
    column-gap: 5px;
}

.Label {
    font-size: 13px;
    height: 25px;
    width: 25px;
    border: 1px solid black;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
}
