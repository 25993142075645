@import '../../../../../../../scss/card';

.AicaGradeSideDrawer {
    height: 100%;
    overflow: auto;
    // border: 1px solid red;

    .Title {
        margin: 0;
        padding: 24px;
        font-size: 24px;
        font-weight: 600;
        line-height: 28.63px;
        letter-spacing: 0.01em;

        border-bottom: 1px solid #1615131a;
        position: sticky;
        top: 0;
        background-color: white;
        z-index: 10;
    }

    .Content {
        padding: 24px;
        background-color: #f7f7f7;
        min-height: calc(100vh - 78px);

        .LegendContainer {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            column-gap: 24px;

            font-weight: 500;
            font-size: 14px;
            line-height: 18px;
            letter-spacing: 0px;
            color: #9d9d9d;

            .SingleLegend {
                display: flex;
                align-items: center;
                column-gap: 5px;

                .Icon {
                    height: 10px;
                    width: 10px;
                    border-radius: 10px;
                    margin-bottom: 2px;
                }
            }
        }

        .MetricsData {
            margin-top: 24px;

            .MainMetric {
                // border-bottom: 1px solid #d9d9d9;
                page-break-inside: avoid;
                page-break-after: auto;
                margin-bottom: 24px;
                @include new-overview-card;

                .Top {
                    display: grid;
                    grid-template-columns: 1fr 1fr;

                    .Left {
                        display: flex;
                        align-items: center;
                        column-gap: 5px;
                        // page-break-inside: avoid;
                        // page-break-after: auto;

                        font-size: 16px;
                        font-weight: 600;
                        line-height: 20px;
                        letter-spacing: 0.00023961660917848349px;

                        & > div {
                            display: flex;
                            align-items: center;
                        }
                    }
                }

                .Submetrics {
                    padding: 24px;
                    page-break-inside: avoid;
                    page-break-after: auto;

                    display: grid;
                    grid-template-columns: repeat(5, 1fr);
                    column-gap: 16px;
                    row-gap: 16px;

                    .SubmetricCard {
                        min-height: 64px;
                        padding: 8px;
                        font-size: 12px;
                        border-radius: 8px;

                        .Icon {
                            height: 10px;
                            width: 10px;
                            border-radius: 10px;
                            margin-bottom: 2px;
                        }

                        .Text {
                            margin-top: 10px;
                        }
                    }
                }

                &:last-child {
                    border-bottom: none;
                }
            }
            .NoData {
                .Top {
                    border-bottom: none;
                }
            }
        }
    }
}

.SideDrawerPDFReport {
    .Title {
        display: none;
    }

    .Content {
        padding: 0;

        .LegendContainer {
            font-size: 11px;
            margin-top: 20px;
        }

        .MetricsData {
            margin-top: 20px;

            .MainMetric {
                margin-bottom: 20px;
                @include new-overview-card;

                .Top {
                    padding: 0.85rem;

                    .Left {
                        display: flex;
                        align-items: center;
                        column-gap: 5px;

                        font-size: 14px;
                        font-weight: 600;
                        line-height: 20px;
                        letter-spacing: 0.00023961660917848349px;

                        & > div {
                            display: flex;
                            align-items: center;
                        }
                    }
                }

                .Submetrics {
                    padding: 0.85rem;
                    column-gap: 0.85rem;
                    row-gap: 0.85rem;

                    .SubmetricCard {
                        font-size: 10px;
                        border-radius: 8px;

                        .Icon {
                            height: 10px;
                            width: 10px;
                            border-radius: 10px;
                            margin-bottom: 2px;
                        }

                        .Text {
                            margin-top: 10px;
                        }
                    }
                }

                &:last-child {
                    border-bottom: none;
                }
            }

            .NoData {
                .Top {
                    border-bottom: none;
                }
            }
        }
    }
}
