@import '../../../../../scss/card';

.Box {
    @include data-upload-card;
    margin-top: 20px;
}
.Title {
    font-size: 1rem;
    font-family: Gilroy-bold, sans-serif;
    margin: 0;
}
.SubTitle {
    font-size: 0.75rem;
    font-family: Gilroy-semibold, sans-serif;
    color: #696969;
    margin-top: 0.5rem;
}
.Separator {
    border: 0.5px solid #d0d5dd;
    margin: 1.5rem 0;
}
