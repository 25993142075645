.TableWrapper {
    box-shadow: 0px 4px 10px 0px #00000026;
    border-radius: 8px;
    display: flex;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    .ScrollableColumns {
        overflow-x: auto;
        overflow-y: hidden;
        height: fit-content !important;
        .TableHeader {
            @extend .Header;
            flex-wrap: nowrap;
            display: flex;
            align-items: center;
            .TableHeaderCell {
                background-color: #fcfcfd;
                min-width: 10rem;
                max-width: 10rem;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                padding: 10px 20px;
                border-right: 1px solid #eaecf0;
                border-bottom: 1px solid #eaecf0;
            }
        }
        .TableBody {
            .TableRow {
                display: flex;
                .TableCell {
                    min-height: max-content;
                    min-width: 10rem;
                    max-width: 10rem;
                    white-space: nowrap;
                    background-color: #f7f7f7;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    padding: 10px 20px;
                    border-right: 1px solid #eaecf0;
                    border-bottom: 1px solid #eaecf0;
                }
            }
        }
    }
    .FixedColumns {
        .TableHeader {
            @extend .Header;
            display: grid;
            align-items: center;
            grid-template-columns: repeat(4, 1fr);
            gap: 0;
            max-width: 40rem;
            .TableHeaderCell {
                min-width: 10rem;
                max-width: 10rem;
                white-space: nowrap;
                text-overflow: ellipsis;
                background-color: #fcfcfd;
                overflow: hidden;
                border-right: 1px solid #eaecf0;
                border-bottom: 1px solid #eaecf0;
                padding: 10px 20px;
            }
        }
        .TableBody {
            .TableRow {
                display: grid;
                grid-template-columns: repeat(4, 1fr);
                align-items: center;
                max-width: 40rem;
                .TableCell {
                    position: relative;
                    min-width: 10rem;
                    max-width: 10rem;
                    white-space: nowrap;
                    padding: 10px 20px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    border-right: 1px solid #eaecf0;
                    border-bottom: 1px solid #eaecf0;
                }
            }
        }
    }
}

.Header {
    color: #535b62;
    font-size: 0.75rem;
    font-family: Gilroy-semibold, serif;
}
.MetricBanner {
    background-color: #fff7e4;
    padding: 0.5rem;
    border-radius: 8px;
    display: flex;
    font-family: Gilroy-SemiBold, serif;
    align-items: center;
    gap: 0.5rem;
    margin-left: 1rem;
    width: 27.75rem;
}
.LearnMore {
    color: var(--primary-text-colour);
    font-family: Gilroy-SemiBold, serif;
    cursor: pointer;
}
