.Header {
    padding: 1.25rem 1rem;
    border-bottom: 1px solid #d0d5dd;
    display: flex;
    align-items: center;
    justify-content: space-between;

    h3 {
        margin: 0 0 0 0.5rem;
        font-size: 1.125rem;
        font-family: 'Gilroy-SemiBold', sans-serif;
    }

    h4 {
        margin: 0;
        font-size: 1.125rem;
        color: #81878c;
        font-family: 'Gilroy-SemiBold', sans-serif;
    }

    h6 {
        margin: 0;
        font-size: 0.75rem;
        color: #b3b3b3;
        font-family: 'Gilroy-SemiBold', sans-serif;
    }

    .RightSection {
        display: flex;
        align-items: center;
        gap: 0.5rem;
    }
}

.Separator {
    margin: 0 0.5rem;
    height: 1rem;
    width: 1px;
    background-color: #d0d5dd;
}

.title {
    display: flex;
    align-items: center;
    gap: 0.25rem;
    font-size: 0.875rem;
    font-family: Gilroy-Bold, sans-serif;
}

.OverviewBodyWrapper {
    position: relative;
    border-radius: 0.5rem;
    background-color: #fff;
    transition: all 2s ease-in-out;
}

.BodyExpanded {
    max-height: 100000rem !important; // Set the expanded height (a reasonable value)
    height: auto !important;
}

.Body {
    padding: 1.25rem 1.25rem 0 1.25rem;
    overflow: hidden;
    transition: all 1s ease-in-out;
    height: 12.5rem;
    max-height: 12.5rem;

    .UpperSection {
        padding-bottom: 1.25rem;
        display: flex;
        align-items: flex-start;
        gap: 2rem;
        border-bottom: 1px solid #d0d5dd;

        .Left {
            width: 100%;
        }

        .Right {
            border-radius: 0.5rem;
            padding: 1.25rem;
            background: #ffeeee;
        }
    }

    .LowerSection {
        padding-top: 1.25rem;
    }

    .InfoSection {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 0.5rem;

        .ReviewSection {
            display: flex;
            align-items: center;
            gap: 0.5rem;
            font-size: 0.875rem;
            color: #4d4d4d;

            .ReviewButtonWrap {
                position: relative;
                display: flex;
                align-items: center;
                gap: 0.5rem;

                .ReviewButton {
                    border: none;
                    border-radius: 8px;
                    background: transparent;
                    cursor: pointer;
                    padding: 10px 8px 6px;

                    &.Dislike {
                        transform: rotate(180deg);
                    }

                    &:hover {
                        background: var(--hover-bg);
                    }

                    &.Active {
                        background: #e7e7e7;
                    }
                }
            }
        }

        .OverviewNote {
            font-size: 0.75rem;
            font-weight: 700;
            color: #9d9d9d;
        }
    }
}

.Observations {
    padding: 0 0 0 1.25rem;
    font-family: Gilroy-Medium, sans-serif;
    font-size: 0.875rem;

    line-height: 1.25rem;

    li {
        color: #676767;
    }
}

.Risks {
    font-family: Gilroy-Medium, sans-serif;
    padding: 0 1.25rem;
    font-size: 0.875rem;
    line-height: 1.25rem;

    li {
        color: #000000cc;
    }
}

.Recommendations {
    font-family: Gilroy-Medium, sans-serif;
    padding: 0 1.25rem;
    font-size: 0.875rem;
    line-height: 1.25rem;

    li {
        color: #676767;
    }
}

.FooterAbs {
    position: absolute;
    bottom: 0;
    height: 11rem;
    width: 100%;
    padding: 1.25rem;
    display: flex;
    justify-content: space-between;
    background: linear-gradient(175.98deg, rgba(250, 246, 255, 0) 3.28%, #f7f7f7 62.84%);
    align-items: flex-end;
    button {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.5rem;
        width: 50%;
        font-family: Gilroy-Medium, sans-serif;
        font-size: 0.875rem;
        line-height: 1.25rem;
        color: var(--primary-text-colour);
        background: #ffffff;
        border: 1px solid #d0d5dd;
        cursor: pointer;
        padding: 0.25rem;
        border-radius: 4.25rem;
    }
    .arrow {
        transition: transform 0.5s;
    }
}

.Footer {
    padding: 1.25rem;
    button {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.5rem;
        width: 50%;
        font-family: Gilroy-Medium, sans-serif;
        font-size: 0.875rem;
        line-height: 1.25rem;
        color: var(--primary-text-colour);
        background: #ffffff;
        border: 1px solid #d0d5dd;
        cursor: pointer;
        padding: 0.25rem;
        border-radius: 4.25rem;
    }
    .arrow {
        transition: transform 0.5s;
    }
}

.isPDFReport {
    .Header {
        //flex-direction: column;
        //align-items: flex-start;
        padding: 0.8rem;

        h3,
        h4,
        h6 {
            font-size: 14px;
        }

        .RightSection {
            h6 {
                margin-top: 3px;
            }
            font-size: 11px;
        }
    }

    .Body {
        padding: 0.8rem;
        height: auto;
        max-height: none;

        .UpperSection {
            flex-direction: column;
            gap: 0rem;

            .Left {
            }

            .Right {
                padding: 0.6rem;
                .Risks {
                    margin-bottom: 0;
                }
            }
        }

        .InfoSection {
            flex-direction: column;
            align-items: flex-start;
            gap: 1rem;
            .OverviewNote {
                font-size: 11px;
            }
        }
    }

    .FooterAbs,
    .Footer {
        flex-direction: column;
        align-items: center;

        button {
            width: 100%;
        }
    }
}
