.AddToWatchlist {
    width: 440px;
    padding: 34px;

    .Title {
        font-size: 24px;
        font-weight: 600;
    }

    .IdentifierFound {
        margin-top: 4px;
        color: var(--primary-text-colour);
        font-size: 10px;
        font-weight: 600;
        line-height: 11.93px;

        display: flex;
        align-items: center;
        column-gap: 2px;
        & > img {
            margin-bottom: 2px;
        }
    }
    .PANoption {
        background: white;
        color: var(--primary-text-colour);
        margin-top: 14px;
        font: 'Gilroy-Medium';
        font-size: 14px;
        border: none;
        font-weight: 400;
        cursor: pointer;
    }
}
