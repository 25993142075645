@import '../../../scss/animations';
@import '../../../scss/breakpoints';

.PartnerSignup {
    @include animate-fadein(300ms, 0, 0);
    width: 100vw;
    height: 100vh;
    display: grid;
    grid-template-columns: 1fr 1.3fr;
}
.Lefttext {
    font-size: 19px;
    line-height: 33px;
    padding: 0 2.5rem;
    max-width: 30ch;
    word-wrap: break-word;
}

.Left {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #f9f9f9;
    text-align: center;

    .Logo {
        margin-bottom: 40px;
        height: 75px;
    }
}

.Right {
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
}
.Login {
    cursor: pointer;
    padding: 0;
    background-color: white;
    font-family: 'Gilroy-SemiBold', sans-serif;
    border: none;
    font-size: 14px;
    color: var(--primary-text-colour);
}

.FormContainer {
    width: 350px;
}

.LoginBlock {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px;

    .Forgot {
        color: var(--primary-text-colour);
        cursor: pointer;
        padding: 40px 0;
        border-bottom: 1px solid #e5e5e5;
    }

    .Signup {
        margin-top: 40px;
    }
}
.Row {
    margin-top: 35px;
    display: flex;
    align-items: center;
}
.Separator {
    margin: 2rem -2.1rem;
    height: 1px;
    width: 120%;
    background-color: #e5e5e5;
}

// CSS FOR RESPONSIVE VIEW//@at-root

@include lg {
    .Left {
        padding: 0 10px;

        .Logo {
            height: auto;
            width: 70%;
        }
    }
}

@include sm {
    .InvestorLogin {
        grid-template-rows: 1fr 3fr;
        grid-template-columns: none;
    }

    .Left {
        padding: 20px;
        font-size: 12px;
        .Logo {
            width: 50%;
            margin-bottom: 20px;
        }
    }

    .Right {
        .FormContainer {
            padding: 0 40px;
            width: 100%;
        }
    }
}
