@import '../../../scss/animations';
@import '../../../scss/breakpoints';

.CompanyLogin {
    @include animate-fadein(300ms, 0, 0);
    width: 100vw;
    height: 100vh;
    display: grid;
    grid-template-columns: 1fr 1.2fr;
}

.Left {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #f9f9f9;
    text-align: center;
    //background-image: url("https://fl-fe-assets.s3.ap-south-1.amazonaws.com/png/company-side-bg.png");
    background-size: auto;
    background-repeat: no-repeat;
    background-position: center;

    .Container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background: radial-gradient(
            100% 359.18% at 0% 0%,
            rgba(255, 255, 255, 0.8) 0%,
            rgba(255, 255, 255, 0.5) 100%
        );
        border-radius: 20px;
        padding: 50px 60px;
    }

    .Logo {
        //margin-bottom: 40px;
        height: 55px;
    }
}

.Right {
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;

    .FormContainer {
        width: 370px;
    }
}

@include lg {
    .Left {
        padding: 0 10px;

        .Logo {
            height: auto;
            width: 70%;
        }
    }
}

@include sm {
    .CompanyLogin {
        grid-template-rows: 1fr 3fr;
        grid-template-columns: none;
    }

    .Left {
        padding: 20px;
        font-size: 12px;
        .Logo {
            width: 50%;
            margin-bottom: 20px;
        }
    }

    .Right {
        .FormContainer {
            padding: 0 40px;
            width: 100%;
        }
    }
}
