@import '../../../../../../scss/animations';
@import '../../../../../../scss/card';

.Details {
    overflow: hidden;
    //@include animate-fadein(300ms, 0, 0, 0);

    .Top {
        border-radius: 10px;
        background-color: white;
        height: fit-content;
        width: 100%;
        position: relative;
        padding: 34px;
        transition: background-image 2s ease-in-out;
        display: inline-block;
        margin-bottom: 40px;

        .CompanyLogo {
            // height: 35%;
            width: 8%;
            aspect-ratio: 1 / 1;

            background-color: white;
            border-radius: 8px;
            transition: box-shadow 200ms ease-in-out;

            .PlaceholderImg {
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 8px;
                background-color: #f3ecfd;
                color: var(--primary-text-colour);
                font-size: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        .Title {
            margin-top: 32px;
            font-size: 24px;
            font-weight: 700;
            line-height: 29.02px;
            text-align: left;

            color: white;
        }

        .Badges {
            margin-top: 20px;
            display: flex;
            align-items: center;
            // justify-content: center;

            column-gap: 10px;
            color: white;

            .Pill {
                background: #ffffff1f;
                padding: 5px 10px;
                font-size: 14px;
                font-weight: 500;
                line-height: 18px;
                text-align: center;
                border-radius: 80px;
                cursor: default;
                transition: box-shadow 200ms ease-in-out;

                display: flex;
                align-items: center;
                column-gap: 10px;

                &.PillWithHover:hover {
                    box-shadow: 0px 0px 20px 0px #ffffffbf;
                }
            }
        }

        .poweredByAica {
            position: absolute;
            right: 20px;
            top: 20px;
            animation: slideInRight 1s ease-in-out;
        }
    }

    .BottomWrap {
        .Header {
            @include overview-card;
            border-radius: 8px 8px 0 0;
            border-bottom: 1px solid #e8e8e7;
        }

        .Bottom {
            border-radius: 0 0 10px 10px;
            background-color: white;
            padding: 24px;

            .Title {
                font-size: 14px;
                font-weight: 500;
                line-height: 16.48px;
                letter-spacing: 0.00023961660917848349px;
                text-align: left;
                color: #999999;
                margin-top: 34px;

                &:first-child {
                    margin-top: 0;
                }
            }

            .Text {
                margin-top: 10px;
                font-size: 14px;
                font-weight: 500;
                line-height: 22px;
                letter-spacing: 0.00019968050764873624px;
                text-align: left;
            }

            .Badges {
                margin-top: 10px;

                display: flex;
                align-items: center;
                column-gap: 10px;

                .Pill {
                    border-radius: 44px;
                    padding: 5px 10px;
                    border: 1px solid #e8e8e7;

                    font-size: 14px;
                    font-weight: 500;
                    line-height: 22px;
                    letter-spacing: 0.00019968050764873624px;
                    text-align: center;
                    background: #ebebeb;
                    border: none;
                    transition: border 200ms ease-in-out;
                    cursor: default;
                    .Label {
                        color: #9d9d9d;
                    }
                }
            }
        }
    }
}
.Bold {
    color: rgb(85, 85, 85);
    font-weight: 600;
}
.ExtraGstin {
    margin-left: 0.313rem;
    color: var(--primary-text-colour);
    cursor: pointer;
}
.GstTooltip {
    display: flex;
    justify-content: flex-start;
}

.AddSocials {
    display: flex;
    align-items: center;
    cursor: pointer;
    column-gap: 5px;
}

@keyframes slideInRight {
    0% {
        transform: translateX(300px);
    }
    100% {
        transform: translateX(0);
    }
}

.PDFReport {
    margin-top: 14px;
    .Top {
        background: linear-gradient(127deg, rgba(0, 0, 0, 1) 0%, rgba(96, 33, 179, 1) 100%);
        padding: 0.85rem;
        margin-bottom: 24px;

        .Title {
            margin-top: 18px;
            font-size: 22px;
        }

        .Badges {
            .Pill {
                font-size: 11px;

                img {
                    display: none;
                }
            }
        }
    }

    .BottomWrap {
        .Header {
            page-break-inside: avoid;
            padding: 0.85rem;

            .Title {
                font-size: 16px;
                line-height: 1rem;
                font-family: 'Gilroy-SemiBold', sans-serif;
                font-weight: 600;
            }
        }

        .Bottom {
            padding: 0.85rem;

            .Title {
                margin-top: 18px;
                font-size: 12px;
                line-height: 1rem;
                page-break-inside: avoid;
            }

            .Text {
                page-break-inside: avoid;
                font-size: 11px;
                line-height: 130%;
            }

            .Badges {
                page-break-inside: avoid;
                flex-direction: column;
                align-items: flex-start;
                row-gap: 12px;
                .Pill {
                    font-size: 11px;
                    text-align: left;
                    border-radius: 18px;
                }
            }
        }
    }
}
