.InvestmentReport {
    width: 100%;

    .FileRow {
        background: #f9f9f9;
        // width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        font-size: 12px;
        font-weight: 600;
        line-height: 12px;
        padding: 13px;
        border-radius: 5px;

        .Left {
            color: var(--primary-text-colour);

            display: flex;
            align-items: center;
            column-gap: 5px;
            cursor: pointer;

            &:hover {
                text-decoration: underline;
            }

            & > img {
                margin-bottom: 2px;
            }
        }

        .Right {
            color: #a0a0a0;
            display: flex;
            align-items: center;
            column-gap: 5px;
        }
    }
}

.GenerateButton {
    background: linear-gradient(90deg, #b321ad 0%, #6021b3 100%);
    color: white;

    display: flex;
    align-items: center;
    padding: 13px;
    border-radius: 5px;
    column-gap: 10px;
    cursor: pointer;

    & > img {
        margin-bottom: 2px;
    }
}

.Locked {
    background: #bcbcbc !important;
    pointer-events: none;
}
