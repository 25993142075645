.PendingCTA {
    height: 40px;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 0.75rem 1rem;
    border-radius: 4px;
    gap: 0.5rem;
    background: #ffffff;
    white-space: nowrap;
    border: 1px solid #c7c7c7;
    span {
        color: #df666a;
        font-size: 16px;
        font-family: 'Gilroy-SemiBold', sans-serif;
    }
}

.PendingDone {
    @extend .PendingCTA;
    background: #e6f7dd !important;
    span {
        color: #49a71b !important;
    }
}
