@import '../../../../../../../../scss/animations';

.Row {
    background-color: white;
    cursor: pointer;
    position: relative;
    height: 72px;

    &:hover {
        background-color: var(--hover-bg);

        .RowItem {
            background-color: var(--hover-bg);
        }
    }

    .HeadersHeadRowItem {
        position: sticky;
        z-index: 99;
        background-color: white;
        border-right: 1px solid #e7e7e7;
        left: 6.25rem;
        background-color: white;
        &:hover {
            background-color: var(--hover-bg);
        }
    }

    .HeadersDrawdownRowItem {
        position: sticky;
        left: 0;
        z-index: 99;
        width: 6.25rem;
        min-width: 6.25rem;
        max-width: 6.25rem;
        background-color: white;

        &:hover {
            background-color: var(--hover-bg);
        }
    }

    .LenderHeadersHeadRowItem {
        position: sticky;
        z-index: 99;
        background-color: white;
        border-right: 1px solid #e7e7e7;
        left: 0;
        background-color: white;
        &:hover {
            background-color: var(--hover-bg);
        }
    }

    .CollapsibleHeaderHeadRowItem {
        position: sticky;
        z-index: 99;
        background-color: #f9fafc;
        border-right: 1px solid #e7e7e7;
        left: 6.25rem;

        &:hover {
            background-color: var(--hover-bg);
        }
    }

    .CollapsibleHeaderDrawdownRowItem {
        position: sticky;
        left: 0;
        z-index: 99;
        background-color: #f9fafc;
        width: 6.25rem;
        min-width: 6.25rem;
        max-width: 6.25rem;

        &:hover {
            background-color: var(--hover-bg);
        }
    }

    .StickyActions {
        position: sticky;
        right: 0;
        background-color: white;
        border-left: 1px solid #e7e7e7;
        z-index: 99;
    }

    .More {
        white-space: nowrap;
        font-size: 12px;
        font-weight: 500;
        line-height: 12px;
        text-align: left;
        color: #6021b3;
    }

    &.CollapsibleRow {
        background-color: #f9fafc;
        cursor: pointer;
        @include animate-fadein(250ms);

        &:hover {
            background-color: var(--hover-bg);

            .RowItem {
                color: var(--primary-text-colour);
                .GreyText {
                    color: var(--primary-text-colour);
                }
            }
        }
    }
    .CommentCell {
        width: 100%;
        height: inherit;
        border-bottom: 1px solid #f0f0f0;
    }
    .CommentDiv {
        width: 100%;
        height: 100%;
        width: 22rem;
    }
    .lenderRow {
        padding: 16px 24px 16px 24px !important;
    }

    .RowItem {
        padding: 1rem 0 1rem 24px;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px;
        border-bottom: 1px solid #f0f0f0;
        white-space: nowrap;
        vertical-align: middle;
        width: 8rem;

        &:first-child {
            padding-left: 30px;
        }

        &.CollapsibleRowItem {
            padding-left: 0;
        }

        &.Indent {
            padding-left: 30px;
        }

        .RowLoading {
            width: 40%;
            margin-bottom: 0;
        }

        .WrapText {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        .GreyText {
            color: #979797;
        }

        .GrayBackground {
            // background-color: #f9fafc;
            background-color: black;
        }

        .DropdownButton {
            padding: 0;
            margin: 0;
            background: none;
            border: none;
            cursor: pointer;
            outline: none;
            transition: 0.25s;
            margin-left: 16px;
            &.Open {
                transform: rotate(-180deg);
            }
        }

        .DealTermsContainer {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0.5rem 0.5rem 0.5rem 0.3rem;
            margin-right: 0.8rem;

            border-radius: 8px;
            background: transparent;

            &:hover {
                background-color: #f9f5ff;
                box-shadow: 0px 0px 8px 0.5px #00000026;
            }
            .DealTermsIcon {
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .DealTermsText {
                font-size: 14px;
                font-weight: 500;
                line-height: 18px;
                color: #6021b3;
                padding-left: 10px;
            }
        }

        .HideRow {
            display: none;
        }
    }
}
.RowHoveredCommentActive {
    background-color: var(--primary-purple-highlight);
    .RowItem {
        background-color: var(--primary-purple-highlight);
    }
}
