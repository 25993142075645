.SendPasswordLink {
    .Title {
        margin-top: 24px;
        color: #000;
        font-size: 24px;
        font-weight: 600;
        letter-spacing: 0.2px;
    }

    .Back {
        cursor: pointer;
        transition: all 100ms ease-in-out;
        &:hover {
            transform: scale(1.2);
        }
    }
}
