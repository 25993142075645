@import '../../../../../../scss/card';

.Container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 24px;

    .Card {
        @include new-overview-card;

        .Body {
            .Fields {
                font-size: 14px;
                font-weight: 500;
                line-height: 20px;
                text-align: left;

                border: 1px solid #eaecf0;
                border-radius: 8px;

                .Field {
                    border-bottom: 1px solid #eaecf0;
                    padding: 12px 16px;

                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    column-gap: 14px;
                    &:last-child {
                        border-bottom: none;
                    }
                }
            }
        }
    }
}
