.HeadingContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.TableContainer {
    height: auto;
    overflow: hidden;
}

.divider {
    width: 1px;
    height: 2rem;
    background-color: #d2d2d2;
}

.EditClassification {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    cursor: pointer;
    color: var(--primary-text-colour);
    font-size: 14px;

    .EditIcon {
        width: 1.5rem;
        height: 1.5rem;
    }
}

.Breadcrumb {
    margin: 1rem 0;
    display: flex;
    align-items: center;
    column-gap: 8px;

    .Blue {
        color: var(--primary-text-colour);
        font-size: 16px;
        font-weight: 700;
        cursor: pointer;
    }

    & > img {
        margin-bottom: 3px;
    }
}

.SaveClassification {
    padding: 0.25rem 0.5rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: white;
    cursor: pointer;
    background: var(--primary-text-colour);
    font-size: 14px;
    outline: none;
    border: none;
    border-radius: 5px;
}

.CancelClassification {
    display: flex;
    border-radius: 5px;
    cursor: pointer;
    padding: 0.25rem 0.5rem;
    align-items: center;
    gap: 0.5rem;
    outline: none;
    border: 1px solid var(--primary-border-colour);
    font-size: 14px;
    background: white;
    color: var(--primary-text-colour);
    font-size: 14px;
}

.AllFinancialMetrics {
    display: flex;
    align-items: center;
    column-gap: 5px;
    font-size: 14px;
    cursor: pointer;
    background: rgba(163, 129, 235, 0.1490196078);
    color: var(--primary-text-colour);
    padding: 6px 10px 6px 12px;
    border-radius: 64px;

    background: linear-gradient(
        to right,
        #7a53cd52 20%,
        #00affa63 30%,
        #0190cd50 70%,
        #754ada6e 80%
    );
    background-size: 400% 400%;
    animation: gradient 5s ease infinite;

    & > img {
        margin-bottom: 2px;
    }

    &:hover {
        font-weight: 600;
    }
}

.UploadData {
    background: rgba(163, 129, 235, 0.1490196078);
    color: var(--primary-text-colour);
    padding: 6px 10px 6px 12px;
    border-radius: 64px;
    font-size: 14px;

    display: flex;
    align-items: center;
    column-gap: 5px;
    cursor: pointer;
}
@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}
