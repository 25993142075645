.offersPage {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 72px;

    .offersPageContainer {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        width: 95%;
        height: 100%;
        margin: 34px 34px 0px 34px;
        background-color: #ffffff;
        border-radius: 8px;

        .pageHeader {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 24px 32px;

            .heading {
                font-size: 24px;
                font-weight: 600;
                text-align: left;
                font-family: Gilroy-Medium, sans-serif;
            }
        }

        .offersTableContainer {
            background-color: #ffffff;
            border-radius: 16px;
            height: 70vh;
        }
    }
}
