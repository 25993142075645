@import '../../../scss/_scrollbars';

.UploadJourney {
    height: 100%;
    position: relative;

    .Top {
        //height calculation: height of footer is subtracted
        height: 100%;
        margin-top: 19px;
        .UploadContainer {
            //height calculation: recur tabs height subtracted
            height: calc(100% - 63px);
            overflow: hidden;
            // scrollbar-gutter: stable both-edges;
            // @include small-grey-scroll;
            @include no-scroll;

            .Component {
                width: 100%;
                height: 100%;
                overflow-y: auto;
                padding-bottom: 17.5rem;
                @include no-scroll;
            }
            .InvestorComponent {
                padding-bottom: 2rem;
            }
        }
    }

    .Footer {
        position: absolute;
        bottom: 3.125rem;
        width: 100%;
    }
}
