.UploadStateOptions {
    margin-bottom: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .UploadText {
        color: black;
        font-size: 12px;
        font-weight: 600;
    }

    .RetryFetch {
        display: flex;
        align-items: center;
        gap: 0.25rem;
        cursor: pointer;
        color: var(--primary-text-colour);
        background: var(--secondary-bg-colour);
        padding: 0.5rem;
        border-radius: 6px;
        font-size: 12px;
        font-weight: 600;
    }
    .isCompany {
        display: flex;
        align-items: center;
        gap: 0.25rem;
        cursor: pointer;
        color: var(--primary-text-colour);
        background: var(--secondary-bg-colour);
        padding: 0.5rem;
        border-radius: 6px;
        font-size: 12px;
        font-weight: 600;
    }
}

.FetchBtn {
    margin: 1rem 0;
    border-radius: 8px;
    background: #fff;
    box-shadow: 0px 1px 5px 0px rgba(112, 144, 176, 0.16);
    padding: 16px;
    color: var(--primary-text-colour);
    font-size: 12px;
    font-weight: 600;
    line-height: 12px;
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;

    & > img {
        margin-right: 5px;
    }
}

.ExitFinboxOptions {
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .SomethingWrong {
        color: #d06d6d;
        font-size: 12px;
        font-weight: 600;

        display: flex;
        align-items: center;

        & > img {
            margin: 0 3px 2.5px 0;
        }
    }

    .UploadManually {
        cursor: pointer;
        color: var(--primary-text-colour);
        font-size: 12px;
        font-weight: 600;
    }
}

.Connected {
    border-radius: 8px;
    background: #fcfcfc;
    box-shadow: 0px 1px 5px 0px rgba(112, 144, 176, 0.16);
    padding: 16px;
    color: #00bd00;
    font-size: 12px;
    font-weight: 600;
    line-height: 12px;
    cursor: pointer;
    text-align: center;
}

.Disabled {
    filter: grayscale(100%);
    pointer-events: none;
}
