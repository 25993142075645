.Card {
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    padding: 0.85rem;
    background-color: #ffffff;
}

.CardHeader {
    font-family: 'Gilroy-SemiBold', sans-serif;
    font-size: 14px;
    margin-bottom: 8px;
    display: flex;
    align-items: center;

    img {
        margin-right: 5px;
        height: 12px;
    }
}

.CardBody {
    display: flex;
    flex-direction: column;
}

.CardRow {
    display: flex;
    justify-content: space-between;
    margin-bottom: 4px;
}

.MetricName {
    font-family: 'Gilroy-Medium', sans-serif;
    font-size: 11px;
    color: #545b62;
}

.MetricValue {
    font-family: 'Gilroy-Medium';
    font-size: 0.875rem;
    color: #000000;
}
