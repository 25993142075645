@import '../../../../../scss/card';
@import '../../../../../scss/animations';

.AdditionalDocuments {
    @include data-upload-card;
    @include animate-fadein(300ms, 0, 0);

    margin-top: 20px;
    color: #000;
    font-size: 14px;
    font-weight: 500;
    line-height: 150%;

    .Title {
        color: #000;
        font-size: 16px;
        font-weight: 600;
        line-height: 16px;
    }

    .Subtext {
        font-size: 12px;
        font-family: 'Gilroy-Medium', sans-serif;
        margin-top: 5px;
        color: #696969;
    }
}
