.AlertEngineTableWeapper {
    height: 100%;
    padding: 1.5rem 2rem;
    span {
        font-family: Gilroy-SemiBold, serif;
        font-size: 0.75rem;
        color: #000000;
    }
}
.TableWrapper {
    position: relative;
    max-height: 80vh;
    overflow: hidden;
    margin-top: 1rem;
    border-radius: 8px;
    background: #fff;
    box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1);
}
.Headers {
    position: sticky;
    top: 0;
    padding: 16px 16px;
    color: #535b62;
    font-size: 0.875rem;
    font-family: Gilroy-SemiBold;
    background-color: #f9fafc;
    display: grid;
    align-items: center;
    grid-template-columns: 0.75fr 4.5fr 1.5fr 0.5fr;
    border-bottom: 1px solid #d7dfe9;
    height: 2.75rem;
}
.Note {
    color: #000;
    font-size: 12px;
    font-weight: 500;
    padding: 16px 16px;
}
.Row {
    color: #000;
    font-size: 12px;
    font-weight: 500;
    height: auto;
    padding: 10px 16px;
    display: grid;
    align-items: center;
    grid-template-columns: 0.75fr 4.5fr 1.5fr 0.5fr;
    border-bottom: 1px solid #d7dfe9;

    &:last-child {
        border-bottom: none;
    }
    .SeverityPill {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0.25rem;
        width: 60%;
        border-radius: 0.5rem;
        font-size: 0.875rem;
    }
    .CategoryPill {
        @extend .SeverityPill;
        padding: 0.25rem 1rem;
        width: fit-content;
        background: #f5f5f5;
        font-family: Gilroy-Medium, serif;
        color: #404040;
    }
    .Description {
        display: flex;
        align-items: center;
        font-size: 0.875rem;
        font-family: Gilroy-SemiBold, serif;
    }
    .Date {
        font-size: 0.875rem;
        color: #667085;
        font-family: Gilroy-Medium, serif;
    }
}
