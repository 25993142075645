.folder {
}
.FolderName {
    color: #000000;
    font-family: Gilroy-SemiBold, sans-serif;
    font-size: 0.87rem;
}

.FolderDate {
    display: flex;
    color: #afafaf;
    font-family: Gilroy-Regular, sans-serif;
    font-size: 0.8rem;
    font-weight: 500;
}
.folderHeader {
    cursor: pointer;
    border-bottom: 1px solid #eaecf0;
    display: grid;
    align-items: center;
    grid-template-columns: 2.3fr 0.3fr 0.5fr 0.1fr 0.1fr;
    height: 3.438rem;
    // padding: 0.75rem 0;
    // grid-template-columns: 7fr 1fr 0.3fr 0.35fr;
    padding: 0.75rem 0;
    &:hover {
        background: var(--hover-bg);
    }
}
.folderHeaderLenderView {
    cursor: pointer;
    border-bottom: 1px solid #eaecf0;
    display: grid;
    align-items: center;
    grid-template-columns: 2.3fr 0.3fr 0.5fr 0.1fr 0.1fr;
    padding: 0.75rem 0;
    &:hover {
        background: var(--hover-bg);
    }
}

.downloadIconContainer {
    background: transparent;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 0 0.5rem;
    padding: 0.5rem;
    border-radius: 8px;
    img {
        height: 1rem;
        width: 1rem;
    }
}
.downloadIconContainer:hover {
    padding: 0.5rem;
    background: #f6f6f67f;
}
.folderContent {
    max-height: 0;
    overflow: hidden;
    opacity: 0;
    transition: all 0.3s ease-out;
    z-index: 0; // Lower z-index to ensure dropdowns appear on top
}

.open {
    max-height: 10000rem;
    opacity: 1;
    z-index: 0; // Lower z-index to ensure dropdowns appear on top
}
.close {
    max-height: 0;
    opacity: 0;
}
.arrowUp {
    transition: transform 0.3s ease-out;
    transform: rotate(90deg);
}
.arrowDown {
    transition: transform 0.3s ease-out;
    transform: rotate(180deg);
}
.ThreeDots {
    position: relative; // Makes sure that OtherOptions are positioned relative to this element
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
        width: 1rem;
        height: 1rem;
    }
}
.OtherOptions {
    position: fixed;
    z-index: 999;
    background: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    border-radius: 0.25rem;
    min-width: 5.125rem;
    display: flex;
    width: 3rem;
    flex-direction: column;
    div {
        font-size: 0.875rem;
        padding: 0.5rem 1rem;
        cursor: pointer;
        &:hover {
            background: var(--hover-bg);
            border-radius: 8px;
        }
    }
}
.separator {
    margin: 0;
    padding: 0;
    height: 3.4rem;
    width: 0.0625rem;
    background-color: #d0d5dd;
}

.labelStatus {
    border-radius: 44px;
    color: var(--primary-bg-colour);
    background-color: var(--secondary-bg-colour);
    padding: 0.25rem 0.5rem;
    font-size: 14px;
    width: 67px;

    /* Center text */
    display: flex;
    align-items: center; /* Vertical center */
    justify-content: center; /* Horizontal center */
    text-align: center;
    height: 24px; /* Adjust height if needed */
}
