.OTPContainer {
    display: flex;
    flex-direction: row;
    gap: 5%;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.OTPInput {
    padding: 0.5em;
    width: 15%;
    height: 100%;
    border: 0.5px solid #ffffff;
    border-radius: 6px;
    font-weight: 600;
    background-color: #f3f3f3;
    color: black;
    text-align: center;

    @media only screen and (max-width: 600px) {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &:focus {
        outline: none;
        background-color: #ffffff;
        border: 0.5px solid var(--secondary-button-border);
    }

    &:focus-visible {
        outline: none;
        border: 0.5px solid var(--secondary-button-border);
    }

    &:active {
        outline: none;
        border: 0.5px solid var(--secondary-button-border);
    }

    &:disabled {
        cursor: no-drop;
    }
}

.HasData {
    background-color: #ffffff;
    border: 0.5px solid #c7c7c7;
}
