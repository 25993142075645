@import '../../../../../../scss/card';
@import '../../../../../../scss/animations';

.Checks {
    // margin-top: 44px;
    @include new-overview-card;
    .Header {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;

        color: rgba(0, 0, 0, 0.7);
        font-size: 24px;
        font-weight: 600;
        line-height: 24px;
    }

    .Body {
        margin-top: 16px;
        @include overview-card;
        border: none;

        & > div {
            @include animate-fadein(300ms, 0px, 10px);
        }
    }

    .ShimmerText {
        margin-bottom: 0;
    }
}
