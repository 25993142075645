.tooltipContainer {
    position: fixed;
    z-index: 100;
    width: 21rem;
    max-width: 90%;
    font-family: Arial, sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
    pointer-events: auto;
    opacity: 1;
    transition: opacity 0.6s ease-in-out;
    border-radius: 8px;
}

/* Tooltip Content Box */
.tooltipContent {
    background: white;
    color: #333;
    padding: 12px 16px;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    position: relative;
}

.tooltipHeading {
    margin: 0px;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 5px;
    line-height: 21px;
    color: #4d4d4d;
    font-family: 'Gilroy-Medium', sans-serif;
}

.tooltipMessage {
    font-family: Gilroy, sans-serif;
    font-size: 14px;
    margin-bottom: 10px;
}

.ctaButton {
    display: inline-block;
    padding: 8px 12px;
    background: #007bff;
    color: white;
    border-radius: 6px;
    text-decoration: none;
    transition: background 0.3s;
    pointer-events: auto;

    &:hover {
        background: #0056b3;
    }
}

/* Tooltip Positioning */
.top {
    bottom: 100%;
    margin-bottom: 15px;
}

.bottom {
    top: 100%;
    margin-top: 12px;
}

/* Tooltip Arrow (Triangle) */
.tooltipArrow {
    width: 20px;
    height: 10px;
    position: absolute;
    left: 30px;
}

.top .tooltipArrow {
    top: 100%; /* Places triangle BELOW tooltip content */
}

.bottom .tooltipArrow {
    top: -22px; /* Places triangle ABOVE tooltip content */
    transform: rotate(180deg);
}

.closeButton {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 8px;
    top: 8px;
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
    color: #666;
    padding: 8px;
    line-height: 1;
    border-radius: 4px;

    &:hover {
        background-color: rgba(0, 0, 0, 0.05);
    }

    &:focus {
        outline: none;
        background-color: rgba(0, 0, 0, 0.1);
    }
}
