.ModalBody {
    padding: 1rem 2rem 0 2rem;
    width: 451px;
    p {
        font-size: 12px;
    }
}
.Row {
    margin-top: 30px;
    display: flex;
    align-items: center;
}
.Bottom {
    padding: 0px 24px 24px 24px;
}
