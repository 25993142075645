@import '../../scss/breakpoints';
@import '../../scss/animations';

.Dashboard {
    .PageWrapper {
        display: flex;
        column-gap: 34px;
        overflow: hidden;
        height: 100%;

        .Sidebar {
            width: 200px;
            min-width: 200px;
            display: flex;
            flex-direction: column;
            height: 100%;
        }

        .MainContent {
            width: 60%;
            padding: 0;
            height: fit-content;
            flex: 1;
        }
    }
}

//.Animate {
//    @include animate-fadein(300ms, 0, 0);
//
//}

.LastFetched {
    color: #81878c;
    font-size: 10px;
    font-weight: 500;
    white-space: nowrap;
}

.PeriodSelectorRow {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.PeriodSelectorRowLeft {
    display: flex;
    align-items: center;
    column-gap: 20px;
}

.Stats {
    width: 100%;
    margin-top: 34px;
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
    column-gap: 34px;
}

.StatsPDFReport {
    margin-top: 24px;
    column-gap: 24px;
}

.CompliancesStats {
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
}

.SectionsContainer {
    .Section {
        .Header {
            font-family: 'Gilroy-Bold', sans-serif;
            font-size: 14px;
            display: flex;
            align-items: center;
            color: #08080d;

            & > div {
                margin-left: 30px;
            }
        }
    }
}

.GraphsContainer {
    padding: 10px 0 50px 0;
    display: grid;
    margin: 34px 0;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
    row-gap: 34px;
    column-gap: 34px;
}

.GraphsContainerPDFReport {
    margin: 14px 0;
    row-gap: 24px;
    column-gap: 24px;
}
.Graph {
    padding: 16px;
    background-color: white;
    border-radius: 8px;
}

.NoGSTData {
    margin-top: 34px;
    background: white;
    border-radius: 8px;
    padding: 20px 24px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;

    .Right {
        font-size: 14px;
        font-weight: 500;
        line-height: 16.48px;
        text-align: center;
        color: #9d9d9d;
    }
}

.MagicUploadButton {
    display: flex;
    align-items: center;
    column-gap: 5px;
    font-size: 14px;
    cursor: pointer;
    background: var(--primary-bg-colour);
    color: #ffffff;
    padding: 0.75rem 1rem;
    border-radius: 4px;
    font-weight: 600;
    position: relative;
    overflow: hidden;

    & > img {
        margin-bottom: 2px;
    }

    &:hover {
        animation-play-state: paused;
    }
}

.MagicUploadButton::before,
.MagicUploadButton::after {
    content: '';
    position: absolute;
    width: 14px;
    height: 200%;
    background: linear-gradient(to left, #ffffff 4.79%, rgba(153, 153, 153, 0) 76.27%);
    opacity: 0.2;
    transform: rotate(17deg);
    animation: reflect 2s infinite;
}

.MagicUploadButton::after {
    animation-delay: 0.1s;
}
@keyframes reflect {
    0% {
        left: -150%;
    }
    100% {
        left: 110%;
    }
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

.redDot {
    position: absolute;
    height: 25px;
    width: 25px;
    right: -8%;
    top: -25%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.75rem;
    color: white;
    font-family: 'Gilroy-SemiBold', sans-serif;
    padding: 0.25rem 0.35rem 0.12rem 0.35rem;
    border-radius: 50%;
    background-color: red;
}

.ExclamationIcon {
    position: absolute;
    right: -10%;
    top: -25%;
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

.PendingDone {
    @extend .PedingCTA;
    background: #e6f7dd !important;
    span {
        color: #49a71b !important;
    }
}

.PedingCTA {
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 0.625rem 1rem;
    border-radius: 0.5rem;
    gap: 0.5rem;
    background: #ffeeee;
    white-space: nowrap;
    span {
        color: #df666a;
        font-size: 0.875rem;
        font-family: 'Gilroy-SemiBold', sans-serif;
    }
}

.Title {
    margin-bottom: 24px;
    background-color: white;
    border-radius: 8px;
    padding: 0.4rem 0.85rem;
    font-size: 14px;
    font-weight: 500;
    font-family: 'Gilroy-SemiBold', sans-serif;

    display: flex;
    align-items: center;
    justify-content: space-between;
}
