.EmptyBank {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    height: 60vh;
    background-color: white;
    box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.06);
    border-radius: 8px;
    margin-top: 20px;
    font-weight: 600;
}
