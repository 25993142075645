@import '../../../scss//scrollbars';
.TooltipV2 {
    position: fixed;
    font-size: 14px;
    padding: 8px 8px 6px 8px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
    opacity: 0;
    transform: translateY(-10px);
    transition:
        opacity 0.2s ease-in-out,
        transform 0.2s ease-in-out;
    z-index: 9999999999999999999;

    color: var(--tooltip-text-color);
    background: var(--tooltip-background-color);
    font-size: 12px;

    max-width: 400px;
    max-height: 300px;
    overflow: auto;
    @include no-bg-scroll;
    white-space: pre-wrap;
    word-break: break-word;
    overflow-wrap: break-word;

    &__Show {
        opacity: 1;
        transform: translateY(0);
    }
}
