.ProvisionalCard {
    width: 50%;
    padding: 30px;
    border-radius: 8px;
    background-color: #ffffff;
    border: 1px solid #e7e7e7;
    animation: DirectorCibil_fadein__V6oss 300ms ease-in-out;
    margin-top: 30px;
    color: #000;
    font-size: 14px;
    font-weight: 500;
    line-height: 150%;
    // media query for mobile
    @media (max-width: 768px) {
        width: 80%;
    }
}
.mainHeading {
    font-family: Gilroy-SemiBold, sans-serif;
    font-size: 16px;
}
.Subheading {
    margin: 10px 0;
    font-size: 12px;
}
.MainBanner {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-top: 1rem;
    padding: 0.5rem;
    background-color: var(--secondary-bg-colour);
    border-radius: 5px;
    p {
        margin: 0;
        font-size: 12px;
        font-family: 'Gilroy-Medium', sans-serif;
    }
}
.Options {
    margin: 20px 0 0 0;
    display: flex;
    align-items: center;

    .Option {
        width: 100%;
        height: 40px;
        border: 1px solid var(--primary-border-colour);
        border-radius: 4px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
            border: 1px solid rgb(137, 137, 137);
        }

        .uploadManually {
            color: #000000;
            font-family: 'Gilroy-SemiBold', sans-serif;
            margin-left: 5px;
            .upload {
                font-size: 12px;
                line-height: 9px;
            }
        }
    }

    .Integrated {
        position: relative;
        overflow: visible;
        border: 1px solid #4aac4f;
        background-color: #14c51d15;

        &::after {
            content: '';
            height: 1rem;
            width: 1rem;
            position: absolute;
            right: -0.35rem;
            top: -0.35rem;
            background-image: url(../../../../../assets/Svg/green-tick.svg);
            background-position: center;
            background-size: cover;
        }
    }

    .Selected {
        border: 1px solid rgb(74, 74, 74);
    }

    .Disabled {
        border: none;
        background-color: #bcbcbc;
        pointer-events: none;
    }
}

.InvestorOptions {
    margin: 20px 0 0 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .Option {
        width: 100%;
        height: 40px;
        border: 1px solid var(--primary-border-colour);
        border-radius: 4px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
            border: 1px solid rgb(137, 137, 137);
        }

        .uploadManually {
            color: var(--primary-text-colour);
            font-family: 'Gilroy-SemiBold', sans-serif;
            margin-left: 5px;

            .upload {
                font-size: 12px;
                line-height: 9px;
            }
        }
    }

    .Integrated {
        position: relative;
        overflow: visible;
        border: 1px solid #4aac4f;
        background-color: #14c51d15;

        &::after {
            content: '';
            height: 1rem;
            width: 1rem;
            position: absolute;
            right: -0.35rem;
            top: -0.35rem;
            background-image: url(../../../../../assets/Svg/green-tick.svg);
            background-position: center;
            background-size: cover;
        }
    }

    .Selected {
        border: 1px solid rgb(74, 74, 74);
    }

    .Disabled {
        border: none;
        background-color: #bcbcbc;
        pointer-events: none;
    }
}

.Tally {
    margin-top: 20px;

    p {
        margin: 0;
        font-size: 12px;
        color: #000000;
        font-family: 'Gilroy-Semibold';

        a {
            color: var(--primary-text-colour);
        }
    }

    .BottomText {
        margin-top: 20px;
        display: flex;
        align-items: center;
    }
}

.Download {
    color: var(--primary-text-colour);
    cursor: pointer;
}

.Loading {
    margin: 14px 0;
    text-align: center;
    height: 80px;
    padding: 15px 0;
    color: #939393;

    .Text {
        font-size: 11px;
        margin-top: 10px;
    }
}
.ErrorText {
    color: #d06d6d;
    display: flex;
    align-items: center;
    font-size: 12px;
    margin-top: 10px;
    white-space: nowrap;
    flex-wrap: wrap;
    .ErrorFilename {
        margin-right: 5px;
        width: auto;
        max-width: 50ch;
        text-decoration: underline;
        cursor: pointer;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.Next {
    animation: slideLeft 600ms ease-in-out;
}

.Previous {
    animation: slideRight 600ms ease-in-out;
}

@keyframes slideLeft {
    0% {
        opacity: 1;
    }

    30% {
        opacity: 0;
    }

    40% {
        opacity: 0;
        transform: translateX(-20px);
    }

    60% {
        opacity: 0;
        transform: translateX(20px);
    }

    70% {
        opacity: 1;
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes slideRight {
    0% {
        opacity: 1;
    }

    40% {
        opacity: 0;
        transform: translateX(20px);
    }

    60% {
        opacity: 0;
        transform: translateX(-20px);
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

.BackContainer {
    display: flex;
    align-items: center;
    cursor: pointer;
    gap: 0.75rem;
    span {
        color: var(--primary-text-colour);
    }
}

.InvestorBackContainer {
    @extend .BackContainer;
    span {
        color: var(--primary-text-colour);
    }
}
