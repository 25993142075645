.DeleteContainer {
    width: 451px;
    height: auto;
    .MessageContainer {
        padding: 24px;
        .Heading {
            font-family: 'Gilroy-SemiBold';
            font-size: 1.5rem;
            margin-bottom: 10px;
        }
        .Message {
            font-family: 'Gilroy-Medium';
            font-size: 0.75rem;
        }
    }
    .ButtonContainer {
        padding: 24px;
        display: flex;
        justify-content: space-between;
        .CancelButton {
            background-color: white;
            border: 1px solid #e7e7e7;
            padding: 10px 16px;
            border-radius: 4px;
            color: var(--primary-text-colour);
            font-family: 'Gilroy-Medium';
            font-size: 0.875rem;
            width: 195.5px;
            cursor: pointer;
        }
        .DeleteButton {
            background-color: #e3777b;
            border: 1px solid #e7e7e7;
            padding: 10px 16px;
            border-radius: 4px;
            color: white;
            font-family: 'Gilroy-Medium';
            font-size: 0.875rem;
            width: 195.5px;
            cursor: pointer;
        }
    }
}
