@import '../../../../scss/scrollbars';
.Container {
    height: 100%;

    display: flex;

    .Top {
        flex: 1;
        height: 100%;
        overflow: auto;
        @include no-bg-scroll2;
    }

    .Comments {
    }
}

.Footer {
    position: sticky;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 10px;
    padding: 17px 30px;
    box-shadow: 0px -1px 10px 0px #0000001a;

    .Text {
        color: #9d9d9d;
        font-size: 12px;
        text-align: center;
    }

    .Regen {
        display: flex;
        align-items: center;
        color: var(--primary-purple);
        column-gap: 8px;
        font-size: 14px;
        cursor: pointer;
    }

    .Progress {
        color: #49a71b;
        display: flex;
        align-items: center;
        column-gap: 10px;
        margin-left: 100px;
    }

    .Buttons {
        display: flex;
        align-items: center;
        color: #d2d2d210;
    }
}

// .Comments{
//     height: calc(100% - 72px);
//     width: fit-content;
//     border: 1px solid blue;
//     overflow: hidden;
// }
