.SmallMonthlyView {
    margin: 0 auto 0px auto;
    overflow-x: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.SingleSummaryContainer {
    display: flex;
    align-items: center;
}

.SingleSummary {
    margin-right: 40px;
    display: flex;
    align-items: center;
    animation: fadeIn 100ms ease-in-out;
    white-space: nowrap;
}

.TableContainer {
    width: 100%;
    overflow: hidden; /* Ensure no overflow */
    .Table {
        border-collapse: separate;
        width: 100%;
        .FirstColumn {
            min-width: 30px;
            border-bottom: 1px dashed black;
        }

        .MonthName {
            font-weight: 500;
            font-size: 12.7972px;
            line-height: 15px;
            color: #bcbcbc;
            padding: 10px 0;
            border-bottom: 1px dashed black;
        }

        .Year {
            font-weight: 500;
            font-size: 12.7972px;
            line-height: 15px;
            color: #000000;
            text-align: center;
            padding-left: 0 !important;
            position: sticky;
            left: 0;
            background-color: white;
        }

        .Item {
            align-items: center;

            .Img {
                padding: 20px 0;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .DelayCount {
                background-color: #fef9ec;
                color: #e49e00;
                height: 24px;
                width: fit-content;
                font-size: 13.7px;
                font-weight: 500;
                line-height: 13.7px;
                text-align: center;
                border-radius: 28px;
                padding: 2px 5px 0 6px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }

    .TableReviewScreen {
        .FirstColumn {
            min-width: 30px;
        }

        .MonthName {
            padding: 5px 10px;
        }

        .Item {
            align-items: center;

            .Img {
                padding: 11px 0;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
}

.LegendContainer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 12px;
    color: #818181;
    .Legend {
        display: flex;
        align-items: center;
        margin-left: 10px;
        & > img {
            margin-bottom: 2px;
            margin-right: 5px;
        }
    }
    &.PDFReportLegend {
        white-space: nowrap;
        margin-left: 20px;
    }
}

.PDFReport {
    overflow: hidden;
    height: auto;
    width: 100%;

    .TableContainer {
        .Table {
            .MonthName {
                font-size: 10px;
                padding: 5px 0px;
            }

            .Year {
                font-size: 10px;
            }

            .Item {
                .Img {
                    padding: 0.7rem 0;
                }

                .DelayCount {
                    height: 16px;
                    font-size: 9px;
                }
            }
        }

        .TableReviewScreen {
            .MonthName {
                padding: 5px 2px;
            }

            .Item {
                .Img {
                    padding: 2px 0;
                }
            }
        }
    }

    .LegendContainer {
        font-size: 8px;
    }
}
