@import '../../../../../scss/scrollbars';

.DealsList {
    border-radius: 12px;
    // margin-top: 34px;
    background-color: white;
    height: calc(100vh - 160px);
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;
    gap: 24px;
    //padding: 24px;

    .Top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1.5rem 1.5rem 0 1.5rem;
    }

    .LeftSection {
        display: flex;
        align-items: center;
        gap: 24px;

        .SectionTitle {
            font-size: 24px;
            font-weight: 700;
            color: #000000;
            // border-right: 1px solid #d9d9d9;
            padding: 2px 24px 2px 0;
            margin-right: 24px;
        }
    }

    .RightSection {
        display: flex;
        align-items: center;
        gap: 24px;
    }

    .TableContainer {
        overflow-y: auto;
        scrollbar-gutter: stable;
        height: calc(100vh - 313px);
        box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;

        @include scrollbar-show-on-hover;

        .PageNumber {
            border-top: 1px solid #e5e5e5;
            padding: 12px 24px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            color: #344054;
            font-size: 14px;
            font-weight: 600;
            line-height: 20px;

            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            background-color: white;

            .Buttons {
                display: flex;
                align-items: center;
                column-gap: 20px;

                .Btn {
                    padding: 8px 12px;
                    color: #344054;
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 20px;
                    cursor: pointer;

                    border-radius: 8px;
                    border: 1px solid var(--gray-300, #d0d5dd);
                    background: var(--white, #fff);
                    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

                    &:active {
                        transform: translateY(2px);
                    }
                }

                .Disabled {
                    box-shadow: none;
                    border: 1px solid var(--gray-300, #dce1e8);
                    color: #c6c6c6;
                    cursor: not-allowed;
                    pointer-events: none;
                }
            }
        }
    }
}
