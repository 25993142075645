.miniDrawer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: #fff;
    border-top: 1px solid #ddd;
    box-shadow: 0 -4px 6px rgba(0, 0, 0, 0.1);
    padding: 1rem;
    transform: translateY(100%); /* Hidden by default */
    transition: transform 0.3s ease-out;
    z-index: 20; /* Layer it above the backdrop */
}

.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
    z-index: 10; /* Layer it behind the mini-drawer */
    animation: fadeIn 0.3s ease-out;
}

.miniDrawerContent {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

h4 {
    margin: 0;
    font-size: 16px;
    font-weight: 600;
}

.radioList {
    display: flex;
    flex-direction: column;
    gap: 12px;
    max-height: 15rem;
    overflow: auto;
}

.radioItem {
    display: flex;
    align-items: center;
    gap: 12px;

    /* Static Circle */
    .circle {
        width: 16px;
        height: 16px;
        border: 1px solid black;
        border-radius: 50%;
        background-color: white;
    }

    .editable {
        flex: 1;
        padding: 4px 8px;
        border: 1px solid transparent;
        min-height: 20px;
        outline: none;
        cursor: text;
        transition: border 0.2s ease-in-out;

        &:hover,
        &:focus {
            //border: 1px solid #ccc;
            border-radius: 4px;
        }
    }
}

.buttonGroup {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;

    .PrimaryBtn {
        min-width: 5rem;
        cursor: pointer;
        border-radius: 0.5rem;
        outline: none;
        display: flex;
        align-items: center;
        justify-content: center;
        background: var(--primary-button-bg);
        color: var(--primary-button-text);
        padding: 0.75rem;
        border: 1px solid var(--primary-border-colour);
    }

    .DisabledBtn {
        @extend .PrimaryBtn;
        filter: grayscale(1);
        opacity: 0.2;
        cursor: not-allowed;
    }

    .SecondaryBtn {
        min-width: 5rem;
        cursor: pointer;
        border-radius: 0.5rem;
        outline: none;
        display: flex;
        justify-content: center;
        align-items: center;
        background: var(--secondary-button-bg);
        color: var(--secondary-button-text);
        padding: 0.75rem;
        border: 1px solid var(--primary-border-colour);
    }
}

.miniDrawer.open {
    transform: translateY(0); /* Slide into view */
}

.miniDrawer.closed {
    transform: translateY(100%); /* Slide out of view */
}

/* Fade-in animation for the backdrop */
@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
