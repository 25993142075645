.ConfirmationModal {
    width: 450px;
    padding: 24px;

    .Title {
        font-size: 24px;
        font-family: 'Gilroy-SemiBold', sans-serif;
        line-height: 32px;
    }

    .Subtext {
        margin-top: 12px;
        font-size: 14px;
        font-family: 'Gilroy-Medium', sans-serif;
        line-height: 18px;
    }

    .Buttons {
        font-family: 'Gilroy-SemiBold', sans-serif;
        margin-top: 36px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 12px;
    }
}
