.MainHeading {
    margin: 0 0 34px;
}
.MainBanner {
    display: flex;
    align-items: center;
    gap: 1rem;
    padding: 0.5rem;
    background-color: var(--secondary-bg-colour);
    border-radius: 5px;
    p {
        margin: 0;
        font-size: 12px;
        font-family: 'Gilroy-Medium', sans-serif;
    }
}
.Heading {
    font-family: 'Gilroy-Medium';
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.00027955271070823073px;
    text-align: left;
}
.FormContainer {
    .Field {
        position: relative;

        .FormInput {
            padding: 16px 12px;
            width: 100%;
            box-sizing: border-box;
            box-shadow: none;
            outline: none;
            border: none;
            color: #000000;
            font-size: 12px;
            font-weight: 500;
            border-radius: 6px;
            background-color: #f9f9f9;
            border: 0.5px solid transparent;
            z-index: 1;

            // border-color: ${(props) => (props.errorBorder ? '#CE121D !important' : 'none')};
            &:focus,
            &:valid {
                border: 0.5px solid #4c8dff;
                background-color: #ffffff;
            }

            &:focus ~ label {
                top: -15px;
                left: 0;
                font-size: 10px;
                color: #8d8d8d;
                font-weight: 300;
            }

            &:valid ~ label {
                top: -15px;
                left: 0;
                font-size: 10px;
                color: #8d8d8d;
                font-weight: 300;
            }

            &:-webkit-autofill {
                box-shadow: 0 0 0 30px white inset;
                -webkit-box-shadow: 0 0 0 30px white inset;
            }

            &:disabled {
                background-color: #f8fff8 !important;
                border: 0.5px solid white;
                box-shadow: 0 0 0 30px #f8fff8 inset;
            }
        }
        .FormLabel {
            position: absolute;
            pointer-events: none;
            left: 10px;
            transition: 0.5s;
            top: 16px;
            font-size: 12px;
            color: #c3c3c3;
            font-weight: 300;
            transition: 0.5s;
            margin-bottom: 0px;
        }

        .LabelTop {
            top: -15px;
            left: 0;
            font-size: 10px;
            color: #8d8d8d;
            font-weight: 300;
        }

        .NewDirRemove {
            position: absolute;
            top: -0.4375rem;
            right: -0.4375rem;
            cursor: pointer;
            width: 0.875rem;
            height: 0.875rem;
        }
    }
    .LabelTop {
        top: -15px;
        left: 0;
        font-size: 10px;
        color: #8d8d8d;
        font-weight: 300;
    }
    .LastDateText {
        font-size: 8px;
        margin-right: 0.3rem;
        font-family: gilroy-semibold, sans-serif;
        white-space: nowrap;
        color: #8d8d8d;
        cursor: default;
    }
    .Banner {
        position: absolute;
        right: 6px;
        top: 6px;
        font-size: 12px;
    }
    .VerifyIcon {
        width: 0.875rem;
        height: 0.875rem;
        margin-right: 6px;
    }

    .BannerText {
        @extend .Banner;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        font-weight: 600;
        white-space: nowrap;
        color: var(--primary-text-colour);
        cursor: pointer;
        padding: 8px 12px;
        background-color: var(--secondary-bg-colour);
        border-radius: 4px;
    }
    .DisabledBannerText {
        @extend .Banner;
        color: #c3c3c3;
        white-space: nowrap;
        cursor: no-drop;
        pointer-events: none;
        background-color: transparent;
    }
    .BannerTextVerified {
        @extend .Banner;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #4cb71a;
        font-size: 14px;
        font-weight: 600;
        white-space: nowrap;
        cursor: no-drop;
        pointer-events: none;
        padding: 8px 12px;
    }
}
.dflex {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: 2.125rem;
}
.DirectorDetailsContainer {
    margin-top: 2.125rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 0.5rem;
}
.AddDirText {
    font-size: 12px;
    font-family: 'Gilroy-Medium', sans-serif;
    white-space: nowrap;
}
.AddDirCta {
    font-size: 12px;
    font-family: 'Gilroy-Medium', sans-serif;
    white-space: nowrap;
    color: var(--primary-text-colour);
    cursor: pointer;
}

.VerifyImg {
    display: flex;
    align-items: center;
}
