@import '../../../../../scss/card';
@import '../../../../../scss/animations';

.DirectorCibil {
    @include data-upload-card;
    @include animate-fadein(300ms, 0, 0);
    margin-top: 20px;
    color: #000;
    font-size: 14px;
    font-weight: 500;
    line-height: 150%;

    .Title {
        color: #000;
        font-size: 16px;
        font-weight: 600;
        line-height: 16px;
    }

    .Text {
        margin-top: 16px;
    }

    .Check {
        margin: 16px 0;
        display: flex;
        align-items: center;
        color: #161513;
        font-size: 13.75px;
        font-weight: 500;
        line-height: 16px;

        & > input {
            margin-right: 5px;
            margin-bottom: 2px;
        }
    }
    .CIBIL {
        margin-bottom: 10px;
    }

    .Equafix {
        @include data-upload-card;
        margin-top: 30px;
        color: #000;
        font-size: 14px;
        font-weight: 500;
        line-height: 150%;
    }
    .Separator {
        margin-top: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: 10px;

        .Line {
            height: 3px;
            width: 100%;
            background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='white' stroke='black' stroke-width='3' stroke-dasharray='3%2c 19' stroke-dashoffset='5' stroke-linecap='square'/%3e%3c/svg%3e");
        }
    }
}
