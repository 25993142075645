.TableContainer {
    max-height: 100%;
    overflow-x: auto;
    overflow-y: auto;
}
.LoadingContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
}

.Table {
    width: 100%;
    height: 100%;
    border-spacing: 0;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    border-collapse: separate;
}
.TableHeaders {
    background-color: #f9fafc;
    font-family: 'Gilroy-SemiBold', serif;
    font-size: 12px;
    line-height: 20px;
    z-index: 10;
    position: sticky;
    top: 0px;
    .TableHeader {
        font-size: 12px;
        text-align: left;
        position: sticky;
        top: 0;
        .HeaderCell {
            border-bottom: 1px solid #e0e0e0;
            min-width: 10rem;
            background: #f5f5f5;
            padding: 1rem;
            border-bottom: 1px solid #e0e0e0;
        }
        .FrozenCell {
            top: 0;
            border-bottom: 1px solid #e0e0e0;
            max-width: 10rem;
            min-width: 10rem;
            position: sticky;
            background: #f5f5f5;
            padding: 1rem;
            left: 0;
            z-index: 5;
            border-bottom: 1px solid #e0e0e0;
        }
    }
}
.TableBody {
    font-size: 12px;
    vertical-align: baseline;

    .LabelRow {
        .LabelCell {
            padding: 0.5rem 0.5rem;
            border-bottom: 1px solid #e0e0e0;
            text-align: center;
        }
    }
    .TableRow {
        .FrozenCell {
            min-width: 10rem;
            max-width: 10rem;
            position: sticky;
            left: 0;
            padding: 1rem;
            background: #ffffff;
            z-index: 5;
            border-bottom: 1px solid #e0e0e0;
        }
        .TableCell {
            padding: 0.5rem 1rem;
            background: #ffffff;

            border-bottom: 1px solid #e0e0e0;
        }
        .CellText {
            display: flex;
            align-items: center;
        }
    }
}

.SelectContainer {
    @extend .FrozenCell;
    padding: 0 !important;
}
.RawTableEditableField {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0.5rem;
    margin-left: 0.5rem;
    border-radius: 5px;
    border: 1px solid #e0e0e0;
    width: 80%;
    outline: none;
    :focus {
        outline: none;
        border: 1px solid var(--primary-border-colour);
    }
}
