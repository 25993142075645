/* Custom properties */
@import '../../../scss/animations';

:root {
    --tooltip-text-color: #979797;
    --tooltip-background-color: white;
    --tooltip-margin: 0px;
    --tooltip-arrow-size: 6px;
}

/* Wrapping */
.Tooltip-Wrapper {
    display: inline-block;
    position: relative;
    // cursor: pointer;
}

/* Absolute positioning */
.Tooltip-Tip {
    width: 420px;
    height: fit-content;
    position: absolute;
    left: calc(50%);
    transform: translateX(calc(-50% - 0.5px));
    padding: 7px 8px 6px 8px;
    // color: var(--tooltip-text-color);
    background: transparent;
    font-family: 'Gilroy-SemiBold';
    font-size: 12px;
    line-height: 15px;
    // line-height: 1;
    z-index: 100;
    white-space: pre-wrap;
    animation: fadeIn 150ms linear;
    // border: 1px solid red;
    margin-left: 0.8rem;
}

.Content {
    border-radius: 10px;
    padding: 7px 8px 6px 8px;
    color: var(--tooltip-text-color);
    background: var(--tooltip-background-color);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
}

/* CSS border triangles */
.Tooltip-Tip::before {
    content: ' ';
    left: 50%;
    border: solid transparent;
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-width: var(--tooltip-arrow-size);
    margin-left: calc(var(--tooltip-arrow-size) * -1);
}

/* Absolute positioning */
.Tooltip-Tip.top {
    // top: calc(var(--tooltip-margin) * -2);
    top: -55px;
    left: 200px;
}

/* CSS border triangles */
.Tooltip-Tip.top::before {
    top: 100%;
    border-top-color: var(--tooltip-background-color);
}

/* Absolute positioning */
.Tooltip-Tip.right {
    left: calc(100% + var(--tooltip-margin));
    top: 50%;
    transform: translateX(0) translateY(-50%);
}

.BenchmarkTooltip {
    top: -35px !important;
    left: 2px !important;
}

.BenchmarkTooltip::before {
    border-top-color: black !important;
}

/* CSS border triangles */
.Tooltip-Tip.right::before {
    left: calc(var(--tooltip-arrow-size) * -1);
    top: 50%;
    transform: translateX(0) translateY(-50%);
    border-right-color: var(--tooltip-background-color);
}

/* Absolute positioning */
.Tooltip-Tip.bottom {
    bottom: calc(var(--tooltip-margin) * -1);
    transform: translateX(-30%) translateY(105%);
}
.DealNotesTooltipBottom {
    bottom: 2rem !important;
    left: -5rem !important;
}

/* CSS border triangles */
.Tooltip-Tip.bottom::before {
    bottom: 100%;
    border-bottom-color: var(--tooltip-background-color);
}

/* Absolute positioning */
.Tooltip-Tip.left {
    left: auto;
    right: calc(100% + var(--tooltip-margin));
    top: 50%;
    transform: translateX(0) translateY(-50%);
}

/* CSS border triangles */
.Tooltip-Tip.left::before {
    left: auto;
    right: calc(var(--tooltip-arrow-size) * -2);
    top: 50%;
    transform: translateX(0) translateY(-50%);
    border-left-color: var(--tooltip-background-color);
}
.OverviewDetail {
    max-height: 5rem;
    overflow-y: scroll;
    scrollbar-width: none;
}
