@import '../../../../../scss/card';

.Box {
    @include data-upload-card;
    margin-top: 20px;
}
.Title {
    font-size: 1rem;
    font-family: Gilroy-bold, sans-serif;
    margin: 0;
}
.SubTitle {
    font-size: 0.75rem;
    font-family: Gilroy-semibold, sans-serif;
    color: #696969;
    margin-top: 0.5rem;
}
.DirectorHeading {
    font-size: 0.75rem;
    font-family: Gilroy-bold, sans-serif;
    margin: 0;
}
.Separator {
    border-top: 0.5px solid #d0d5dd;
    margin: 1.5rem 0;
}
.AddDirector {
    display: flex;
    flex-direction: row-reverse;
    margin-top: 20px;

    .AddDirectorBtn {
        background: transparent;
        box-shadow: 0px 2px 8px 0px #00000013;
        border: none;
        padding: 10px 20px;
        border-radius: 4px;
        cursor: pointer;
        color: var(--primary-text-colour);
    }
}
.AddDirectorModal {
    padding: 2.25rem;
    min-width: 26.5rem;

    .AddDirectorHeading {
        margin: 0;
        padding: 0;
        margin-bottom: 2rem;
        font-size: 1.5rem;
        font-family: Gilroy-semibold, sans-serif;
        color: #000000;
    }
}
