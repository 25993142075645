.DrawerWrapper {
    height: 100vh;
    display: flex;
    flex-direction: column;
    .DrawerHeading {
        height: 12%;
        display: flex;
        align-items: center;
        gap: 1rem;
        border-bottom: 1px solid #1615131a;
        h1 {
            padding: 1rem;
        }
    }
    .DrawerContent {
        padding: 1rem;
        height: 80%;
        .ContentHeadingContianer {
            display: flex;
            align-items: center;
            gap: 1rem;
            white-space: nowrap;
            margin-bottom: 1rem;
            background-color: #f7f7f7;
            padding: 1rem;
        }
        .ContentHeading {
            height: 10%;
            display: flex;
            align-items: center;
            font-family: 'Gilroy-SemiBold', sans-serif;
            gap: 1rem;
        }
    }
    .separator {
        height: 1.9rem;
        width: 1px;
        background-color: #d9d9d9;
    }
    .DrawerBody {
        box-shadow: 0 0 10px 2px #0000000f;
        height: 90%;
        padding-top: 1rem;
    }
    .Loader {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: 100%;
    }
}
.DrawerFooter {
    background: white;
    z-index: 5;
    margin-top: auto;
    height: 8%;
    padding: 1rem;
    border-top: 1px solid #e0e0e0;
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    .BlueButton {
        background-color: #2f8fff;
        border: none;
        color: white;
        padding: 0.5rem 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: Gilroy-SemiBold, serif;
        border-radius: 0.25rem;
        outline: none;
        cursor: pointer;
        &:disabled {
            background-color: #bcbcbc;
            color: white;
            border: none;
        }
    }
    .BlueButtonInv {
        @extend .BlueButton;
        background-color: var(--primary-bg-colour);
    }
}
.WhiteButton {
    background-color: white;
    border: 1px solid #2f8fff;
    color: #2f8fff;
    padding: 0.5rem 1rem;
    font-family: Gilroy-SemiBold, serif;
    border-radius: 0.25rem;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
    cursor: pointer;
    &:disabled {
        background-color: #fff;
        color: #bcbcbc;
        border: 1px solid #bcbcbc;
    }
}
.WhiteButtonInv {
    @extend .WhiteButton;
    border: 1px solid var(--primary-border-colour);
    color: var(--primary-text-colour);
}
