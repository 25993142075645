.AddAssesseeWrapper {
    padding: 1.5rem;
    width: 451px;
}
.Title {
    color: #000;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.2px;
}
.Row {
    margin-top: 30px;
    display: flex;
    align-items: center;
}
