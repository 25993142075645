.BarContainer {
    display: flex;
    align-items: center;
    column-gap: 20px;

    .Value {
        font-size: 18px;
        font-weight: 600;
        line-height: 21.47px;
        letter-spacing: 0.00023961660917848349px;
        text-align: left;
        color: #bcbcbc;

        .Black {
            color: #000;
        }
    }

    .BarParent {
        margin-bottom: 4px;
        height: 16px;
        width: 190px;
        background-color: #e5e9f2;
        border-radius: 2px;
        position: relative;

        .BarChild {
            position: absolute;
            height: 100%;
            top: 0;
            left: 0;
            border-radius: 2px;
            background: linear-gradient(
                90deg,
                var(--secondary-bg-colour) 0%,
                var(--primary-bg-colour) 100%
            );
            transition: width 1s ease-in-out;
        }
    }
}
