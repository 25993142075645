@import '../../../../../scss/card';
@import '../../../../../scss/animations';

.GSTUpload {
    margin-top: 24px;
    @include animate-fadein(300ms, 0, 0);
    @include data-upload-card;

    .Top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 16px;
        font-family: 'Gilroy-Bold';
    }

    .UploadContent {
        margin-top: 24px;

        .Text1 {
            margin-bottom: 15px;
            font-size: 12px;
            font-weight: 500;
            line-height: 18px;
            letter-spacing: 0.00027955271070823073px;

            .Link {
                color: var(--primary-text-colour);
                font-weight: 600;
                cursor: pointer;
            }
        }
    }
}
