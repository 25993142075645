@mixin overview-card {
    background-color: white;
    padding: 24px;
    border-radius: 8px;
    // border: 1px solid #d7dfe9;
}

@mixin data-upload-card {
    width: 50%;
    padding: 30px;
    border-radius: 8px;
    background-color: #ffffff;
    border: 1px solid #e7e7e7;
    // media query for mobile
    @media (max-width: 768px) {
        width: 80%;
    }
}

@mixin section-card {
    padding: 24px;

    border-radius: 8px;
    border: 0.5px solid #d8d8d8;
    background: #fff;
    box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.06);
}

@mixin card-with-title {
    .Top {
        @include overview-card;
        border-radius: 8px 8px 0 0;
        border-bottom: 1px solid #e8e8e7;
        height: 64px;
    }

    .Body {
        @include overview-card;
        border-radius: 0 0 8px 8px;
    }
}

@mixin new-overview-card {
    background-color: white;
    border-radius: 8px;

    .Top {
        padding: 24px;

        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #e5e5e5;

        .Title {
            font-size: 18px;
            font-weight: 600;
            line-height: 24px;
            letter-spacing: 0.00023961660917848349px;
            text-align: left;
        }

        .Right {
            .cta {
                display: flex;
                align-items: center;
                color: var(--primary-text-colour);
                column-gap: 5px;
                cursor: pointer;
                font-size: 14px;

                & > img {
                    margin-bottom: 2px;
                }
            }
        }
    }

    .Body {
        padding: 24px;
    }
}

@mixin cta-card {
    border: 1px solid #e8e8e7;
    border-radius: 8px;
    transition: box-shadow 150ms ease-in-out;
    padding: 16px;

    position: relative;
    overflow: hidden;

    &:hover {
        box-shadow: 0px 0px 20px 0px #00000033;

        .cta {
            position: absolute;
            transform: translateY(-100%);
        }
    }

    .cta {
        padding: 16px;
        background: var(--secondary-bg-colour);
        position: absolute;
        width: 100%;
        left: 0;
        top: 100%;
        transition: all 100ms ease-in-out;
        color: var(--primary-text-colour);

        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        text-align: left;

        display: flex;
        align-items: center;
        column-gap: 10px;
        cursor: pointer;

        &:hover {
            background: var(--hover-bg);
        }
    }
}
