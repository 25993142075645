@import '../../scss/animations';

.DataRoomWrapper {
    @include animate-fadein(500ms, 0, 0, 0.7);
}

.DataRoomContainer {
    height: 83dvh;
    overflow-y: hidden;
    background: #ffffff;
    border-radius: 0.25rem;
}
.LoadingContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
}

.MagicUploadButton {
    display: flex;
    align-items: center;
    column-gap: 5px;
    font-size: 14px;
    cursor: pointer;
    background: var(--primary-bg-colour);
    color: #ffffff;
    padding: 0.75rem 1rem;
    border-radius: 4px;
    font-weight: 600;
    position: relative;
    overflow: hidden;

    & > img {
        margin-bottom: 2px;
    }

    &:hover {
        animation-play-state: paused;
    }
}

.MagicUploadButton::before,
.MagicUploadButton::after {
    content: '';
    position: absolute;
    width: 14px;
    height: 200%;
    background: linear-gradient(to left, #ffffff 4.79%, rgba(153, 153, 153, 0) 76.27%);
    opacity: 0.2;
    transform: rotate(17deg);
    animation: reflect 2s infinite;
}

.MagicUploadButton::after {
    animation-delay: 0.1s;
}

@keyframes reflect {
    0% {
        left: -150%;
    }
    100% {
        left: 110%;
    }
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

.redDot {
    position: absolute;
    height: 25px;
    width: 25px;
    right: -8%;
    top: -25%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.75rem;
    color: white;
    font-family: 'Gilroy-SemiBold', sans-serif;
    padding: 0.25rem 0.35rem 0.12rem 0.35rem;
    border-radius: 50%;
    background-color: red;
}

.ExclamationIcon {
    position: absolute;
    right: -10%;
    top: -25%;
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

.PendingDone {
    @extend .PedingCTA;
    background: #e6f7dd !important;
    span {
        color: #49a71b !important;
    }
}

.PedingCTA {
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;
    gap: 0.5rem;
    background: #ffeeee;
    white-space: nowrap;
    span {
        color: #df666a;
        font-size: 0.875rem;
        font-family: 'Gilroy-SemiBold', sans-serif;
    }
}
