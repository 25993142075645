.Headers {
    &:first-child {
        padding-left: 16px;
    }

    .HeadMain,
    .FirstHeadRowItem {
        position: sticky;
        top: 0;
        left: 6.25rem;
        padding: 15px 40px 15px 30px;
        white-space: nowrap;
        border-bottom: 1px solid #d7d7d7;
        width: 5%;
        z-index: 100;
        background-color: #f9fafc;
        border-right: 1px solid #d7d7d7;
        min-width: 6.25rem;

        &:first-child {
            border-right: none;
        }
    }

    .FirstDrawdownRowItem {
        position: sticky;
        left: 0;
        z-index: 999;
    }

    .LenderHeadersHeadRowItem {
        position: sticky;
        z-index: 99;
        background-color: #f9fafc;
        left: 0 !important;
        border-right: 1px solid #d7d7d7;

        &:hover {
            background-color: var(--hover-bg);
        }
    }

    .CommentColumn {
        position: sticky;
        top: 0;
        left: 0;
        padding: 15px 0 15px 0.5rem !important;
        white-space: nowrap;
        border-bottom: 1px solid #d7d7d7;
        width: 5%;
    }

    .CollapsibleHeader {
        padding-left: 16px;
    }

    .HeadItem {
        font-size: 14px;
        padding: 15px 20px 15px 24px;
        text-align: left;
        white-space: nowrap;
        border-bottom: 1px solid #d7d7d7;
        width: 15%;
    }

    .WideColumn {
        width: 20%;
    }

    .HideLabel {
        font-size: 0;
    }

    .StickyActions {
        position: sticky;
        right: 0;
        z-index: 999;
        background-color: #f9fafc;
        border-left: 1px solid #e7e7e7;
    }

    .DealTermsHeader {
        min-width: 158px;
    }
}
