@import '../../../../scss/card';

.RelatedParties {
    @include card-with-title;

    .Generating {
        background-color: white;
        height: 200px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .Top {
        font-size: 18px;
        font-weight: 600;
        line-height: 21.47px;
        letter-spacing: 0.00023961660917848349px;
        text-align: left;

        padding: 20px 24px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .Left {
            font-weight: 500;
        }

        .LastUpdated {
            font-size: 14px;
            font-weight: 500;
            line-height: 16.48px;
            letter-spacing: 0.00023961660917848349px;
            color: #9d9d9d;
        }
    }

    .TopNoData {
        border-bottom: none;
        border-radius: 8px;
    }

    .NoData {
        font-family: 'Gilroy-Regular';
        color: #9d9d9d;
        line-height: 16.98px;
        font-size: 14px;

        display: flex;
        align-items: center;
    }

    .Body {
        .TableContainer {
            border: 1px solid #e8e8e7;
            border-radius: 0.5rem;
            overflow: hidden;

            .Table3 {
                width: 100%;
                border-collapse: collapse;
                border-spacing: 0;

                table-layout: fixed;

                thead {
                    background-color: #f9fafc;
                    border-bottom: 1px solid #e5e5e5;

                    .Header {
                        padding: 12px 16px;
                        font-size: 14px;
                        font-weight: 600;
                        color: #545b62;
                        text-align: left;
                        display: flex;
                        align-items: center;

                        .ExpandButton {
                            margin-left: 5px;
                            color: var(--primary-text-colour);
                            background: var(--secondary-bg-colour);
                            padding: 3px 8px;
                            font-weight: 600;
                            display: flex;
                            align-items: center;
                            column-gap: 10px;
                            border-radius: 14px;
                            cursor: pointer;

                            & > img {
                                margin-bottom: 2px;
                                transition: transform 0.5ms ease-in-out;
                            }
                        }

                        &_FromName {
                            width: 26%;
                        }

                        &_ToName {
                            width: 40%;
                        }

                        &_Relation {
                            width: 15%;
                        }

                        &_Risk {
                            width: 8%;
                        }

                        &_Alerts {
                            width: 10%;
                        }
                    }
                }

                tbody {
                    .EdgeGroupType {
                        background-color: #f7f7f7;
                        border-bottom: 1px solid #e7e7e7;
                        padding: 12px 16px;
                        color: #4d4d4d;
                        font-weight: 700;
                        font-size: 14px;
                        line-height: 20px;
                        letter-spacing: -0.28px;
                        height: 43px;

                        &:last-child {
                            border-bottom: none;
                        }
                    }

                    .FirstDegreeRow {
                        display: table-row;
                        border-bottom: 1px solid #e5e5e5;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 20px;
                        letter-spacing: -0.28px;
                        cursor: pointer;

                        .RowItem {
                            padding: 8px 16px;
                            display: table-cell;
                            vertical-align: middle;

                            &:first-child {
                                padding-left: 8px;
                            }
                        }

                        .EntityNameContainer {
                            display: flex;
                            align-items: center;

                            .Caret {
                                display: flex;
                                align-items: center;
                                padding: 6px 6px;
                                cursor: pointer;
                                border-radius: 8px;
                                margin-right: 8px;

                                &:hover {
                                    background-color: #efefef;
                                }

                                &_Disabled {
                                    visibility: hidden;
                                    pointer-events: none;
                                }

                                &_Expanded {
                                    background-color: #efefef;
                                }

                                .CaretImg {
                                    transition: all 200ms ease-in-out;

                                    &_Rotate {
                                        transform: rotate(90deg);
                                    }
                                }
                            }

                            .EntityNamePill {
                                background-color: #ebebeb;
                                color: #000000;
                                font-size: 14px;
                                padding: 4px 10px;
                                border-radius: 12px;
                                display: flex;
                                align-items: flex-start;
                                column-gap: 5px;
                            }
                        }

                        .Alerts {
                            color: #df666a;
                            font-weight: 600;
                            font-size: 14px;
                            line-height: 20px;
                            display: flex;
                            align-items: center;
                            white-space: nowrap;

                            &_Disabled {
                                color: #bcbcbc;
                                pointer-events: none;
                            }
                        }

                        .Risk {
                            width: fit-content;
                            border-radius: 44px;
                            padding: 2px 8px 2px 8px;
                            font-size: 14px;
                            line-height: 18px;
                            text-align: center;
                            color: #bcbcbc;
                            background-color: #f7f7f7;

                            &_Severe {
                                color: #ffffff;
                                background: #df666a;
                            }

                            &_High {
                                color: #df666a;
                                background: #fff4f5;
                            }

                            &_Medium {
                                color: #ff7a0e;
                                background: #fff4eb;
                            }

                            &_Low {
                                color: #ffb800;
                                background: #fffceb;
                            }
                        }

                        &_Hover {
                            &:hover {
                                background-color: var(--hover-bg);
                                color: var(--primary-text-colour);
                            }
                        }
                    }

                    .SecondDegreeRow {
                        background-color: #f9fafc;
                        border-bottom: 1px solid #e7e7e7;
                    }
                }
            }
        }
    }
}

.RelatedPartiesPDFReport {
    .Top {
        font-size: 14px;

        .StatusRight {
            display: none;
        }
    }

    .TopNoData {
        display: none;
    }

    .NoData {
        display: none;
    }

    .Body {
        .TableContainer {
            .Table3 {
                thead {
                    .Header {
                        font-size: 11px;
                        padding: 8px 16px;
                        page-break-inside: avoid;

                        .ExpandButton {
                            display: none;
                        }

                        &_FromName {
                            width: 35%;
                        }

                        &_ToName {
                            width: 40%;
                        }

                        &_Relation {
                            width: 15%;
                        }

                        &_Risk {
                            width: 12%;
                        }
                    }
                }
                tbody {
                    .EdgeGroupType {
                        font-size: 11px;
                        padding: 8px 16px;
                        page-break-inside: avoid;
                        height: fit-content;
                    }
                    .FirstDegreeRow {
                        page-break-inside: avoid;
                        font-size: 11px;

                        .RowItem {
                            padding: 4px 8px;
                        }

                        .EntityNameContainer {
                            .EntityNamePill {
                                padding: 2px 6px;
                                font-size: 11px;
                            }
                        }
                        .Risk {
                            font-size: 11px;
                        }
                    }
                }
            }
        }
    }
}
