@import '../../../../../../scss/card';

.NewsUpdates {
    @include new-overview-card;

    .Body {
        .Articles {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            align-items: stretch;
            column-gap: 24px;
            row-gap: 24px;

            .SingleArticle {
                @include cta-card;
                min-height: 210px;

                .Head {
                    display: flex;
                    align-items: flex-start;
                    justify-content: space-between;

                    .Image {
                        height: 70px;
                        aspect-ratio: 1/1;
                        display: flex;
                        align-items: center;
                        border-radius: 4px;
                        overflow: hidden;
                    }

                    .Date {
                        border-radius: 16px;
                        color: #828282;
                        padding: 5px 8px;
                        background: #f7f7f7;
                        font-size: 12px;
                        font-weight: 500;
                        line-height: 14.12px;
                        letter-spacing: 0.00019968050764873624px;
                        text-align: center;
                    }
                }

                .PublisherName {
                    margin-top: 16px;
                    font-size: 14px;
                    font-weight: 700;
                    line-height: 16.8px;
                    text-align: left;
                }

                .Text {
                    margin-top: 16px;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 16px;
                    text-align: left;
                    color: #676767;
                }
            }
        }

        .PDFReport {
            grid-template-columns: 1fr 1fr 1fr;
        }
    }
}
