@import '../../../../../scss/card';
@import '../../../../../scss/animations';

.BankAccounts {
    @include data-upload-card;

    @include animate-fadein(300ms, 0, 0);
    margin-top: 20px;

    .Top {
        color: #14143c;
        font-size: 16px;
        font-weight: 600;
        line-height: 16px;

        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;

        .Right {
            font-weight: 500;
            display: flex;
            align-items: center;
            column-gap: 10px;
            color: var(--primary-text-colour);

            .EditBtn {
                fill: #fff;
                box-shadow: 0 1px 10px 0 rgba(112, 144, 176, 0.16);
                padding: 8px 16px;
                border-radius: 4px;

                display: flex;
                align-items: center;
                column-gap: 5px;
            }

            & > img {
                margin-bottom: 4px;
            }
        }
    }

    .InputRows {
        margin-top: 30px;
        display: flex;
        align-items: center;

        .List {
            width: 300px;
            height: 600px;
        }
        .Input {
            margin: 0;
        }

        & > div {
            margin: 0;
        }
    }

    .FetchContainer {
        margin: 30px 0;

        .FetchBtn {
            border-radius: 8px;
            background: #fff;
            box-shadow: 0px 1px 5px 0px rgba(112, 144, 176, 0.16);
            padding: 16px;
            color: var(--primary-text-colour);
            font-size: 12px;
            font-weight: 600;
            line-height: 12px;
            cursor: pointer;

            display: flex;
            align-items: center;
            justify-content: center;

            & > img {
                margin-right: 5px;
            }
        }

        .ExitFinboxOptions {
            margin-top: 10px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .SomethingWrong {
                color: #d06d6d;
                font-size: 12px;
                font-weight: 600;

                display: flex;
                align-items: center;

                & > img {
                    margin: 0 3px 2.5px 0;
                }
            }

            .UploadManually {
                cursor: pointer;
                color: var(--primary-text-colour);
                font-size: 12px;
                font-weight: 600;
            }
        }

        .UploadStateOptions {
            margin-bottom: 10px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .UploadText {
                color: black;
                font-size: 12px;
                font-weight: 600;
            }

            .RetryFetch {
                cursor: pointer;
                color: var(--primary-text-colour);
                font-size: 12px;
                font-weight: 600;
            }
        }
    }

    .CheckboxRow {
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        column-gap: 10px;

        color: black;
        font-size: 12px;
        font-weight: 600;
        line-height: 17px;
    }

    .CheckboxRowDisabled {
        color: rgba(22, 21, 19, 0.4);
    }

    .OverdraftBox {
        background-color: #f6f6f6;
        border-radius: 4px;
        display: flex;
        flex-direction: column;
        margin: 10px 0;
        padding: 24px;
        position: relative;
        font-size: 12px;

        .UploadText {
            color: #14143c;
            font-size: 12px;
            line-height: 20px;
            margin: 10px 0;
        }
    }
}

.AddNewAccount {
    @include data-upload-card;
    margin-top: 24px;

    .Title {
        color: #14143c;
        font-size: 16px;
        font-weight: 600;
        line-height: 16px;
    }

    .Text {
        margin-top: 10px;
        color: #14143c;
        font-size: 12px;
        // font-weight: 600;
        font-family: 'Gilroy-Medium', sans-serif;
        line-height: 20px;
    }

    .AddBtn {
        fill: #fff;
        box-shadow: 0 1px 10px 0 rgba(112, 144, 176, 0.16);
        padding: 16px;
        margin-top: 30px;
        color: var(--primary-text-colour);
        text-align: center;
        font-size: 12px;
        font-weight: 600;
        line-height: 16px;
        cursor: pointer;
    }
}
