.MagicUploadTableWrapper {
    margin-top: 1rem;
    position: relative;
    overflow: auto;
    height: 55%;
}

.MagicUploadTable {
    table-layout: fixed;
    position: relative;
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 2.5rem;
}

.MUBody {
    width: 100%;
    overflow: auto;
}

.MUHeader {
    color: #535b62;
    background: #f9fafc;
    border-bottom: 1px solid #eaecf0;
    font-family: Gilroy-Medium, sans-serif;
    width: 100%;

    th {
        white-space: nowrap;
        text-align: left;
        padding: 0.75rem 1.5rem;
        font-size: 0.875rem;
        width: 60%;
        &:first-child {
            width: 100%;
        }
    }
}

.MURow {
    border-bottom: 1px solid #eaecf0;
    color: #000000;
    height: 40px;

    td {
        padding: 0.75rem 1.5rem;
        font-size: 0.875rem;
        span {
            position: relative;
            z-index: 2;
            .TruncatedText {
                max-width: 30ch;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }

        .ProgressBackground {
            z-index: 1;
        }
    }
    .LastUpdated {
        white-space: nowrap;
        padding: 0.75rem 0 0.75rem 1.5rem;
    }
    &:hover {
        background: var(--hover-bg);
    }
}

.MURowEmpty {
    height: 40px;
    td {
        padding: 0.75rem 1.5rem;
    }
}

.ProgressBackground {
    background: var(--secondary-bg-colour);
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    transition: width 0.5s ease;
}
.OrgNamePill {
    background: var(--secondary-bg-colour);
    cursor: pointer;
    border-radius: 2.75rem;
    padding: 0.5rem 0.5rem;
    line-height: 1.125rem;
    display: flex;
    align-items: center;
    color: var(--primary-text-colour);
    gap: 0.5rem;
    filter: grayscale(1);
    font-size: 0.875rem;
    width: 100%;
    &:hover {
        box-shadow: 0px 0px 8px 0.5px #00000026;
        filter: grayscale(0);
    }
    .OrgNameText {
        width: 40ch;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
.LoadingPill {
    background: #ffffff;
    cursor: pointer;
    border-radius: 2.75rem;
    padding: 0.2rem 0.5rem;
    line-height: 1.125rem;
    display: flex;
    align-items: center;
    font-size: 0.875rem;
    color: #b7b7b7;
    gap: 0.5rem;
    filter: grayscale(1);
    width: 100%;
}
.MoreOptions {
    min-width: 7.5rem;
    position: absolute;
    left: -5rem;
    z-index: 500;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: #535b62;
    background: white;
    box-shadow: 0px 0px 10px 0px #0000001a;
    span {
        cursor: pointer !important;
        padding: 0.5rem 0.5rem;
        font-family: Gilroy-semibold, sans-serif;
        text-align: left;
        font-size: 0.87rem;
        width: 100%;
        border-bottom: 1px solid #eaecf0;
        &:hover {
            background: var(--hover-bg);
        }
    }
}

.AnimationWrapper {
    display: flex;
    align-items: center;
    position: relative;
    justify-content: center;
    border-radius: 2.75rem;
    transition: width 0.5s ease;
    background-color: transparent;
    width: fit-content;
    animation: changeBg 0.5s linear 1s forwards;
}

.AnimationErrorWrapper {
    @extend .AnimationWrapper;
    animation:
        changeErrorBg 0.5s linear 1s forwards,
        errorShake 0.25s 3s 2 forwards;
}

@keyframes changeErrorBg {
    0% {
        background-color: transparent;
    }
    50% {
        background-color: #fdf0ec;
    }
    100% {
        background-color: #fdf0ec;
    }
}

@keyframes changeBg {
    0% {
        background-color: transparent;
    }
    50% {
        background-color: #f5f5f5;
    }
    100% {
        background-color: #f5f5f5;
    }
}
.AnimatedImage {
    width: auto;
    height: auto;
    position: relative;
    right: 0;
    z-index: 3;
    border-radius: 2.75rem;
    background-color: #f5f5f5;
    animation:
        moveIn 1s ease forwards,
        shrinkDot 0.5s ease 1s forwards;
}
.AnimatedImageError {
    @extend .AnimatedImage;
    background-color: #fdf0ec;
}

@keyframes moveIn {
    0% {
        right: -80px;
    }
    100% {
        right: 0;
    }
}

@keyframes shrinkDot {
    0% {
        right: 0;
        transform: scale(1);
        width: 1.5rem;
    }
    100% {
        left: 0;
        transform: scale(0);
        width: 0;
    }
}
@keyframes emergeAndSlideOut {
    0% {
        opacity: 0;
        width: 0;
    }
    50% {
        opacity: 0.75;
        width: 18rem;
    }
    100% {
        opacity: 1;
        width: 15rem;
    }
}

.SuccessAnimatedPill {
    width: 100%;
    opacity: 0;
    z-index: 3;
    animation: emergeAndSlideOut 1s 1s ease forwards;
}
.ErrorAnimatedPill {
    @extend .SuccessAnimatedPill;
    // the error shake animation should start after the shrinkDot animation
    animation: emergeAndSlideOut 1s 1s ease forwards;
}

@keyframes errorShake {
    0%,
    100% {
        transform: translateX(0);
    }
    25% {
        transform: translateX(-10px);
    }
    50% {
        transform: translateX(10px);
    }
    75% {
        transform: translateX(-10px);
    }
}
.dNone {
    z-index: 3;
    display: none;
}

.TruncateText {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 15ch;
    display: block;
}
