@import '../../../scss/animations';
@import '../../../scss/breakpoints';

.InvestorSignup {
    @include animate-fadein(300ms, 0, 0);
    width: 100vw;
    height: 100vh;
    display: grid;
    grid-template-columns: 1fr 1.3fr;
}

.Left {
    background-image: url('https://fl-fe-assets.s3.ap-south-1.amazonaws.com/svg/investorSignupBackground.svg');
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #f9f9f9;
    text-align: center;
    h1 {
        font-size: 2.5rem;
        background: linear-gradient(
            180deg,
            #6021b3 100%,
            #bb429d 100%,
            #7628d8 100%,
            #6021b3 100%,
            #6021b3 100%
        );
        -webkit-text-fill-color: transparent;
        -webkit-background-clip: text;
        background-clip: text;
        margin-bottom: 0;
    }

    .Logo {
        margin-bottom: 40px;
        height: 75px;
    }
}

.Right {
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
}

.FormContainer {
    width: 350px;
}

.LoginBlock {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px;

    .Forgot {
        color: var(--primary-text-colour);
        cursor: pointer;
        padding: 40px 0;
        border-bottom: 1px solid #e5e5e5;
    }

    .Signup {
        margin-top: 40px;
    }
}

// CSS FOR RESPONSIVE VIEW//@at-root

@include lg {
    .Left {
        padding: 0 10px;

        .Logo {
            height: auto;
            width: 70%;
        }
    }
}

@include sm {
    .InvestorLogin {
        grid-template-rows: 1fr 3fr;
        grid-template-columns: none;
    }

    .Left {
        padding: 20px;
        font-size: 12px;
        .Logo {
            width: 50%;
            margin-bottom: 20px;
        }
    }

    .Right {
        .FormContainer {
            padding: 0 40px;
            width: 100%;
        }
    }
}

.InvestorLoginFormContainer {
    @include animate-fadein(300ms, 0, 0);

    .Title {
        font-size: 24px;
        font-weight: 600;
    }

    .Forgot {
        width: fit-content;
        color: var(--primary-text-colour);
        cursor: pointer;
        margin-top: 40px;

        background-color: white;
        border: none;
        font-size: 16px;
        padding: 0;
    }

    .Signup {
        margin-top: 40px;

        .Login {
            cursor: pointer;
            padding: 0;
            background-color: white;
            border: none;
            font-size: 16px;
            color: var(--primary-text-colour);
        }
    }
}
.Row {
    margin-top: 35px;
    display: flex;
    align-items: center;
}
