@import '../../scss/animations';
@import '../../scss/scrollbars';

.DebtProducts {
    height: 100%;
    position: relative;
    --default-column-width: 140px;
    --select-column-width: 75px;
    --caret-column-width: 50px;
    --products-column-width: 300px;
    --probability-column-width: 127px;
    --security-column-width: 700px;
    --table-row-height: 45px;

    .Container {
        background-color: white;
        border-radius: 8px;
        border: 1px solid #e7e7e7;

        .Top {
            height: 90px;
            padding: 24px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .Title {
                font-size: 24px;
                font-weight: 600;
                line-height: 24px;
                text-align: left;
            }
        }

        .Body {
            .TableContainer {
                transition: all 0.3s ease;
                overflow: auto;
                @include light-scrollbar;

                &::-webkit-scrollbar {
                    width: 16px;
                }
                height: calc(100vh - 15rem);

                .Table {
                    border-spacing: 0;
                    width: 100%;

                    .HeadersRow {
                        color: #545b62;
                        font-size: 14px;
                        font-weight: 600;
                        line-height: 18px;
                        position: sticky;
                        top: 0;
                        z-index: 23;
                        .Header {
                            background-color: #f9fafc;

                            padding: 13px 16px;
                            min-width: var(--default-column-width);
                            max-width: var(--default-column-width);
                            text-align: left;
                            border-bottom: 1px solid #e7e7e7;
                        }
                        .EmptyHeader {
                            color: transparent;
                        }
                        .CaretCol {
                            min-width: var(--caret-column-width);
                            max-width: var(--caret-column-width);
                            color: transparent;
                            display: flex;
                            align-items: center;
                            justify-content: center;

                            //position: sticky;
                            //left: var(--select-column-width);

                            //@TODO: comment this and uncomment above when allowing selection
                            position: sticky;
                            left: 0;
                            top: 0;
                        }

                        .SelectCol {
                            min-width: var(--select-column-width);
                            max-width: var(--select-column-width);

                            position: sticky;
                            left: var(--caret-column-width);
                        }

                        .ProductsCol {
                            min-width: var(--products-column-width);
                            max-width: var(--products-column-width);

                            //position: sticky;
                            //left: calc(var(--select-column-width) + var(--caret-column-width));

                            //@TODO: comment this and uncomment above when allowing selection
                            position: sticky;
                            left: calc(var(--caret-column-width) + var(--select-column-width));
                            top: 0;
                        }

                        .ProbabilityCol {
                            min-width: var(--probability-column-width);
                            max-width: var(--probability-column-width);
                            border-right: 1px solid #e7e7e7;

                            //position: sticky;
                            //left: calc(
                            //    var(--select-column-width) + var(--caret-column-width) +
                            //        var(--products-column-width)
                            //);

                            //@TODO: comment this and uncomment above when allowing selection
                            position: sticky;
                            left: calc(var(--caret-column-width) + var(--products-column-width) + var(--select-column-width));
                            top: 0;
                        }

                        .SecurityCol {
                            min-width: var(--security-column-width);
                            //max-width: var(--security-column-width);
                        }
                    }

                    .TableBody {
                        position: relative;
                    }

                    .TableRow {
                        font-size: 14px;
                        font-family: 'Gilroy-Medium', sans-serif;
                        line-height: 18px;
                        cursor: pointer;
                        // position: relative;

                        &:hover {
                            .RowItem {
                                background-color: #f7f7f7;
                            }
                        }

                        &.ParentRow {
                            z-index: 22;
                        }

                        &.ExpandedRow {
                            position: sticky;
                            font-family: 'Gilroy-SemiBold', sans-serif;
                            top: calc(var(--table-row-height));
                            z-index: 21;
                        }

                        .RowItem {
                            padding: 23.5px 16px;
                            border-bottom: 1px solid #e7e7e7;
                            background-color: white;

                            .CaretDiv {
                                padding: 10px 13px;
                                border-radius: 8px;

                                &:hover {
                                    background-color: #efefef;
                                }
                            }

                            .CaretDivHov {
                                background-color: #efefef;
                            }
                        }

                        .DisabledRowItem {
                            background-color: #f9f9f9;
                            //opacity: 0.5;
                            //pointer-events: none;
                            color: #c3c3c3;
                        }

                        .SelectCol {
                            position: sticky;
                            left: var(--caret-column-width);
                            z-index: 20;
                        }
                        .SelectColInner {
                            min-width: var(--select-column-width);
                            max-width: var(--select-column-width);

                            position: sticky;
                            padding-left: 3rem;
                            left: calc(var(--caret-column-width));
                        }
                        .CaretColInner {
                            min-width: var(--caret-column-width);
                            max-width: var(--caret-column-width);
                            z-index: 20;
                            //position: sticky;
                            //left: var(--select-column-width);

                            position: sticky;
                            left: 0;
                        }

                        .CaretCol {
                            min-width: var(--caret-column-width);
                            max-width: var(--caret-column-width);
                            z-index: 20;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            //position: sticky;
                            //left: var(--select-column-width);

                            position: sticky;
                            left: 0;
                        }

                        .ProductsCol {
                            z-index: 20;
                            position: sticky;
                            left: calc(var(--select-column-width) + var(--caret-column-width));
                        }

                        .ProbabilityCol {
                            z-index: 20;
                            border-right: 1px solid #e7e7e7;
                            position: sticky;
                            left: calc(var(--select-column-width) + var(--caret-column-width) + var(--products-column-width));
                        }
                    }

                    .Subrow {
                        @include animate-fadein(180ms, 0, -10px, 0);
                        font-weight: 100;

                        .RowItem {
                            background-color: #f9fafc;
                            color: black;
                        }

                        .ProductsCol {
                            padding-left: 40px;
                        }
                    }
                }
            }
        }
    }
}

.Product {
    display: flex;
    align-items: center;
    column-gap: 10px;
    font-size: 14px;
    font-family: 'Gilroy-Medium', sans-serif;
    line-height: 20px;

    .Count {
        background-color: #efefef;
        font-size: 12px;
        font-weight: 500;
        line-height: 12px;
        padding: 6px 9px 5px 9px;
        border-radius: 20px;
        //width: fit-content;
        //aspect-ratio: 1/1;
    }
}

.ExpandedRow {
    .Product {
        font-family: 'Gilroy-SemiBold', sans-serif;
    }
}

.ProductSub {
    font-weight: 400;
}

.SecurityContainer {
    display: flex;
    align-items: center;
    column-gap: 10px;
    z-index: 5;

    .SecurityItem {
        //font-family: 'Gilroy-SemiBold', sans-serif;
        white-space: nowrap;
        font-size: 12px;
        font-weight: 500;
        line-height: 12px;
        background-color: #efefef;
        padding: 6px 10px;
        border-radius: 44px;
    }

    .More {
        white-space: nowrap;
        font-size: 12px;
        font-weight: 500;
        line-height: 12px;
        text-align: left;
        color: #6021b3;
    }
}

.ProbabilityRow {
    display: flex;
    align-items: center;
    column-gap: 10px;
    padding: 10px 6px;
    border-radius: 8px;
    transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);

    &:hover {
        background-color: #f2e8ff;
        box-shadow: 0 0 8px 0.5px #00000026;
    }
}

.Loader {
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: sticky;
    left: 0;

    .Text {
        margin-top: 20px;
        color: #606060;
        font-size: 15.41px;
        font-weight: 600;
        line-height: 18.39px;
        letter-spacing: 0.01em;
        text-align: left;
    }
}

.Empty {
    margin-top: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 24px;

    color: #344054;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;

    position: sticky;
    left: 0;

    .Link {
        color: var(--primary-purple);
        text-decoration: underline;
        cursor: pointer;
    }
}

.ExpandedCaret {
    transform: rotate(90deg);
}

.DisabledCaret {
    opacity: 0.5;
}

.SecuritiesTooltip {
    padding: 7px;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: #4d4d4d;
}

.PendingDone {
    @extend .PedingCTA;
    background: #e6f7dd !important;
    span {
        color: #49a71b !important;
    }
}

.PedingCTA {
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;
    gap: 0.5rem;
    background: #ffeeee;
    white-space: nowrap;
    span {
        color: #df666a;
        font-size: 0.875rem;
        font-family: 'Gilroy-SemiBold', sans-serif;
    }
}

.Right {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.Footer {
    height: 0;
    display: flex;
    // height: auto;
    overflow: hidden;
    z-index: 30;
    width: 100%;
    position: absolute;
    // bottom: -2rem;
    opacity: 0;
    padding: 0.75rem 2.2rem;
    background: var(--primary-purple-highlight);
    border: 1px solid var(--Border-Grey-border, #e7e7e7);
    margin: 0 -34px;
    transition: bottom 0.3s ease;
}
.FooterText {
    width: 100%;
    font-family: Gilroy-SemiBold, sans-serif;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.FooterBtnContainer {
    height: 2.5rem;
    display: flex;
    align-items: center;
    gap: 1rem;
}

.Checkbox {
    appearance: none;
    width: 1rem;
    height: 1rem;
    margin: 8px;
    border: 1px solid black;
    border-radius: 4px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    outline: none;
    position: relative;
    transition: all 0.1s ease-in-out;

    // For the unchecked state
    &:not(:checked) {
        background-color: transparent;
    }

    // For the checked state
    &:checked {
        background-color: var(--primary-bg-colour); // Purple background
        border-color: var(--primary-bg-colour); // Purple border

        &::after {
            // Make a tick mark
            content: '';
            position: absolute;
            top: 25%;
            width: 0.4rem;
            height: 0.2rem;
            border: solid white;
            border-width: 0 0 2px 2px;
            transform: rotate(-45deg);
        }
    }
}
