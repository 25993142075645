.DrawerContent {
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;
}

.DrawerFooter {
    position: fixed;
    bottom: 0;
    right: 0;
    width: 70%;
    padding: 0px 24px 24px 24px;
    background-color: white;
    border-top: 1px solid #e5e5e5;
    z-index: 10;
}

.ButtonContainer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 90%;
    gap: 16px;
}
