@import '../../../../../scss/card';
@import '../../../../../scss/animations';

.Equafix {
    @include data-upload-card;
    margin-top: 30px;
    color: #000;
    font-size: 14px;
    font-weight: 500;
    line-height: 150%;
}
.Top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    line-height: 20px;
    font-family: 'Gilroy-SemiBold';
    letter-spacing: 0.00027955271070823073px;
    background-color: white;
    // box-shadow: 0px 1px 10px 0px #7090b029;
    text-align: left;
    width: 100%;
    height: 68px;
    border-radius: 8px;
    padding: 0px 30px;
}

.Equafix {
    @include data-upload-card;
    margin-top: 30px;
    color: #000;
    font-size: 14px;
    font-weight: 500;
    line-height: 150%;
}

.Line {
    height: 0px;
    border: 1px solid #ffffff;
    background-color: #dbdbdb;
}

.Separator {
    margin-top: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 10px;

    .Line {
        height: 3px;
        width: 100%;
        background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='white' stroke='black' stroke-width='3' stroke-dasharray='3%2c 19' stroke-dashoffset='5' stroke-linecap='square'/%3e%3c/svg%3e");
    }
}
