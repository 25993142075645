.Container {
    min-height: 100%;

    .Table {
        width: 100%;
        border-collapse: collapse;
        table-layout: fixed;

        .TableHead {
            background-color: #f9fafc;
            color: #535b62;
            position: sticky;
            z-index: 10;
            top: 0;
            box-shadow: 0 0.031rem 0 #e7e7e7;

            .TableHeadRow {
                height: 2.75rem;

                .TableHeadRowItem {
                    font-size: 0.875rem;
                    font-family: 'Gilroy-SemiBold';
                    padding: 0.75rem 0;
                    text-align: left;

                    &:first-child {
                        width: 5%;
                        padding-left: 1.5rem;
                    }
                    &:nth-child(2) {
                        width: 25%;
                    }
                    &:nth-child(3) {
                        width: 20%;
                    }
                    &:nth-child(4) {
                        width: 20%;
                    }
                    &:nth-child(5) {
                        width: 15%;
                    }
                    &:nth-child(6) {
                        width: 15%;
                    }
                }
            }
        }

        .TableBody {
            position: relative;
            width: 100%;
            height: 100%;

            .EmptyScreen {
                position: absolute;
                left: 0;
                right: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                height: 400px;
                flex-direction: column;
                font-size: 1.25rem;
                color: #535b62;
                gap: 0.3rem;

                img {
                    margin-bottom: 1rem;
                }
            }

            .TableRow {
                max-height: 30px;
                border-bottom: 0.063rem solid #e7e7e7;

                .TableRowItem {
                    font-size: 0.875rem;
                    font-family: 'Gilroy-Medium';
                    padding: 1rem 3rem 1rem 0;

                    &:first-child {
                        padding-left: 1.5rem;
                    }
                }
            }
        }
    }

    .Loader {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
}

.Row {
    background-color: white;
    cursor: pointer;
    height: 2rem;

    &:hover {
        background-color: var(--hover-bg);
    }

    &.CollapsibleRow {
        background-color: #f9fafc;
        cursor: default;

        .RowItem {
            color: var(--secondary-text-colour);
            font-size: 14px;
            padding: 1.75rem 1.75rem 1.75rem 0rem;

            &:nth-child(2) {
                padding-left: 2.5rem;
            }
        }
    }

    .RowItem {
        padding: 1.75rem 0rem;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        border-bottom: 1px solid #f0f0f0;
        white-space: nowrap;
        text-overflow: ellipsis;
        vertical-align: middle;

        &:first-child {
            padding-left: 30px;
        }

        &.CollapsibleRowItem {
            width: 5%;
            padding: 16px;
        }

        .DropdownButton {
            padding: 0;
            margin: 0 0 0 8px;
            background: none;
            border: none;
            cursor: pointer;
            outline: none;
            transition: 0.25s;

            &.Open {
                transform: rotate(-180deg);
            }
        }
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.Status {
    padding: 6px 12px;
    border-radius: 16px;
    font-size: 12px;
    font-weight: 500;
    display: inline-block;
}

.ProductCell {
    display: flex;
    align-items: center;
    max-width: 100%;
    padding-right: 2.5rem;

    .FirstProduct {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .RemainingCount {
        color: var(--primary-text-colour);
        flex-shrink: 0;
        white-space: nowrap;
    }

    &.BoldText {
        font-family: 'Gilroy-SemiBold';
        font-weight: 600;
    }
}

.NameCell {
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    padding-right: 2.5rem;
}

.BoldText {
    font-family: 'Gilroy-SemiBold';
    font-weight: 600;
}

.RedirectPill {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    margin-left: 8px;
    padding: 0.2rem 0.5rem;
    background-color: var(--primary-purple-highlight);
    color: var(--primary-text-colour);
    border-radius: 12px;
    font-size: 12px;
    text-decoration: none;
    cursor: pointer;
}
