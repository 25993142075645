.CategorySelector {
    border-radius: 8px;
    border: 1px solid #d0d5dd;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    width: fit-content;
    display: flex;
    align-items: center;

    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    cursor: pointer;
    overflow: hidden;
}

.Category {
    padding: 10px 16px;
    border-right: 1px solid #d0d5dd;

    display: flex;
    align-items: center;

    &:last-child {
        border-right: none;
    }
}

.SelectedCategory {
    background: var(--secondary-bg-colour);
    color: var(--primary-text-colour);
}

.grey {
    color: #979797;
}
