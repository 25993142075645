.InvestorDealShareContainer {
    width: 100%;

    .InvestorDealShare {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #f8f8f8;
        padding: 18px 20px;
        border-radius: 14px;
        border: 0.5px solid #f8f8f8;

        &.Shared {
            background-color: white;
            border: 0.5px solid #dbdbdb;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;

            &.RecurInvestor {
                border-radius: 14px;

                .NetworkContainer {
                    .NetworkLogo {
                        background-color: #eee2ff;
                    }
                }
            }

            .NetworkContainer {
                .NetworkLogo {
                    background-color: #f8f8f8;
                }
            }
        }

        .NetworkContainer {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .NetworkLogo {
                display: flex;
                padding: 10px;
                background-color: white;
                border-radius: 4.5px;
            }

            .NetworkDetails {
                margin-left: 1rem;

                .NetworkLabel {
                    font-size: 14px;
                    font-weight: 700;
                    color: #000000;
                }

                .NetworkStats {
                    font-size: 12px;
                    font-weight: 500;
                    color: #939393;
                    margin-top: 5px;
                }
            }
        }

        .NetworkShare {
            display: flex;
            align-items: center;

            .ShareLabel {
                font-size: 12px;
                font-weight: 500;
                color: #a0a0a0;
                margin-right: 14px;
            }

            .NetworkShareText {
                font-size: 14px;
                font-weight: 700;
                color: #000000;
            }
        }
    }

    .InvestorList {
        border: 0.5px solid #dbdbdb;
        border-top: none;
        border-bottom-left-radius: 14px;
        border-bottom-right-radius: 14px;
        max-height: 300px;
        overflow-y: auto;
        overflow-x: hidden;

        .SelectAll {
            background-color: #d9d9d92b;
            display: flex;
            justify-content: space-between;
            align-items: center;
            text-wrap: nowrap;

            .SelectedText {
                font-size: 14px;
                font-weight: 500;
                color: #a3a3a3;
                padding-right: 24px;
            }
        }
    }
}
