.PortfolioSuccess {
    padding: 48px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .SuccessAnimationImage {
        width: 150px;
        height: auto;
        margin-bottom: 2.25rem;
    }

    .Title {
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 2.25rem;
    }
}
