@import '../../../../scss/animations';
@import '../../../../scss/breakpoints';

.DashboardHeader {
    box-shadow: 0px 0px 10px 0px #0000001a;
    background: white;
    height: 72px;
    z-index: 100;

    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    display: grid;
    grid-template-columns: 2fr auto 2fr;
    align-items: center;
}

.DashboardHeaderRight {
    // justify-self: end;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    column-gap: 16px;
    // width: 20%;
    height: 72px;
    padding: 1rem 1.5rem;

    .MessageIcon {
        cursor: pointer;
        display: flex;
        align-items: center;
        padding: 4px 6px;
        border-radius: 8px;

        &:hover {
            background-color: #f2f2f2;
        }

        &_Active {
            background-color: #f2f2f2;
        }
    }

    .AddToScan {
        background: linear-gradient(109.4deg, #f4c4d5 -23.96%, #c250a3 9.77%, #8b33cc 61.9%, #6f2fe8 90.16%);
        color: white;
        padding: 8px 14px;

        display: flex;
        align-items: center;
        margin-left: 10px;
        border-radius: 8px;
        font-size: 14px;
        font-weight: 500;
        line-height: 24px;
        text-align: left;
        column-gap: 5px;
        cursor: pointer;

        & > img {
            margin-bottom: 2px;
        }
    }
}

.RefreshReport {
    color: black;
    font-size: 10px;
    font-weight: 600;
    cursor: pointer;
}

.DownloadPDF {
    padding: 8px 12px;
    font-size: 10px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #81878c;
    border-radius: 4px;
    cursor: not-allowed;
}

.CompanyName {
    height: 100%;
    padding: 1rem 1.5rem;

    display: flex;
    align-items: center;
    overflow-x: hidden;
    // width: 32rem;
    gap: 10px;
    font-family: Gilroy-semibold, sans-serif;
    font-size: 1rem;

    .HeaderBackButton {
        height: 40px;
        width: 40px;
        cursor: pointer;
    }

    .Name {
        display: flex;
        align-items: center;
        gap: 10px;
        width: 100%;

        .FavIcon {
            background: #f8f8f8;
            box-shadow: 0px 1px 2px 0px #1018280d;
            color: var(--primary-text-colour);
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 0.5rem;
            padding: 0.5rem 1rem;

            height: 35px;
            width: 35px;
        }

        .NameTextContainer {
            width: 100%;
            max-width: 380px;
            height: 35px;
            display: flex;
            flex-direction: column;
            justify-content: center;

            .NameText {
                flex-shrink: 1;
                max-width: 90%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .SubText {
                white-space: nowrap;
                font-family: Gilroy-Medium, sans-serif;
                font-size: 0.75rem;
                color: #b3b3b3;
            }
        }
    }
}

.DashboardHeaderMiddle {
    height: 100%;
    display: flex;
    align-items: center;
    gap: 1rem;
}

@media (max-width: 1400px) {
    .CompanyName {
        .Name {
            .NameText {
                width: 150px;
            }
        }
    }
}

@media (max-width: 1260px) {
    .CompanyName {
        .Name {
            .NameText {
                width: 100px;
            }
        }
    }
}

@media (max-width: 1200px) {
    .CompanyName {
        .Name {
            .NameText {
                width: 50px;
            }
            .SubText {
                visibility: hidden;
            }
        }
    }
}

@media (max-width: 1140px) {
    .CompanyName {
        .Name {
            display: none;
            .NameText {
                width: 50px;
            }
            .SubText {
                visibility: hidden;
            }
        }
    }
}
