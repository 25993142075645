.EmailNotVerified {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 12px 4%;
    background-color: #ffe0d3;
    color: #08080d;
    font-size: 16px;
    font-family: 'Gilroy-SemiBold', sans-serif;

    .WarnIcon {
        margin-right: 1rem;
    }

    .ResendVerificationCta {
        background-color: transparent;
        border: none;
        padding: 0;
        font-size: 16px;
        font-family: 'Gilroy-SemiBold', sans-serif;
        color: #6021b3;
        cursor: pointer;
        margin-left: 4px;
    }
}
