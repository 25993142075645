.EmptyDeals {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .Title {
        margin-top: 24px;
        color: #08080d;
        font-size: 16px;
        font-weight: 600;
    }

    .Subtext {
        margin-top: 22px;
        color: #939393;
        font-size: 14px;
        font-weight: 500;
        text-align: center;
        max-width: 460px;
    }
}
