.Pill {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    border-radius: 4px;
    padding: 0.5rem 0.5rem;
    background: var(--secondary-bg-colour);
    width: fit-content;
    font-size: 0.75rem;
}

.FilteredPillsWrapper {
    overflow: hidden;
    padding: 1rem;
    display: flex;
    gap: 0.5rem;
    align-items: center;
    flex-wrap: wrap;
    transition: all 0.2s ease-in-out;
}

.ClearAllPill {
    transition: height 0.1s ease-in-out;
    overflow: hidden;
    span {
        color: var(--primary-text-colour);
        cursor: pointer;
        font-size: 0.75rem;
        font-family: Gilroy-semibold, sans-serif;
    }
}
