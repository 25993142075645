@import '../../../../scss//animations';

.CompanySignupFormContainer {
    .Title {
        font-size: 24px;
        font-weight: 600;
        transition: all 2s ease-in-out;
    }

    .Tnc {
        display: flex;
        align-items: flex-start;
        column-gap: 5px;
        font-size: 14px;
        margin-top: 35px;

        .Link {
            color: var(--primary-text-colour);
            cursor: pointer;
        }
    }

    .Loading {
        height: 610px;

        display: flex;
        align-items: center;
        justify-content: center;
    }

    .Content {
        @include animate-fadein(500ms);
        padding: 60px 0;
    }

    .Forgot {
        color: #2f8fff;
        cursor: pointer;
        padding: 40px 0;
        border-bottom: 1px solid #e5e5e5;
    }

    .Signup {
        margin-top: 40px;

        .Login {
            cursor: pointer;
            padding: 0;
            background-color: white;
            border: none;
            font-size: 16px;
            color: var(--primary-text-colour);
        }
    }
}
