.UploadCard {
    .UploadText {
        color: #000000;
        margin-bottom: 8px;
        font-family: 'Gilroy-Medium';
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 0.00027955271070823073px;
        text-align: left;
    }
}
