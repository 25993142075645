@import '../../scss/animations';

.AIText {
    background: linear-gradient(
        270deg,
        var(--primary-text-colour) 25%,
        #f142e5 50%,
        var(--primary-text-colour) 80%
    );
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    background-size: 200% 200%;
    animation: gradient 3s ease infinite;
}

@keyframes gradient {
    0% {
        background-position: 200% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0 50%;
    }
}

.LoadingDots {
    width: auto;
    overflow: hidden;
    animation: dot1 1s steps(3, end) infinite;
}

@keyframes dot1 {
    0% {
        width: 0ch;
    }
    33% {
        width: 1ch;
    }
    66% {
        width: 2ch;
    }
    100% {
        width: 3ch;
    }
}

.AIWrapper {
    @include animate-fadein(300ms, 0, 10px);
    display: flex;
    align-items: center;
}
