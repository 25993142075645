@use '../../../../scss/colors';
@import '../../../../scss/card';
@import '../../../../scss/animations';

.CreditBureau {
    overflow: hidden;
    @include animate-fadein(300ms, 0, 30px);
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 1.5rem;
}
