@import '../../../../../../scss/card';

$table-grid-template: 1fr 0.9fr 1fr 1fr 4fr 55px;
.FundingHistory {
    @include new-overview-card;

    .Top {
        .Right {
            .LastUpdated {
                font-size: 14px;
                font-weight: 500;
                line-height: 18px;
                color: #9d9d9d;
            }
        }
    }

    .Body {
        .TableContainer {
            border-radius: 8px;
            overflow: hidden;
            border: 1px solid #e8e8e7;
            //max-height: 400px;
            //overflow-y: auto;

            .Headers {
                display: grid;
                grid-template-columns: $table-grid-template;
                background-color: #f9fafc;

                position: sticky;
                top: 0;

                font-size: 14px;
                font-weight: 600;
                line-height: 18px;
                text-align: left;

                color: #545b62;
                border-bottom: 1px solid #e8e8e7;

                .Header {
                    padding: 13px 16px;
                }
            }

            .Rows {
                .Row {
                    display: grid;
                    grid-template-columns: $table-grid-template;
                    border-bottom: 1px solid #e8e8e7;

                    font-size: 14px;
                    font-weight: 500;
                    line-height: 18px;

                    .RowItem {
                        padding: 13px 16px;
                    }

                    &:last-child {
                        border-bottom: none;
                    }
                }
            }
        }
    }
}

.DocIconContainer {
    cursor: pointer;
    height: 15px;
    width: 23px;

    .DownloadContainer {
        padding: 4px 4px 0 4px;
        border-radius: 4px;
        &:hover {
            background-color: #f2f2f2;
        }
    }
}

.AlignRight {
    //text-align: right;
}
