.A4-Page {
    width: 210mm; /* A4 width */
    min-height: 297mm; /* A4 height */
    padding: 10mm; /* Inner padding */
    margin: 0 auto; /* Center the page */
    background: #f7f7f7;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    position: relative;
    //overflow: auto;
    z-index: 10;
}

.A4-Content {
    //flex: 1; /* Allow flexible height for content */
    width: 100%; /* A4 width */
    min-height: auto;
    max-height: none;
    z-index: 20;
    //position: absolute;
    //overflow: auto;
}

.Page {
    position: absolute;
    bottom: 10px;
    right: 0;
    font-size: 12px;
    margin-right: 10px;
}

.PoweredByAica {
    position: absolute;
    bottom: 5px;
    left: 50%;
    transform: translateX(-50%);
    height: 15px;
}

/* Print-specific styles */
@media print {
    .A4-Page {
        box-shadow: none;
        margin: 0;
        transform: none;
    }
}
