@mixin commonFileExplorer {
    display: grid;
    border-bottom: 1px solid #eaecf0;
    color: #535b62;
    background: #f9fafc;
    font-size: 0.875rem;
    font-family: Gilroy-SemiBold, sans-serif;
    padding: 1rem;
}

.fileExplorer {
    display: flex;
    flex-direction: column;
    height: 100%;
    border-radius: 0.75rem;
}

.header {
    padding: 1.5rem;
    display: grid;
    grid-template-columns: 2fr 0.2fr 0.2fr;
    gap: 1rem;
    font-family: Gilroy-SemiBold, sans-serif;
    h1 {
        font-size: 1.5rem;
        margin: 0;
    }
}
.separator {
    height: 2rem;
    width: 0.0938rem;
    background-color: #e5e5e5;
}
.ButtonTextContainer {
    display: flex;
    gap: 1rem;
    align-items: center;
    img {
        height: 1rem;
        width: 1rem;
    }
}
.downloadButton {
    background-color: var(--primary-bg-colour);
    color: #fff;
    font-family: Gilroy-SemiBold, sans-serif;
    border: none;
    padding: 10px 24px;
    cursor: pointer;
    border-radius: 0.3125rem;
}

.fileList {
    width: 100%;
    height: 80%;
    padding-bottom: 1rem;
}
.FileTreeContainer {
    overflow-y: auto;
    height: 100%;
    padding-bottom: 1.5rem;
    position: relative;
}

.fileTreeHeader {
    @include commonFileExplorer;
    grid-template-columns: 2.3fr 0.3fr 0.5fr 0.1fr 0.1fr;
}

.fileTreeHeaderLenderView {
    @include commonFileExplorer;
    grid-template-columns: 2.3fr 0.3fr 0.5fr 0.1fr 0.1fr;
}

.leftSubHeader {
    display: flex;
    gap: 1.5rem;
    align-items: center;
}
.fileTreeHeaderItem {
    font-size: 0.875rem;
    padding: 0 0.5625rem;
    font-weight: 600;
}
.selectorView {
    display: flex;
    gap: 1.5rem;
    align-items: center;
    font-family: Gilroy-SemiBold, sans-serif;
    font-size: 0.75rem;
    color: #535b62;
}
