.BarParent {
    margin-bottom: 4px;
    height: 1rem;
    background-color: #e5e9f2;
    border-radius: 2px;
    position: relative;
    .BarChild {
        position: absolute;
        height: 100%;
        top: 0;
        left: 0;
        border-radius: 2px;
        background: linear-gradient(
            90deg,
            var(--secondary-bg-colour) 0%,
            var(--primary-bg-colour) 100%
        );

        //         background: linear-gradient(90deg, #e6d9ff 0%, #aa7eff 40%, rgba(96, 33, 179, 0.75) 100%);

        transition: width 1s ease-in-out;
    }

    &[data-inactive='true'] {
        background-color: #f5f5f5;
        .BarChild {
            background: linear-gradient(90deg, #e0e0e0 0%, #bdbdbd 100%);
        }
    }
}
