.RelatedPartiesAlerts {
    .Heading {
        padding: 17px 24px;
        display: flex;
        align-items: center;
        font-size: 18px;
        font-weight: 500;
        line-height: 16px;
        text-align: left;
        border-bottom: 1px solid #d3d3d3;
        color: black;

        .Top {
            padding: 11px 0;
        }

        .Name {
            color: #a9a9a9;
        }
    }

    .Content {
        padding: 24px;
        background-color: #efefef;
        height: calc(100vh - 73px);
        overflow: auto;

        .Count {
            font-size: 12px;
            font-weight: 500;
            line-height: 12px;
            letter-spacing: 0.00023961660917848349px;
            color: #2f363f;
        }

        .Table {
            margin-top: 16px;
            background-color: white;
            border-radius: 8px;

            .Headers {
                background: #fcfcfd;

                display: grid;
                grid-template-columns: 1fr 5fr 2fr;

                font-size: 14px;
                font-weight: 500;
                line-height: 18px;
                border-bottom: 1px solid #eaecf0;
                border-radius: 8px 8px 0 0;
                color: #535b62;

                .Header {
                    padding: 13px 16px;
                }
            }

            .Rows {
                .Row {
                    display: grid;
                    grid-template-columns: 1fr 5fr 2fr;
                    align-items: center;
                    border-bottom: 1px solid #eaecf0;

                    & > div {
                        padding: 12px 16px;
                    }

                    .Risk {
                        width: fit-content;
                        font-size: 12px;
                        font-weight: 500;
                        line-height: 18px;
                        padding: 2px 8px;
                        border-radius: 44px;
                    }

                    .Desc {
                        font-size: 14px;
                        font-weight: 500;
                        line-height: 20px;
                        text-align: left;
                    }

                    .Category {
                        font-size: 12px;
                        font-weight: 500;
                        line-height: 18px;
                        text-align: center;
                        color: #404040;
                        background: #f5f5f5;
                        width: fit-content;
                        border-radius: 44px;
                        padding: 2px 8px;
                    }
                }
            }
        }
    }
}
