@import '../../../../../../scss/animations';

$--lender-name-width: 17.5rem;

.Container {
    position: relative;
    height: 100%;
}

.TableContainer {
    height: 100%;
    width: 100%;
    overflow-x: auto;
    display: flex;
    flex-direction: column;
    position: relative;

    .Table {
        background-color: white;
        width: max-content;
        min-width: 100%;
        border-collapse: separate;
        border-spacing: 0;
        overflow-y: scroll;
        overflow-x: auto;
        height: auto;
        tr {
            //height: 50px;
        }
        table-layout: fixed;

        .FirstHeadRowItem {
            min-width: $--lender-name-width;
            border-right: 1px solid #e7e7e7;
            position: sticky;
            left: 0;
        }

        .TableHead {
            background-color: #f9fafc;
            z-index: 10;
            position: sticky;
            top: 0;
            text-align: left;
            color: #535b62;
            font-size: 14px;
            font-weight: 500;
            line-height: 18px;
            width: max-content;
            height: 44px;
        }

        .TableBody {
            @include animate-fadein(200ms, 0, 5px);
            font-family: 'Gilroy-Medium';
            font-size: 14px;
            line-height: 12px;
            z-index: 1;
            position: relative;
            vertical-align: middle;
        }
    }

    &::-webkit-scrollbar {
        display: none;
    }

    &::-webkit-scrollbar-track {
        display: none;
    }

    &::-webkit-scrollbar-thumb {
        display: none;
    }
}

.Loading {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 500;
    display: flex;
    align-items: center;
    justify-content: center;
}
