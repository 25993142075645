.PasswordInputWrapper {
    display: flex;
    position: relative;
}

.PasswordInput {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    border-radius: 50px;
    background: #fdf0ec;
    color: #000000;
    padding: 0.5rem 0.75rem;
    outline: none;
    border: none;
    z-index: 2;
    cursor: pointer;
    font-family: Gilroy-Medium, sans-serif;
    font-size: 0.875rem;

    &::placeholder {
        font-family: Gilroy-Medium, sans-serif;
        color: #df666a;
    }

    &:hover {
        box-shadow: 0px 0px 8px 0.5px #00000026;
    }
}

.Valid {
    background: #ffffff !important;
    border: 1px solid var(--primary-border-colour) !important;

    &::placeholder {
        color: #000000;
    }

    &:hover {
        box-shadow: none !important;
        color: #000000 !important;
        background: #ffffff !important;
    }
}

.SaveBtn {
    cursor: pointer;
    position: absolute;
    right: 20%;
    height: 0.875rem;
    width: 0.875rem;
    top: 25%;
    z-index: 2;
    padding: 0;
    background-color: transparent;
    outline: none;
    border: none;
}

.ErrorImg {
    position: absolute;
    right: 20%;
    top: 28%;
    height: 0.875rem;
    width: 0.875rem;
    z-index: 2;
}
.Disabled {
    filter: grayscale(0);
    background: #f7f7f7 !important;
    cursor: no-drop;
    &::placeholder {
        color: #b7b7b7;
    }
}

.errorShake {
    animation: errorShake 0.25s forwards;
}

@keyframes errorShake {
    0%,
    100% {
        transform: translateX(0);
    }
    25% {
        transform: translateX(-10px);
    }
    50% {
        transform: translateX(10px);
    }
    75% {
        transform: translateX(-10px);
    }
}
