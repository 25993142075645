.TypeSelector {
    display: flex;
    align-items: center;
    border: 1px solid rgba(208, 213, 221, 1);
    border-radius: 8px;
    cursor: pointer;
    background-color: white;
    overflow: hidden;
    width: fit-content;

    .Option {
        display: flex;
        align-items: center;
        border-right: 1px solid rgba(208, 213, 221, 1);
        padding: 10px 12px;
        font-size: 14px;
        font-weight: 700;
        display: flex;
        align-items: center;
        column-gap: 8px;
        background-color: rgb(244, 244, 244);
        // .Icon {
        //     // filter: invert(12%) sepia(56%) saturate(6142%) hue-rotate(265deg) brightness(97%)
        //     //     contrast(94%);
        // }

        &:last-child {
            border-right: none;
        }
    }

    .SelectedOption {
        background-color: white;
    }
}
