.form-group {
    padding-top: 13px;
    margin-bottom: 1rem;
}
.gstotpContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.gstotpInput {
    padding: 12px;
    width: 15%;
    border: 0.5px solid #ffffff;
    border-radius: 6px;
    font-weight: 600;
    background-color: #f3f3f3;
    color: black;
    border: 1px solid #aaaaaa;
    @media only screen and (max-width: 600px) {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
.gstotpInput:focus {
    border: 1px solid #2f8fff;
    background-color: #ffffff;
}
.gstotpInput:focus-visible {
    outline: none;
}
.gstotpInput:disabled {
    cursor: no-drop;
}
