@import '../../scss/animations';

.Topbar {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    background-color: white;
    height: 72px;

    box-shadow: 0px 0px 10px 0px #0000001a;
    z-index: 998;

    display: flex;
    align-items: center;
    justify-content: space-between;

    .Left {
        min-width: 130px;
        height: 100%;
        display: flex;
        align-items: center;
        width: 34rem;
    }

    .ActiveDeals {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 9rem;
        padding: 0.5rem 1rem;
        border-radius: 1.25rem;
        background: #f7f7f7;
        span {
            margin-left: 1rem;
            white-space: nowrap;
            font-family: 'Gilroy-SemiBold', sans-serif;
            color: #8a8b9f;
        }
        &:hover {
            background-color: var(--hover-bg);
        }
    }
    .ActiveTab {
        background-color: var(--secondary-bg-colour);
        span {
            color: var(--primary-text-colour);
        }
    }
    .DisabledTab {
        cursor: not-allowed;
        // pointer-events: none;
        filter: grayscale(1);
    }

    .Slider {
        position: absolute;
        @include animate-fadein(600ms, 0, 0);
        left: 0;
        bottom: 0;
        height: 3px;
        width: 100px;
        background-color: var(--primary-text-colour);
        transition: left 100ms ease-in-out;
        margin-top: 20px;
    }
    .Center {
        height: 100%;
        padding: 0 16px;
        flex: 1;
        display: flex;
        justify-content: center;
        position: relative;
    }

    .Right {
        padding-right: 16px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        column-gap: 24px;
        width: 34rem;

        .SampleReport {
            cursor: pointer;
            display: flex;
            align-items: center;
            column-gap: 8px;
            padding: 10px 16px;
            border-radius: 5px;

            font-size: 14px;
            font-weight: 600;
            line-height: 20px;

            background: linear-gradient(
                94.46deg,
                var(--primary-text-colour) 1.11%,
                #af68bb 112.57%
            );
            background: -webkit-linear-gradient(
                94.46deg,
                var(--primary-text-colour) 1.11%,
                #af68bb 112.57%
            );
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            box-shadow: 0px 1px 8px 0px #1018281c;

            &:hover {
                box-shadow: 0px 1px 8px 0px #0d15262d;
            }

            & > img {
                margin-bottom: 2px;
            }
        }
    }
}

.MagicUploadButton {
    display: flex;
    align-items: center;
    column-gap: 5px;
    font-size: 1rem;
    cursor: pointer;
    background: #ffffff;
    padding: 0.75rem 1rem;
    border-radius: 4px;
    border: 1px solid #c7c7c7;
    font-weight: 600;
    position: relative;

    & > img {
        margin-bottom: 2px;
    }

    &:hover {
        animation-play-state: paused;
    }
}

.MagicUploadButton::before,
.MagicUploadButton::after {
    content: '';
    position: absolute;
    width: 14px;
    height: 200%;
    background: linear-gradient(to left, #ffffff 4.79%, rgba(153, 153, 153, 0) 76.27%);
    opacity: 0.2;
    transform: rotate(17deg);
    animation: reflect 2s infinite;
}

.MagicUploadButton::after {
    animation-delay: 0.1s;
}

@keyframes reflect {
    0% {
        left: -150%;
    }
    100% {
        left: 110%;
    }
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

.redDot {
    position: absolute;
    height: 25px;
    width: 25px;
    right: -8%;
    top: -25%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.75rem;
    color: white;
    font-family: 'Gilroy-SemiBold', sans-serif;
    padding: 0.25rem 0.35rem 0.12rem 0.35rem;
    border-radius: 50%;
    background-color: red;
}

.ExclamationIcon {
    position: absolute;
    right: -10%;
    top: -25%;
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

.Active {
    height: 100%;
    width: 9rem;
    padding: 1rem;
    border-bottom: 3px solid var(--primary-border-colour) !important;
}
.Disabled {
    height: 100%;
    padding: 1rem;
    width: 9rem;
    border-bottom: 3px solid transparent !important;
    pointer-events: none;
}
.Element {
    margin-left: 1rem;
    cursor: pointer;
    height: 100%;
    padding: 1rem;
    width: 10rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 3px solid transparent;
}

.InviteUser {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    cursor: pointer;
    color: var(--secondary-button-text);
    border-radius: 4px;
    border: 1px solid var(--secondary-button-border);
    padding: 0.675rem 1rem;
    font-size: 14px;
    font-weight: 600;
}
