@import '../../../../../../scss/card';

.ManagementProfile {
    @include new-overview-card;

    .Body {
        display: flex;
        align-items: stretch;
        flex-wrap: wrap;
        column-gap: 24px;
        row-gap: 24px;

        .Card {
            @include cta-card;
            min-width: 240px;
            min-height: 212px;

            .CardTop {
                display: flex;
                justify-content: space-between;

                .Badge {
                    font-size: 12px;
                    font-weight: 500;
                    line-height: 14.12px;
                    letter-spacing: 0.00019968050764873624px;
                    text-align: center;
                    padding: 3px 8px;
                    width: fit-content;
                    height: fit-content;
                    border-radius: 16px;
                }

                .Active {
                    color: #49a71b;
                    background: #e1ffe1;
                }

                .Former {
                    color: #df666a;
                    background: #fdf0ec;
                }
            }

            // display: flex;
            // flex-direction: column;
            // justify-content: space-between;
            .Image {
                height: 70px;
                aspect-ratio: 1/1;
                display: flex;
                align-items: center;
                border-radius: 4px;
                overflow: hidden;
            }

            .Name {
                margin: 16px 0 0px 0;
                font-size: 14px;
                font-weight: 700;
                line-height: 16.8px;
                text-align: left;
                color: #000000;
            }

            .Field {
                margin-top: 8px;
                align-items: center;
                justify-content: space-between;

                display: grid;
                grid-template-columns: 1fr 1.4fr;

                &:first-child {
                    margin-top: 24px;
                }
                .FieldName {
                    font-size: 12px;
                    font-weight: 500;
                    line-height: 20px;
                    text-align: left;
                    color: #999999;
                }

                .FieldVal {
                    font-size: 12px;
                    font-weight: 600;
                    line-height: 20px;
                    text-align: left;
                    color: #000000;
                }
            }
        }
    }

    .PDFReport {
        grid-template-columns: 1fr 1fr 1fr;
    }
}
