@import '../../../scss/animations';

.TabSlider {
    display: flex;
    align-items: center;
    position: relative;
    height: 100%;
    column-gap: 0.5rem;
    justify-content: center;
    max-width: fit-content;

    .SingleTab {
        min-width: 7.5rem;
        padding: 8px 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: 12px;
        border-radius: 6px;
        cursor: pointer;
        color: #4d4d4d;

        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        text-align: left;
        transition: all 200ms ease-in-out;
        & > img {
            margin-bottom: 2px;
        }

        &:hover {
            background: var(--hover-bg);
        }

        .Shimmer {
            margin-bottom: 0;
        }
    }

    .ActiveTab {
        background-color: var(--secondary-bg-colour);
        color: var(--primary-text-colour);

        &:hover {
            background-color: var(--hover-bg);
        }
    }

    .DisabledTab {
        cursor: not-allowed;
        // pointer-events: none;
        filter: grayscale(1);
    }

    .Slider {
        position: absolute;
        @include animate-fadein(600ms, 0, 0);
        left: 0;
        bottom: 0;
        height: 3px;
        width: 100px;
        background-color: var(--primary-bg-colour);
        transition: left 100ms ease-in-out;
    }
}
