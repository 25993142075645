@mixin small-grey-scroll {
    &::-webkit-scrollbar {
        width: 19px;
        scroll-padding-right: 100px;
    }

    &::-webkit-scrollbar-track {
        background: transparent;
        border-radius: 5px;
        border-left: 0px solid transparent;
        border-right: 0px solid transparent;
        margin: 0px 0 5px 0;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #656565;
        border-radius: 4px;
        border: 6px solid #f7f7f7;
    }
}

@mixin no-bg-scroll {
    &::-webkit-scrollbar {
        width: 5px;
        // scroll-padding-right: 100px;
    }

    &::-webkit-scrollbar-track {
        background: transparent;
        border-radius: 10px;
        border-left: 0px solid transparent;
        border-right: 0px solid transparent;
        margin: 0px 0 5px 0;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #cccccc7e;
        border-radius: 10px;
        border: 5px solid transparent;
    }
}

@mixin no-bg-scroll2 {
    &::-webkit-scrollbar {
        width: 5px;
        // scroll-padding-right: 100px;
    }

    &::-webkit-scrollbar-track {
        background: transparent;
        border-radius: 10px;
        border-left: 0px solid transparent;
        border-right: 0px solid transparent;
        margin: 0px 0 5px 0;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #4444447e;
        border-radius: 10px;
        border: 5px solid transparent;
    }
}

@mixin no-scroll {
    &::-webkit-scrollbar {
        display: none;
    }

    &::-webkit-scrollbar-track {
        display: none;
    }

    &::-webkit-scrollbar-thumb {
        display: none;
    }
}

@mixin scrollbar-show-on-hover {
    &::-webkit-scrollbar {
        background-color: transparent;
        width: 5px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: transparent;
    }

    &:hover {
        &::-webkit-scrollbar {
            background-color: transparent;
            width: 5px;
            height: 5px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: gray;
            border-radius: 20px;
        }
    }
}

@mixin light-scrollbar {
    &::-webkit-scrollbar {
        width: 19px;
        scroll-padding-right: 100px;
    }

    &::-webkit-scrollbar-track {
        background: transparent;
        border-radius: 5px;
        border-left: 0px solid transparent;
        border-right: 0px solid transparent;
        margin: 0px 0 5px 0;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #d9d9d9;
        border-radius: 10px;
        border: 6px solid white;
    }
}
