@import '../../../../scss/animations';

.CompanySignupFormContainer {
    @include animate-fadein(300ms, -30px, 0);
    .Title {
        font-size: 24px;
        font-weight: 600;
    }

    .Forgot {
        color: #2f8fff;
        cursor: pointer;
        padding: 40px 0;
        border-bottom: 1px solid #e5e5e5;
    }

    .Signup {
        margin-top: 40px;

        .Login {
            cursor: pointer;
            padding: 0;
            background-color: white;
            border: none;
            font-size: 16px;
            color: var(--primary-text-colour);
        }
    }

    .Row {
        margin-top: 35px;
        display: flex;
        align-items: center;
    }
}

.IdentifierFound {
    margin-top: 4px;
    color: #6921b3;
    font-size: 10px;
    font-weight: 600;
    line-height: 11.93px;

    display: flex;
    align-items: center;
    column-gap: 2px;
    & > img {
        margin-bottom: 2px;
    }
}
