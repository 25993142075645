@import '../../../../../scss/animations';

.Alerts {
    //negative margin is applied here to counter the gutter property used to adjust sidebar width
    // margin: 20px -15px -10px -15px;
    .SingleAlert {
        @include animate-fadein(300ms, 0, 0);
        margin-bottom: 10px;
        background-color: var(--secondary-bg-colour);
        color: var(--primary-text-colour);
        font-size: 12px;
        font-weight: 500;
        padding: 12px 8px;
        border-radius: 4px;

        display: flex;
        align-items: center;
        column-gap: 6px;

        & > img {
            margin-bottom: 2px;
        }
    }
}
