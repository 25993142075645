@import '../../scss/scrollbars';
.CompanyLegalNameSearch {
    position: relative;
    margin-bottom: 10px;

    .Icon {
        position: absolute !important;
        right: 15px;
        top: 50%;
        transform: translateY(-50%);
    }

    .Options {
        position: absolute;
        top: 100%;
        left: 0;
        // min-height: 40px;
        max-height: 300px;
        width: 400px;
        background-color: white;
        margin-top: 20px;
        border: 1px solid rgb(237, 237, 237);
        z-index: 20;
        transition: all 200ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
        box-shadow: 0px 0px 10px 0px #0000001a;
        border-radius: 6px;
        font-size: 12px;
        font-weight: 500;
        line-height: 14.12px;
        cursor: pointer;
        overflow-y: auto;
        @include no-bg-scroll;

        .Option {
            display: grid;
            grid-template-columns: 3fr 1fr;
            align-items: center;
            padding: 10px;

            border-bottom: 1px solid #eeeeee;
            .Left {
            }

            .Right {
                color: #c1c1c1;
            }

            &:hover {
                background-color: var(--hover-bg);
            }
        }

        .AddNew {
            padding: 10px;
            display: flex;
            align-items: center;
            column-gap: 10px;
            color: var(--primary-text-colour);
            &:hover {
                background-color: var(--hover-bg);
            }
        }
    }
}
