.Title {
    color: #535b62;
}

.FilteredHeader {
    width: 100%;
    white-space: nowrap;
    cursor: pointer;
    position: relative;
}

.FilteredHeaderSelected {
    @extend .FilteredHeader;
    border-bottom: 1px solid var(--primary-border-colour);
    background-color: var(--secondary-bg-colour);
}

@mixin dropdown-container-base {
    position: absolute;
    top: 110%;
    left: 0;
    box-shadow: 0px 0px 10px 0px #0000001a;
    height: auto;
    max-height: 18rem;
    transition: all 0.1s linear;
    background-color: white;
    overflow-x: hidden;
}

.FilteredDropdownContainer {
    @include dropdown-container-base;
    width: 350px;
    z-index: 2;
}
.FilteredDropdownContainerForUploadedBy {
    @include dropdown-container-base;
    width: 100%;
}

.DropdownOptionsWrapper {
    height: 100%;
    width: 100%;
}
.FilteredDropdownWrapper {
    overflow: auto;
    height: fit-content;
    border-top: 1px solid #eaecf0;
}

.FilteredDropdownItem {
    display: flex;
    align-items: center;
    gap: 1rem;
    padding: 0.5rem 1rem;
    cursor: pointer;
    border-bottom: 1px solid #eaecf0;
    height: 39.7px;

    &:hover {
        background-color: var(--hover-bg);
    }

    label {
        font-family: 'Gilroy-Regular';
        font-size: 12px;
        color: #000000;
    }
}

.LoadingState {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}
.NoData {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50%;
    padding-top: 10px;
    span {
        padding-bottom: 10px;
    }
}
.SearchInputFilter {
    position: sticky;
    top: 0;
    width: 100%;
    font-size: 0.75rem;
    border: none;
    font-family: Gilroy-semibold, sans-serif;
    height: 40px;
    // position the icon at the start
    background: #f9f9f9 url('../../../../assets/search-glass.svg') no-repeat 10px 50%;
    background-size: 1rem;
    padding: 1rem 1rem 1rem 2rem;
    outline: none;

    &::placeholder {
        color: #c3c3c3;
    }
}
.DropdownLabel {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    img {
        width: 1rem;
        height: 1rem;
    }
}

.SearchBar {
    cursor: pointer;
    border-radius: 0.5rem;
    position: relative;
    background: #f7f7f7;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.75rem;
}

.SelectedItem {
    background: #ebebeb;
    color: black;
    font-family: Gilroy-Medium, sans-serif;
    border-radius: 2rem;
    padding: 0.25rem 0.75rem;
    font-size: 0.875rem;
}
.AndMore {
    margin-left: 1ch;
    color: var(--primary-text-colour);
    font-size: 0.75rem;
    font-family: Gilroy-Medium, sans-serif;
}
.DownArrow {
    width: 1rem;
    height: 1rem;
    filter: brightness(0);
    transition: transform 0.3s;
}
.PlaceholderText {
    color: #9d9d9d;
    font-family: Gilroy-Medium, sans-serif;
    font-size: 0.75rem;
}
