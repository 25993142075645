@import '../../../../../../scss/scrollbars';

.YearMonthTable {
    overflow: auto;

    @include scrollbar-show-on-hover;

    .Headers {
        display: grid;
        grid-template-columns: 1.2fr repeat(12, 1fr);
        border-bottom: 1px dashed black;

        position: sticky;
        top: 0;
        left: 0;
        background-color: white;
        .Header {
            padding: 5px;
            text-align: center;
            color: #bcbcbc;
            font-size: 12.797px;
            font-weight: 500;

            &:first-child {
                text-align: left;
            }
        }
    }

    .Rows {
        .Row {
            display: grid;
            grid-template-columns: 1.2fr repeat(12, 1fr);

            &:first-child {
                margin-top: 5px;
            }

            .Data {
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 10px 5px 0 6px;

                &:first-child {
                    justify-content: flex-start;
                    font-size: 12px;
                    font-weight: 700;
                    background-color: transparent;
                    color: black;
                }

                .Badge {
                    padding: 3px 6px 2px 6px;
                    background: #ed6e7233;
                    color: #ed6e72;
                    border-radius: 50px;
                    width: fit-content;
                    font-size: 12px;
                }
                .Empty {
                    color: #404040;
                    background-color: white;
                }
            }
        }
    }
}
