.MetricRiskBadge {
    page-break-inside: avoid;
    page-break-after: auto;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.28px;
    text-align: center;

    width: fit-content;
    padding: 2px 10px;
    border-radius: 20px;

    display: flex;
    align-items: center;
    column-gap: 5px;

    .Icon {
        height: 10px;
        width: 10px;
        border-radius: 10px;
    }
}

.MetricRiskPDFReport {
    font-size: 10px;
}
