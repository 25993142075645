@import '../../../../../../../../scss/animations.scss';

.MapDataModal {
    @include animate-fadein(300ms, 0, 0, 0);
}
.ModalBody {
    padding: 0 2rem;
    //width: 40rem;
    //height: 12.5rem;
    .MainScreen {
        display: flex;
        flex-direction: column;
        height: 100%;
    }
    .ButtonContainer {
        padding: 1.5rem 0;
        width: 100%;
        display: flex;
        justify-content: flex-end;
        gap: 1rem;
        .BlueButton {
            background-color: #2f8fff;
            border: none;
            color: white;
            padding: 0.5rem 1rem;
            font-family: Gilroy-SemiBold, serif;
            border-radius: 0.25rem;
            outline: none;
            cursor: pointer;
        }
        .BlueButtonInv {
            @extend .BlueButton;
            background-color: var(--primary-bg-colour);
        }
        .WhiteButton {
            background-color: white;
            border: 1px solid #2f8fff;
            color: #2f8fff;
            padding: 0.5rem 1rem;
            font-family: Gilroy-SemiBold, serif;
            border-radius: 0.25rem;
            outline: none;
            cursor: pointer;
        }
        .WhiteButtonInv {
            @extend .WhiteButton;
            color: var(--primary-text-colour);
            border: 1px solid var(--primary-border-colour);
        }
    }
}
