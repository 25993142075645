.YelloBanner {
    background: #fff7e9;
    padding: 0.75rem;
    display: flex;
    align-items: flex-start;
    gap: 0.5rem;
    border-radius: 5px;
    p {
        margin: 0;
        font-size: 0.625rem;
    }
}

.GstContentContainer {
    padding: 1rem;
}
.Footer {
    display: flex;
    align-items: center;
    padding: 1rem;
    gap: 1rem;
}
.GstContainer {
    display: flex;
    align-items: center;
    margin-bottom: 1.5rem;
    .Label {
        margin: 0;
        font-size: 0.75rem;
        font-family: 'Gilroy-Medium', sans-serif;
        color: #000000;
    }
    .SubLabel {
        margin: 0;
        font-size: 0.75rem;
        color: #000000;
    }
}
.FooterSubtext {
    margin: 0.5rem 0 0 0;
    font-size: 0.625rem;
    color: #9d9d9d;
}
