.DrawerHeader {
    background-color: #ffffff;
}

.DrawerContent {
    display: flex;
    flex-direction: column;
    position: relative;
    padding-bottom: 90px;
    overflow-y: auto;
    height: 100%;
    max-height: calc(100vh - 85px);
    width: 100%;

    .DropdownContainer {
        padding: 24px 24px 0px 24px;
        transition: all 0.5s ease;

        &.deleting {
            transform: translateX(100%);
            opacity: 0;
        }
    }
}

.triggerContent {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-left: 8px;

    .leftContent {
        display: flex;
        gap: 8px;
        align-items: center;
    }

    .rightContent {
        display: flex;
        gap: 8px;
        align-items: center;

        .dateBy {
            color: #9d9d9d;
            font-size: 14px;
        }

        .amount {
            font-weight: 500;
            font-size: 14px;
            color: #333;
            background-color: #ebebeb;
            padding: 4px 8px;
            border-radius: 16px;
        }
    }

    .deleteIcon {
        cursor: pointer;
        margin-right: 16px;
    }
}

.DrawerFooter {
    height: 72px;
    position: sticky;
    bottom: 0;
    background: white;
    padding: 1rem;
    border-top: 1px solid var(--border-color);
    opacity: 0;
    transform: translateY(100%);
    transition: all 0.3s ease-in-out;

    &.visible {
        opacity: 1;
        transform: translateY(0);
    }
}

.ButtonContainer {
    display: flex;
    gap: 1rem;
    justify-content: flex-end;
}

.loaderContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
}
