@import '../../../../../../scss/card';
.ShareHoldingPattern {
    @include new-overview-card;

    .Top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 1rem;

        .Title {
            font-size: 1rem;
            font-weight: 600;
            color: #000;
        }

        .MetaData {
            display: flex;
            align-items: center;
            gap: 1.5rem;

            .Date {
                font-size: 0.875rem;
                color: #6c757d;
                font-weight: 500;
            }

            .DownloadWrapper {
                display: flex;
                align-items: center;
                cursor: pointer;
                gap: 0.5rem;

                .DownloadIcon {
                    width: 0.938rem;
                    height: 0.938rem;
                }

                .DownloadText {
                    font-size: 0.875rem;
                    color: #6021b3;
                    font-weight: 600;
                    line-height: 1.125rem;
                    text-align: center;
                }
            }
        }
    }

    .Body {
        .Table {
            border-radius: 8px;
            border: 1px solid #e8e8e7;
            overflow: hidden;

            .ShpHeaders {
                padding: 16px 16px;
                color: #535b62;
                font-size: 0.875rem;
                font-weight: 600;
                background-color: #f9fafc;
                display: grid;
                align-items: center;
                grid-template-columns: repeat(4, 1fr);
                border-bottom: 1px solid #e7e7e7;
                height: 2.75rem;
            }

            .ShpRow {
                color: #000;
                font-size: 0.875rem;
                font-weight: 500;
                padding: 16px 16px;
                display: grid;
                align-items: center;
                grid-template-columns: repeat(4, 1fr);
                border-bottom: 1px solid #e7e7e7;
                height: 2.75rem;

                &:last-child {
                    border-bottom: none;
                    font-weight: 600;
                    background-color: #f7f7f7;
                }
            }
        }
    }
}
