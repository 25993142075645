@import '../../../../scss/animations';
@import '../../../../scss/scrollbars';

.BankTableContainer {
    margin-top: 34px;
    overflow-x: auto;
    min-height: 400px;

    .Top {
        position: sticky;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        column-gap: 10px;

        font-size: 14px;
        font-weight: 600;
        line-height: 17px;
        color: rgba(197, 197, 197, 1);
        z-index: 10;

        .Left {
            display: flex;
            align-items: center;
            column-gap: 10px;

            & > img {
                margin-bottom: 2px;
            }
        }

        .Right {
            padding: 6px 10px 6px 12px;
            color: #6021b3;
            cursor: pointer;
            display: flex;
            align-items: center;
            column-gap: 10px;
            background-color: #a381eb26;
            border-radius: 64px;
            font-weight: 500;

            &:hover {
                font-weight: 600;
            }
        }
    }
    .Content {
        .BankTable {
            @include animate-fadein(400ms, 0, 30px);
            // margin-top: 36px;
            border-collapse: separate;
            border-spacing: 0;
            background-color: white;
            .TableHead {
                font-size: 0.875rem;
                line-height: 20px;
                font-family: 'Gilroy-SemiBold';
                text-align: right;
                color: #535b62;
                background: #f9fafc;
                border-right: 1px solid #ededed;
                height: 2.75rem;
                .TableHeadRow {
                    .TableHeadRowItem {
                        border-bottom: 1px solid #eaecf0;
                        padding: 12px 16px;
                        min-width: 150px;
                        max-width: 150px;
                        // border-right: 1px solid #ededed;
                        &:first-child {
                            padding: 12px 24px;
                            background: #f9fafc;
                            min-width: 330px;
                            position: sticky;
                            left: 0;
                            text-align: left;
                            border-right: 1px solid #eaecf0;
                        }
                    }
                }
            }

            .TableBody {
                font-size: 0.875rem;
                text-align: right;
                .TableBodyRow {
                    .TableBodyRowItem {
                        padding: 12px 16px;
                        min-width: 150px;
                        max-width: 150px;
                        color: black;
                        border-bottom: 1px solid #eaecf0;
                        height: 2.75rem;
                        &:first-child {
                            padding: 12px 24px;
                            font-weight: 600;
                            position: sticky;
                            left: 0;
                            background-color: white;
                            text-align: left;
                            border-right: 1px solid #eaecf0;
                        }
                    }
                    .negativeValue {
                        color: #f10707;
                    }
                }

                .SummaryRowFirst {
                    .TableBodyRowItem {
                        border-top: 1px solid rgb(98, 98, 98);
                    }
                }

                .SeparatorRow {
                    text-align: center;
                    font-family: 'Gilroy-SemiBold';
                    height: 2.75rem;
                    .SeparatorRowItem {
                        gap: 8px;
                        color: #939393;
                        background: #ebebeb;
                        padding: 12px 24px 12px 24px;
                        position: sticky;
                        left: 0;
                        border-bottom: 1px solid #eaecf0;
                    }
                }
            }
        }
    }
}

.TableContainer {
    @include scrollbar-show-on-hover;
    margin-top: 36px;
    border-radius: 0.938rem;
    height: auto;
    overflow-y: hidden;
}
